import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { OKTAConst } from '@/utils/constants';
import { getOktaAuthCheck } from '@/utils/helpers';

const axiosParams = {
  baseURL: process.env.REACT_APP_BASE_URL ?? 'http://localhost:8080'
};

const axiosInstance = axios.create(axiosParams);

axiosInstance.interceptors.request.use((config) => {
  if (!getOktaAuthCheck()) {
    // @ts-ignore
    config.headers['Authorization'] = localStorage.getItem(OKTAConst.accessToken)
      ? 'Bearer ' + localStorage.getItem(OKTAConst.accessToken)
      : 'Bearer ' + process.env.REACT_APP_FALLBACK_AUTH_TOKEN;
  } else {
    // @ts-ignore
    config.headers['Authorization'] = localStorage.getItem(OKTAConst.accessToken)
      ? 'Bearer ' + localStorage.getItem(OKTAConst.accessToken)
      : 'Bearer ';
  }

  return config;
});

// Main api function
const api = (axios: AxiosInstance) => {
  return {
    get: <T>(url: string, config: AxiosRequestConfig = {}) => axios.get<T>(url, config),
    delete: <T>(url: string, config: AxiosRequestConfig = {}) => axios.delete<T>(url, config),
    post: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) => axios.post<T>(url, body, config),
    patch: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) => axios.patch<T>(url, body, config),
    put: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) => axios.put<T>(url, body, config)
  };
};

export default api(axiosInstance);
