import React from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { HashMap } from '@/types';
import { OKTAConst } from '@/utils/constants';
import { oktaTokenCheckApi } from '@/api/modelApi';
import { toastErrorMsg } from './Toast';
import { getOktaAuthCheck, sleepForSpecificTime } from '@/utils/helpers';

const oktaBaseURL = process.env.REACT_APP_OKTA_URL || 'https://dev-42544738.okta.com/oauth2/default';
const oktaClientId = process.env.REACT_APP_OKTA_CLIENTID || '0oa5qqi3f0YMGTjH55d7';

const config = {
  issuer: oktaBaseURL,
  clientId: oktaClientId,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI || window.location.origin,
  responseType: 'code'
};

console.log('Okta: sdk initializer');
const authClient = new OktaAuth(config);

export const useOktaInitializer = () => {
  const [loading, setLoading] = React.useState(true);

  const validateOktaToken = async () => {
    try {
      // For Dev environment only
      if (!getOktaAuthCheck()) {
        console.log('No Okta check required for dev envs');
        const token = (await authClient.token.parseFromUrl()) as HashMap;

        if (token?.tokens?.accessToken) {
          localStorage.setItem(OKTAConst.accessToken, token?.tokens?.accessToken?.accessToken);
        }
        if (token?.tokens?.idToken) {
          localStorage.setItem(OKTAConst.accessUserName, token?.tokens?.idToken?.claims?.name);
        }
        return;
      }

      const params = new URLSearchParams(window.location.search);

      if (params.has('code')) {
        const token = (await authClient.token.parseFromUrl()) as HashMap;
        if (token?.tokens?.accessToken) {
          localStorage.setItem(OKTAConst.accessToken, token?.tokens?.accessToken?.accessToken);
        }
        if (token?.tokens?.idToken) {
          localStorage.setItem(OKTAConst.accessUserName, token?.tokens?.idToken?.claims?.name);
        }
      } else if (!localStorage.getItem(OKTAConst.accessToken) || !localStorage.getItem(OKTAConst.accessUserName)) {
        console.log('Signin redirection');
        await authClient?.signInWithRedirect();
        // As the authClient reload, the browser which is async process, causing to make loader disapper
        // and seeing the home screen for split seccond, adding sleeper for the same.
        await sleepForSpecificTime(3000);
      } else {
        console.log('OKTA: previous local session data check');
        const payloadParams = new URLSearchParams();
        payloadParams.append('token', localStorage.getItem(OKTAConst.accessToken));
        payloadParams.append('token_type_hint', 'access_token');
        payloadParams.append('client_id', oktaClientId);
        const data = await oktaTokenCheckApi(oktaBaseURL, payloadParams);
        if (!data?.data?.active) {
          throw Error('Invalid Okta token.');
        }
      }
    } catch (error) {
      console.error('OKTA: error ', error);
      toastErrorMsg('You are unauthenticated, please refresh in order to do Okta signin.');
      Object.keys(OKTAConst).forEach((key) => {
        localStorage.removeItem(OKTAConst[key]);
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    validateOktaToken();
  }, []);

  return { authClient, loading };
};
