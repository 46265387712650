import React, { MouseEventHandler, useEffect } from 'react';
import { round } from 'lodash';
import { Tab } from '@headlessui/react';
import Slider from 'react-slick';
import clsx from 'clsx';
import { AppButtonWrapper, AppLayout, OverflowContent } from '@/components/Layout/DesignLayout';
import { Card } from '@/components/Common/Card';
import { GButton, SButton } from '@/components/Common/Button';
import { TableCustom } from '@/components/Common/Table';
import { Title } from '@/components/Common/Typography';
import { ReactComponent as LineMiniChart } from '@/assets/images/omni-min-line-chart.svg';
import { ComposeBarLineChart } from '@/components/Chart/ComposeBarLineChart';

import { ComposeAreaLineChart } from '@/components/Chart/ComposeAreaLineChart';
import { cardHeight, channelDarkColorObj, intervalModelChannelValidationTime } from '@/utils/constants';
import { hexToRgb } from '@/utils/helpers';
import { useNavigate } from 'react-router-dom';
import { fetchMediaChannelPassingModel, flickHelpTooltip } from '@/store/modelsSlice';

import { ReactComponent as SlickArrowNext } from '@/assets/images/chevron-right-light.svg';
import { ReactComponent as SlickArrowPrev } from '@/assets/images/chevron-left-light.svg';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SlickArrowNext />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SlickArrowPrev />
    </div>
  );
}

const reactSlickSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export function ChannelView() {
  const [activeChart, setActiveChart] = React.useState(0);
  const allData = useAppSelector((state) => state.models.measurableSpend);
  const validatedMediaChannelData = useAppSelector((state) => state.models.mediaChannelModelValidation);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const headerData = React.useMemo(() => {
    return {
      title: 'Media Channel Model Validation',
      currentState: 'Review',
      subtleText: 'Please review your Media Channel Model validation',
      helpTooltipProps: {
        title: 'Validate the Spend and Impressions per channel',
        description:
          'In this report, you are shown which channels have passed the validation checks – this is based on the Spend and Impressions recorded for each channel.'
      }
    };
  }, []);

  useEffect(() => {
    const mediaValidationApiIntercal = setInterval(() => dispatch(fetchMediaChannelPassingModel('')), intervalModelChannelValidationTime);

    return () => clearInterval(mediaValidationApiIntercal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Pass / Fail',
      dataIndex: 'name',
      key: 'name',
      // @ts-ignore
      render: (text, row) => (
        <div className='flex flex-row text-left'>
          <span
            className={clsx(
              'flex px-[7px] py-[3px] uppercase text-white rounded-app items-center',
              row.isPass ? 'bg-[#48B8A7]' : 'bg-[whtie] border text-[#E15778] border-[#E15778]'
            )}
          >
            {row.isPass ? 'Pass' : 'Fail'}
          </span>
          <span className='ml-3'>{text}</span>
        </div>
      )
    },
    {
      title: 'Has Spend',
      dataIndex: 'hasSpend',
      key: 'hasSpend',
      // @ts-ignore
      render: (text) => <>{String(text)}</>
    },
    {
      title: 'Zero Values (%)',
      dataIndex: 'zeroValues',
      key: 'zeroValues',
      // @ts-ignore
      render: (num) => {
        return <>{num ? num : 0}%</>;
      }
    },
    {
      title: 'Standard Deviation',
      dataIndex: 'sd',
      key: 'sd',
      // @ts-ignore
      render: (num) => {
        return <>{num ? round(num, 2) : 0}</>;
      }
    },
    {
      title: 'Is Nun',
      dataIndex: 'isNun',
      key: 'isNun',
      // @ts-ignore
      render: (text) => <>{String(text)}</>
    }
  ];

  const handleCurrentListItem = (inx: number) => {
    setActiveChart(inx);
  };

  const handleRedirectVoice = () => {
    navigate('/review-voice');
  };

  return (
    <AppLayout headerProps={headerData}>
      <OverflowContent className='flex flex-col xl:flex-row gap-10 w-full text-neutral-100' isRequireSpace>
        <Card size='sm' className={clsx('relative flex-1 xl:w-3/5  xl:max-w-[52%]', cardHeight.md)} noShadow>
          <TableCustom
            className='omni-channel-table-custom h-[550px] pb-8 overflow-y-scroll'
            header='Media Channels Passing Model Validation'
            data={validatedMediaChannelData}
            columns={columns}
            isLoading={!Boolean(validatedMediaChannelData?.length)}
          />
          <div className='absolute left-0 bottom-0 w-full bg-white flex h-16 px-16 items-center justify-center gap-2'>
            <i className={`fa-light fa-circle-info text-lg`}></i>{' '}
            <span className='text-sm'>Media channels that fail the test are excluded from the model.</span>
          </div>
        </Card>
        <Card size='xsm' className={clsx('flex-1 xl:w-2/5 xl:max-w-[614px]', cardHeight.md)} noShadow>
          <Tab.Group>
            <Tab.List className='flex space-x-1 rounded-[10px] bg-neutral-5 p-1'>
              <TabChannel />
            </Tab.List>
            <Tab.Panels className='mt-2 h-full'>
              <Tab.Panel className={clsx('rounded-xl h-full bg-white p-3')}>
                <div className='flex flex-col w-full h-full'>
                  <div className='text-center mb-4'>
                    <Title as='h5'>Measurable Spend & Impressions ('000)</Title>
                  </div>
                  <ComposeBarLineChart
                    rotateAngle={-35}
                    data={allData?.aggregated_spend}
                    firstLabel='Spend'
                    secondLabel='Impressions'
                    fieldKeys={{
                      xAxis: 'name',
                      bar: 'spend',
                      line: 'impression'
                    }}
                    maxDomainRef={'spend'}
                    isRightYAxis
                    cartesianProps={{ vertical: false }}
                    fixedMultiple={1000}
                    isMaxRange={false}
                  />
                </div>
              </Tab.Panel>
              <Tab.Panel className={clsx('rounded-xl bg-white p-3 h-full')}>
                <div className='flex flex-col h-full m-auto'>
                  <div className='text-center'>
                    <Title as='h5'>
                      {allData?.monthChannelDataAggregation?.[activeChart]?.channel} Spend & Impressions Over Time ('000)
                    </Title>
                  </div>
                  <div className='flex-1 max-h-[400px]'>
                    <ComposeAreaLineChart
                      data={allData?.monthChannelDataAggregation?.[activeChart]?.data ?? []}
                      currentActiveShade={channelDarkColorObj[allData?.monthChannelDataAggregation?.[activeChart]?.channel]}
                    />
                  </div>
                  <div className='w-11/12 overflow-hidden mt-auto mx-auto'>
                    <Slider {...reactSlickSettings}>
                      {allData?.monthChannelDataAggregation.map((element, inx) => (
                        <SliderListItem
                          key={inx}
                          currentActive={activeChart === inx}
                          index={inx}
                          name={element.channel}
                          onClick={() => handleCurrentListItem(inx)}
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Card>
      </OverflowContent>
      <div className='flex flex-col justify-center lg:flex-row lg:justify-between items-center'>
        <div className='flex flex-row items-center cursor-pointer' onClick={() => navigate('/review-validate')}>
          <GButton variant='default' size='md' className='px-0'>
            <>
              <i className='fa-regular fa-angle-left h-[16px] w-[10px]'></i>
              <div className='text-md font-semibold'>Previous</div>
            </>
          </GButton>
        </div>
        <AppButtonWrapper className='flex flex-row mx-auto mt-4 lg:mt-0 lg:mr-0'>
          <SButton onClick={() => dispatch(flickHelpTooltip())}>My data is wrong</SButton>
          <GButton variant='primary' size='md' suffix={<i className='fa-light fa-chevron-right' />} onClick={handleRedirectVoice}>
            Approve & continue
          </GButton>
        </AppButtonWrapper>
      </div>
    </AppLayout>
  );
}

const TabChannel = () => {
  const data = [
    {
      text: 'Spend & Impressions',
      icon: <i className='fa-light fa-chart-bar'></i>
    },
    {
      text: 'Spend Over Time',
      icon: <i className='fa-light fa-chart-line'></i>
    }
  ];

  return (
    <React.Fragment>
      {data.map((ele) => (
        <Tab
          key={ele.text}
          className={({ selected }) => clsx('omni-tabs-header-list', selected ? 'bg-white text-primary-50' : 'text-neutral-100')}
        >
          <span>{ele.icon}</span>
          <span className='ml-[7px]'>{ele.text}</span>
        </Tab>
      ))}
    </React.Fragment>
  );
};

type SlicderListItemType = {
  index: number;
  currentActive?: boolean;
  name: string;
  onClick: MouseEventHandler<HTMLDivElement>;
};

const SliderListItem = ({ index, currentActive, name, onClick }: SlicderListItemType) => {
  const currentColor = channelDarkColorObj[name];

  const rgba = hexToRgb(currentColor);

  return (
    <div key={index} className='pr-1 cursor-pointer'>
      <div
        onClick={onClick}
        className='h-[80px] flex items-center justify-center rounded-app'
        style={{
          color: currentColor
        }}
      >
        <div className='w-[100%] px-2'>
          <div
            className='flex border-1 justify-center align-items-center py-4 radius-2 transition'
            style={{
              color: currentColor,
              background: `rgba(${rgba?.r}, ${rgba?.g}, ${rgba?.b}, 0.05)`,
              borderRadius: `5px`,
              border: `2px solid ${!currentActive ? 'transparent' : currentColor}`
            }}
          >
            <LineMiniChart />
          </div>
          <div className='text-xs text-black text-center mt-1'>{name}</div>
        </div>
      </div>
    </div>
  );
};
