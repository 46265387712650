import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Cell, ResponsiveContainer, Text } from 'recharts';
import { barChartRadius, channelDarkColorObj } from '@/utils/constants';
import { defaultChartValue, formatCurrency } from '@/utils/helpers';
import { BaseChartType, HashMap } from '@/types';
import clsx from 'clsx';

type BarCharCustomType = BaseChartType & {
  hasSingleBar?: boolean;
  singleBarColor?: string;
  maxBarSize?: number;
  noLegend?: boolean;
  noTooltip?: boolean;
  firstLabel?: string;
  secondLabel?: string;
  isOverflow?: boolean;
  isPDF?: boolean;
};

const renderLegend = (props: HashMap) => {
  const { payload } = props;
  return (
    <ul className='flex flex-row justify-center items-center relative -bottom-7'>
      {payload.map((entry: HashMap, index: number) => (
        <li key={`item-${index}`} className='flex flex-row items-center mr-4'>
          <svg width='18' height='18' className='mr-2'>
            <rect
              width='18'
              height='18'
              fill={entry.payload.children[0].props.fill}
              fillOpacity={index === 1 ? '0.2' : 1}
              stroke={entry.payload.children[0].props.fill}
              strokeWidth={1}
            />
          </svg>
          {entry.value}
        </li>
      ))}
    </ul>
  );
};

const CustomTooltip = (props: HashMap) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <>
        <div
          className='p-4 rounded-[8px]'
          style={{
            background: 'radial-gradient(86.82% 192.89% at 73.58% 28.49%, #373F45 0%, #566169 100%)'
          }}
        >
          <p className='flex flex-row items-center text-white'>
            <svg width='14' height='14' className='mr-2 rounded-sm'>
              <rect
                width='14'
                height='14'
                fill={props.payload[0].payload.currentColor}
                fillOpacity={1}
                stroke={props.payload[0].payload.currentColor}
                strokeWidth={1}
              />
            </svg>
            {`${props.payload[0].name}  ${formatCurrency(props.payload[0].value, 0)}`}
          </p>
          {props.payload[1] && (
            <p className='flex flex-row items-center text-white'>
              <svg width='14' height='14' className='mr-2 rounded-sm'>
                <rect
                  width='14'
                  height='14'
                  fill={props.payload[1].payload.currentColor}
                  fillOpacity={0.2}
                  stroke={props.payload[1].payload.currentColor}
                  strokeWidth={1}
                />
              </svg>
              {`${props.payload[1].name}  ${formatCurrency(props.payload[1].value, 0)}`}
            </p>
          )}
        </div>
      </>
    );
  }
  return null;
};

export function VerticalBarChartCustom({
  id,
  isAnimationActive = true,
  data,
  hasSingleBar,
  singleBarColor,
  maxBarSize,
  noLegend = false,
  noTooltip = false,
  firstLabel,
  secondLabel,
  isOverflow = true,
  isPDF = false
}: BarCharCustomType) {
  const chartData = React.useMemo(() => {
    return data.map((element) => {
      const currentColor = singleBarColor ? singleBarColor : channelDarkColorObj[element?.['name']];

      return {
        ...element,
        currentColor
      };
    });
  }, [data, singleBarColor]);

  const renderCustomBarLabel = ({ x, y, width, height, value, ...rest }: HashMap) => {
    if (isPDF) {
      return (
        <foreignObject x={x - 10} y={y - 18} width={width + 20} height={height + 25}>
          <div className='text-sm text-black text-center'>{`${
            value === 0 && rest.values === 'lightBar' ? '' : value === 0 ? defaultChartValue() : formatCurrency(value, 0)
          }`}</div>
        </foreignObject>
      );
    }

    const dx = 0;
    const position: any = {
      x: x + width / 2,
      y: y,
      textAnchor: 'middle',
      dy: height / 2,
      height: undefined
    };

    return (
      <Text
        {...position}
        width={width}
        className={clsx(isPDF ? 'text-sm fill-black text-black' : 'text-xs fill-neutral-100 text-neutral-100')}
        dx={rest.values === 'solidBar' && value === 0 ? width / 2 + width / 2 : dx}
      >{`${value === 0 && rest.values === 'lightBar' ? '' : value === 0 ? defaultChartValue() : formatCurrency(value, 0)}`}</Text>
    );
  };

  const addtionalStyling = isPDF
    ? {
        fill: '#00000',
        fontWeight: '500',
        fontSize: 14
      }
    : {};

  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
      className={clsx(`recharts-responsive-container-large`, isOverflow && 'overflow-x-scroll')}
    >
      <BarChart data={chartData} layout='horizontal' barCategoryGap={5} barGap={5} margin={{ left: 30, right: 10, bottom: 30, top: 18 }}>
        <XAxis
          dataKey='name'
          axisLine={false}
          tickLine={false}
          type='category'
          // @ts-ignore
          tick={{ angle: -25, dy: 10 }}
          fontSize={12}
          interval={0}
          {...addtionalStyling}
        />
        <YAxis type='number' hide />
        {!noTooltip && (
          <Tooltip
            content={CustomTooltip}
            isAnimationActive={isAnimationActive}
            cursor={false}
            animationDuration={200}
            animationEasing='ease-out'
          />
        )}

        <Bar
          dataKey='total_spend'
          name={firstLabel}
          label={renderCustomBarLabel}
          maxBarSize={maxBarSize}
          isAnimationActive={isAnimationActive}
        >
          {chartData.map((entry, index) => {
            return (
              <Cell
                values='solidBar'
                key={`cell-${index}`}
                className='omni-bar-cell'
                fill={entry.currentColor}
                stroke={entry.currentColor}
                strokeWidth={2}
                // @ts-ignore
                radius={barChartRadius}
              />
            );
          })}
        </Bar>

        {/* `hasSingleBar` would props would hide other bar */}
        {!hasSingleBar && (
          <Bar dataKey='spend' name={secondLabel} label={renderCustomBarLabel} isAnimationActive={isAnimationActive}>
            {chartData.map((entry, index) => {
              return (
                <Cell
                  values='lightBar'
                  key={`cell-${index}`}
                  fill={entry.currentColor}
                  fillOpacity={0.2}
                  stroke={entry.currentColor}
                  strokeWidth={1}
                  // @ts-ignore
                  radius={barChartRadius}
                />
              );
            })}
          </Bar>
        )}

        {!noLegend && <Legend content={renderLegend} />}
      </BarChart>
    </ResponsiveContainer>
  );
}
