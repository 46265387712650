import React from 'react';
import { defaultChartValue, formatCurrency, formatPercentage, maxDomainRangeSetterForBarChart, formatTitleCase } from '@/utils/helpers';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Cell, ResponsiveContainer, CartesianGrid } from 'recharts';
import { barChartGapBetweenBarAndValue, barChartRadius, barChartYAxisMaxLength, channelDarkColorObj } from '@/utils/constants';
import { BaseChartType, HashMap } from '@/types';
import clsx from 'clsx';

type BarCharCustomType = BaseChartType & {
  hasSingleBar?: boolean;
  singleBarColor?: string;
  maxBarSize?: number;
  noLegend?: boolean;
  noTooltip?: boolean;
  firstLabel?: string;
  secondLabel?: string;
  noCartesian?: boolean;
  isPercentage?: boolean;
  barCategoryGap?: number;
  barGap?: number;
  topYAxisLabel?: boolean;
  fieldKeys?: {
    yAxis: string;
    solidBar: string;
    lightBar?: string;
  };
  marginControl?: Record<string, number>;
  isDollar?: boolean;
  isNoDataLabel?: boolean;
  isPDF?: boolean;
};

const renderLegend = (props: HashMap) => {
  const { payload } = props;
  return (
    <ul className='flex flex-row justify-center items-center relative -bottom-7'>
      {payload.map((entry: HashMap, index: number) => (
        <li key={`item-${index}`} className='flex flex-row items-center mr-4 text-xs text-neutral-100'>
          <svg width='18' height='18' className='mr-2'>
            <rect
              width='18'
              height='18'
              rx='4'
              ry='4'
              fill={entry.payload.children[0].props.fill}
              fillOpacity={index === 1 ? '0.2' : 1}
              stroke={entry.payload.children[0].props.fill}
              strokeWidth={1}
            />
          </svg>
          {entry.value}
        </li>
      ))}
    </ul>
  );
};

export function BarChartCustom({
  id,
  isAnimationActive = true,
  data,
  hasSingleBar,
  singleBarColor,
  maxBarSize,
  noLegend = false,
  noTooltip = false,
  firstLabel,
  secondLabel,
  noCartesian = false,
  isPercentage,
  isDollar,
  cartesianProps = {},
  barCategoryGap,
  barGap,
  fieldKeys,
  marginControl,
  formatter,
  maxDomainRef,
  topYAxisLabel = false,
  isNoDataLabel = true,
  isPDF = false
}: BarCharCustomType) {
  const yaxisField = fieldKeys?.yAxis ?? 'name';

  const chartDataMappedWithColor = React.useMemo(() => {
    let maximumValue = 0;
    const mappedData = data.map((element) => {
      const currentColor = singleBarColor ? singleBarColor : channelDarkColorObj[element?.[yaxisField]];
      if (maxDomainRef) {
        maximumValue = Math.max(maximumValue, element?.[maxDomainRef]);
      }

      return {
        ...element,
        currentColor
      };
    });

    return {
      data: mappedData,
      maximumValue
    };
  }, [data, singleBarColor, yaxisField, maxDomainRef]);

  const renderCustomBarLabel = ({ x, y, width, height, value, ...rest }: HashMap) => {
    return (
      <text
        x={x + width + barChartGapBetweenBarAndValue}
        textAnchor='right'
        width={width}
        y={y}
        className={clsx(isPDF ? 'fill-black text-black text-sm' : 'fill-neutral-100 text-neutral-100 text-xs')}
        dy={rest.values === 'solidbar' && value === 0 ? (hasSingleBar ? height / 2 : height / 0.9) : height / 1.5}
      >
        {value === 0 && rest.values === 'lightBar'
          ? ''
          : value === 0 && isNoDataLabel
          ? defaultChartValue()
          : formatter
          ? formatter(parseFloat(value), 0)
          : isPercentage
          ? formatPercentage(value)
          : formatCurrency(value, 0)}
      </text>
    );
  };

  const renderCustomTooltip = (props: HashMap) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <React.Fragment>
          <div
            className='p-4 rounded-[8px]'
            style={{
              background: 'radial-gradient(86.82% 192.89% at 73.58% 28.49%, #373F45 0%, #566169 100%)'
            }}
          >
            <p className='flex flex-row items-center text-white'>
              <svg width='14' height='14' className='mr-2 rounded-sm'>
                <rect
                  width='14'
                  height='14'
                  fill={props.payload[0].payload.currentColor}
                  fillOpacity={1}
                  stroke={props.payload[0].payload.currentColor}
                  strokeWidth={1}
                />
              </svg>
              {`${
                firstLabel
                  ? formatTitleCase(props.payload[0]?.[fieldKeys?.yAxis || 'name'])
                  : formatTitleCase(props.payload[0].payload?.[fieldKeys?.yAxis || 'name'])
              }
                  ${isDollar && !isPercentage ? formatCurrency(props.payload[0].value, 0) : ''}
                  ${isPercentage ? formatPercentage(props.payload[0].value) : ''}
              `}
            </p>
            {props.payload[1] && (
              <p className='flex flex-row items-center text-white'>
                <svg width='14' height='14' className='mr-2 rounded-sm'>
                  <rect
                    width='14'
                    height='14'
                    fill={props.payload[1].payload.currentColor}
                    fillOpacity={0.2}
                    stroke={props.payload[1].payload.currentColor}
                    strokeWidth={1}
                  />
                </svg>
                {`${formatTitleCase(props.payload[1]?.[fieldKeys?.yAxis || 'name'])}
                  ${isDollar && !isPercentage ? formatCurrency(props.payload[1].value, 0) : ''}
                  ${isPercentage ? formatPercentage(props.payload[1].value) : ''}
                `}
              </p>
            )}
          </div>
        </React.Fragment>
      );
    }
    return null;
  };

  const marginStyling = marginControl ?? { left: 30, right: 35, bottom: 30 };

  const maxDomainRange = (dataMax: number) => {
    return maxDomainRangeSetterForBarChart(dataMax, chartDataMappedWithColor.maximumValue);
  };

  const maxRangeConfig = maxDomainRef ? maxDomainRange : 'dataMax';

  const cartesianStyle = isPDF
    ? {
        strokeWidth: 0.5,
        strokeOpacity: 0.5
      }
    : {
        strokeWidth: 1,
        strokeOpacity: 1
      };

  return (
    <ResponsiveContainer width='100%' height='100%' className={`recharts-responsive-container-large`}>
      <BarChart
        layout='vertical'
        data={chartDataMappedWithColor?.data}
        barCategoryGap={barCategoryGap ?? 8}
        barGap={barGap ?? 8}
        margin={marginStyling}
        id={id}
      >
        <XAxis type='number' hide domain={[0, maxRangeConfig]} />
        <YAxis
          type='category'
          dataKey={yaxisField}
          tickMargin={10}
          width={barChartYAxisMaxLength}
          axisLine={false}
          tickLine={false}
          tick={topYAxisLabel ? <CustomizedYAxisTopTick isPDF={isPDF} maxBarSize={maxBarSize} /> : <CustomizedYAxisTick isPDF={isPDF} />}
        />
        {!noTooltip && (
          <Tooltip
            content={renderCustomTooltip}
            cursor={false}
            isAnimationActive={isAnimationActive}
            animationDuration={200}
            animationEasing='ease-out'
          />
        )}
        {!noCartesian && <CartesianGrid stroke='#F0F4F9' {...cartesianStyle} {...cartesianProps} />}
        <Bar dataKey={fieldKeys?.solidBar ?? 'pv'} name={firstLabel} label={renderCustomBarLabel} barSize={maxBarSize}>
          {chartDataMappedWithColor?.data?.map((entry, index) => {
            return (
              <Cell
                values={'solidbar'}
                key={`cell-${index}`}
                fill={entry.currentColor}
                stroke={entry.currentColor}
                strokeWidth={2}
                // @ts-ignore
                radius={barChartRadius}
              />
            );
          })}
        </Bar>

        {/* `hasSingleBar` would props would hide other bar */}
        {!hasSingleBar && (
          <Bar
            dataKey={fieldKeys?.lightBar ?? 'uv'}
            // style={{ transform: 'translate(0,5px)' }}
            name={secondLabel}
            label={renderCustomBarLabel}
            barSize={maxBarSize}
          >
            {chartDataMappedWithColor?.data?.map((entry, index) => {
              return (
                <Cell
                  values={'lightBar'}
                  key={`cell-${index}`}
                  fill={entry.currentColor}
                  fillOpacity={0.2}
                  stroke={entry.currentColor}
                  strokeWidth={1}
                  // @ts-ignore
                  radius={barChartRadius}
                />
              );
            })}
          </Bar>
        )}

        {!noLegend && <Legend content={renderLegend} />}
      </BarChart>
    </ResponsiveContainer>
  );
}

function CustomizedYAxisTick({ x, y, payload, isPDF }: HashMap) {
  return (
    <text x={x} y={y} textAnchor='end' className={clsx(isPDF ? 'fill-black text-base' : 'fill-neutral-100 text-xs')}>
      {formatTitleCase(payload.value)}
    </text>
  );
}

function CustomizedYAxisTopTick({ x, y, payload, ...rest }: HashMap) {
  const offset = -rest?.maxBarSize / 1.5;
  const customizedXTransform = x + 16;

  return (
    <text
      x={customizedXTransform}
      y={y + offset}
      textAnchor='start'
      className={clsx(rest?.isPDF ? 'fill-black text-base' : 'fill-neutral-100 text-xs')}
    >
      {formatTitleCase(payload.value)}
    </text>
  );
}
