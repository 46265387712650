import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { round } from 'lodash';
import { creatCsvFile } from 'download-csv';
import { formatCurrency } from '@/utils/helpers';
import { downloadCsvFork } from '@/utils/downloadCsv';
import { AppButtonWrapper, AppLayout, OverflowContent } from '@/components/Layout/DesignLayout';
import { Card, CardHeaderIcon, PriceAggregationCard } from '@/components/Common/Card';
import { GButton } from '@/components/Common/Button';
import { AppFooter } from '@/components/Layout/Footer';
import { Title } from '@/components/Common/Typography';
import { ReactComponent as InlineTextLogo } from '@/assets/images/omni-only-inline-text-logo-white.svg';
import { Checkbox } from '@/components/Common/Checkbox';
import { SliderCustom } from '@/components/Common/SliderCustom';
import { Modal } from '@/components/Common/Modal';
import { ReactComponent as GlobLogo } from '@/assets/images/omni-glob.svg';
import { ReactComponent as OmniLogo } from '@/assets/images/omni-only-inline-text-logo.svg';
import { useOptimiseResult } from './hooks/useOptimiseResultLogic';
import { useAppSelector } from '@/store/hooks';
import { LoadingToastMessage } from '@/components/Common/Toast';
import { abcCoefficientModelApi } from '@/api/modelApi';
import { OptimiseChart } from '@/components/Chart/OptimiseChart';

export function OptimiseResult() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = React.useState(false);
  const loadingCoefficientId = React.useRef(null);
  const [loadingCoefficientFlag, setLoadingCoefficientFlag] = React.useState(false);
  const { yearly_values } = useAppSelector((state) => state.models.yearlyMMMBudget);
  const curSessionId = useAppSelector((state) => state.models.curSessionId);

  const {
    changeInSpendSlider,
    changeInTotalSpendCheckbox,
    changeInOptimisedSpendCheckbox,
    changeInSpendCheckbox,
    spendCheck,
    optimisedSpendCheck,
    totalSpendCheck,
    spendSliderValue,
    filterDataV3: filterData,
    aggregateDataOptimisedV3: aggregatedOptimisedData
  } = useOptimiseResult(yearly_values);

  const headerData = React.useMemo(() => {
    return {
      title: 'Optimise Your Results',
      currentState: 'Optimise',
      subtleText: 'Adjust the settings to optimise your investment',
      helpTooltipProps: {
        title: 'Optimise your spend and review the forecasted results',
        description:
          'Toggle on the slider found on the left to see how increasing or decreasing spend will affect your client’s Return on Investment per channel and overall revenue.'
      }
    };
  }, []);

  const handleApprove = () => {
    navigate('/optimise-confirm');
  };

  const modalIcon = () => (
    <div className='relative'>
      <GlobLogo className='absolute -left-1' />
      <i className='relative text-[87px] fa-light fa-bezier-curve'></i>
    </div>
  );

  const handlePreviousRoute = () => {
    navigate('/analysis-model');
  };

  const createAbcCoefficientDataCsv = async () => {
    if (curSessionId) {
      setLoadingCoefficientFlag(true);

      // @ts-ignore
      loadingCoefficientId.current = toast(<LoadingToastMessage title={'Generating ABC Coefficient CSV'} />, {
        autoClose: false
      });

      const exportFileName = 'abc_coefficient.csv';
      let data = [];

      try {
        const response = await abcCoefficientModelApi(curSessionId);
        data = response?.data ?? [];
      } catch (error) {
        console.log('Error generated when calling the co-efficient API: ', error);
      }

      const columns = {
        a: 'a',
        b: 'b',
        c: 'c',
        channel: 'channel'
      };

      const formattedData = data.map((coefficient) => ({
        a: coefficient.a || '',
        b: coefficient.b || '',
        c: coefficient.c || '',
        channel: coefficient.channel
      }));

      const csvFile = creatCsvFile(formattedData, columns);
      downloadCsvFork(csvFile, exportFileName);
      // @ts-ignore
      toast.dismiss(loadingCoefficientId.current);

      setLoadingCoefficientFlag(false);
    }
  };

  return (
    <div className={`opacity-[1] h-full`}>
      <AppLayout headerProps={headerData}>
        <OverflowContent className='flex flex-col xl:flex-row gap-10 w-full text-neutral-100 ' isRequireSpace>
          <Card size='sm' className='flex-1 flex-grow-[2] min-w-0 h-[680px] flex flex-col gap-[18px]' noShadow>
            <div className='flex justify-around gap-4'>
              <PriceAggregationCard
                title='Total revenue uplift'
                value={formatCurrency(round(aggregatedOptimisedData.totRevenue, 0), 0)}
                unformattedValue={aggregatedOptimisedData.totRevenue}
              />
              <PriceAggregationCard
                title='Revenue uplift %'
                value={aggregatedOptimisedData.revenueUplift !== Infinity ? `${round(aggregatedOptimisedData.revenueUplift, 1)}%` : '0%'}
                unformattedValue={aggregatedOptimisedData.revenueUplift}
              />
              <PriceAggregationCard
                title='Expenditure uplift'
                value={formatCurrency(round(aggregatedOptimisedData.expanditureUplift, 0), 0)}
                unformattedValue={aggregatedOptimisedData.expanditureUplift}
              />
            </div>
            <div className='flex flex-col xl:flex-row gap-[30px] h-full'>
              <div className='min-w-[272px]'>
                <Card size='noshadow'>
                  <Title as='h5' className='text-center mb-4'>
                    Show / Hide data
                  </Title>
                  <div className='flex flex-row gap-6 lg:gap-2 lg:flex-col'>
                    <div className='w-3/5 lg:w-full'>
                      <div>
                        <SliderCustom handleChange={changeInSpendSlider} value={spendSliderValue} />
                        <Checkbox label='Total Spend Change' checked={totalSpendCheck} handleChange={changeInTotalSpendCheckbox} />
                      </div>
                    </div>
                    <div className='w-2/5 lg:w-full'>
                      <div>
                        <div className='rounded-app bg-[#2AC8DD] striped w-full h-[19px] mb-2' />
                        <Checkbox label='Optimised Spend' checked={optimisedSpendCheck} handleChange={changeInOptimisedSpendCheckbox} />
                      </div>
                      <div className='mt-4 lg:mt-2'>
                        <div className='rounded-app bg-[#2AC8DD] w-full h-[19px] mb-2' />
                        <Checkbox label='Current Spend' checked={spendCheck} handleChange={changeInSpendCheckbox} />
                      </div>
                    </div>
                  </div>
                </Card>
                <div className='flex xl:w-[272px] px-[29px] py-[21px] splash-wrapper rounded-[10px] mt-[25px] flex-col gap-3'>
                  <InlineTextLogo />
                  <p className='text-white text-xs'>Upload results to Omni Channel Planner for further customisation</p>
                  <GButton
                    type='button'
                    bgGradient='gray'
                    size='sm'
                    className='text-xs justify-end w-full'
                    suffix={<i className='fa-light fa-chevron-right' />}
                    onClick={() => setModalOpen(true)}
                  >
                    Download ABC data
                  </GButton>
                </div>
              </div>

              <Card size='noshadow' className='h-full w-full'>
                <Title as='h5' className='text-center'>
                  Media Spend Optimisation ('000)
                </Title>
                <OptimiseChart
                  data={filterData}
                  margin={{ top: 30, left: 10, right: 10 }}
                  formatter={formatCurrency}
                  fieldKeys={{ xAxis: 'name', solidBar: 'current_spend', stripeBar: 'optimal_spend', lightBar: 'total_spend' }}
                />
              </Card>
            </div>
          </Card>
        </OverflowContent>

        <AppFooter
          leftSection={
            <GButton variant='default' size='md' className='px-0' onClick={handlePreviousRoute}>
              <i className='fa-regular fa-angle-left h-[16px] w-[10px]'></i>
              <div className='text-md font-semibold'>Previous</div>
            </GButton>
          }
          rightSection={
            <AppButtonWrapper>
              <GButton variant='primary' size='md' suffix={<i className='fa-light fa-chevron-right' />} onClick={handleApprove}>
                Approve & continue
              </GButton>
            </AppButtonWrapper>
          }
        />

        {/* Modal */}
        <Modal open={modalOpen} title='demo' onClose={() => setModalOpen(false)} size='lg'>
          <CardHeaderIcon icon={modalIcon} />
          <OmniLogo className='' />
          <Title as='h4' className='card-title-text mt-[20px]'>
            ABC curves are ready to be sent to Omni
          </Title>
          <div className='flex flex-col gap-2 mt-[30px]'>
            <GButton
              type='button'
              bgGradient='gray'
              className='text-lg justify-end'
              disabled={loadingCoefficientFlag}
              onClick={createAbcCoefficientDataCsv}
            >
              View results
            </GButton>
            <GButton type='button' className='text-base font-semibold justify-end' onClick={() => setModalOpen(false)}>
              Back to optimisation
            </GButton>
          </div>
        </Modal>
      </AppLayout>
    </div>
  );
}
