import React from 'react';
import { ReactComponent as MainBgLogo } from '@/assets/images/omni-rotate-logo.svg';
import { ReactComponent as ChecklistLogo } from '@/assets/images/omni-checklist-glob.svg';
import { GButton } from '@/components/Common/Button';
import { Title } from '@/components/Common/Typography';
import { Card, CardHeaderIcon } from '@/components/Common/Card';
import { LoadingSection } from '@/components/Common/Loading';
import { useNavigate } from 'react-router-dom';
import {
  fetchEvalutionMetricCheck,
  fetchMediaChannelPassingModel,
  fetchMMMliteDecomp,
  fetchModelledResult,
  fetchYearlyMMMLiteBudgetResult
} from '@/store/modelsSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { intervalBetweenEvaluationAndRoiApiCheck, intervalBetweenModelApiCheck } from '@/utils/constants';

export function ModalConfirmView() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = React.useState(false);
  const currSessionId = useAppSelector((state) => state.models.curSessionId);
  const evalutionMetric = useAppSelector((state) => state.models.evalutionMetric);
  const evalutionMetricStageLoading = useAppSelector((state) => state.models.evalutionMetricStageLoading);
  const modelledResults = useAppSelector((state) => state.models.modelledResults);
  const liteDecomp = useAppSelector((state) => state.models.liteDecomp);
  const dispatch = useAppDispatch();
  const [intervalId, setIntervalId] = React.useState('');

  const redirectNextLoaderStage = () => {
    setLoading(true);
  };

  const handleLoadingComplete = React.useCallback(() => {
    if (modelledResults?.length && liteDecomp?.length) {
      navigate('/analysis-sales?isRedirect=true');
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const callApiForData = () => {
    const id = setInterval(() => {
      dispatch(fetchEvalutionMetricCheck(currSessionId));
    }, intervalBetweenModelApiCheck);

    // @ts-ignore
    setIntervalId(id);
  };

  React.useEffect(() => {
    if (!evalutionMetricStageLoading && evalutionMetric.length > 0) {
      clearInterval(intervalId);
      setTimeout(() => {
        dispatch(fetchModelledResult(currSessionId));
        dispatch(fetchYearlyMMMLiteBudgetResult(currSessionId));
        dispatch(fetchMMMliteDecomp(currSessionId));
        dispatch(fetchMediaChannelPassingModel(currSessionId));
      }, intervalBetweenEvaluationAndRoiApiCheck);
    }
  }, [evalutionMetric, intervalId, currSessionId, evalutionMetricStageLoading, dispatch, navigate]);

  React.useEffect(() => {
    if (modelledResults?.length && liteDecomp?.length) {
      navigate('/analysis-sales?isRedirect=true');
      setLoading(false);
    }
  }, [modelledResults, liteDecomp, navigate]);

  return (
    <LoadingSection isLoading={isLoading} onLoadingComplete={handleLoadingComplete} callApiForData={callApiForData} isLoadingCounter>
      <div className='relative splash-wrapper h-full flex flex-col items-center justify-center overflow-hidden text-neutral-100'>
        <MainBgLogo
          className='absolute'
          style={{
            transform: `rotate(110deg)`,
            transition: 'transform 0.5s ease-in-out'
          }}
        />
        <div className='bg-white relative w-[380px] h-[380px] rounded-full flex justify-center items-center'>
          <Card>
            <CardHeaderIcon icon={ChecklistLogo} />
            <Title as='h4' className='card-title-text'>
              Confirmation
            </Title>
            <p className='text-base tracking-[0.03em] min-h-[151px]'>
              Please confirm you have checked all data before the modelling is commenced
            </p>
            <div className='flex flex-col gap-2'>
              <GButton type='button' bgGradient='gray' className='text-lg justify-end' onClick={redirectNextLoaderStage}>
                Confirm and proceed
              </GButton>
              <GButton type='button' className='text-base font-semibold justify-end' onClick={() => navigate('/review-voice')}>
                Back to review data
              </GButton>
            </div>
          </Card>
        </div>
      </div>
    </LoadingSection>
  );
}
