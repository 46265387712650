import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SplashView } from '@/views/SplashView/SplashView';
import { UploadView } from '@/views/UploadView/UploadView';
import { ChannelView } from '@/views/ReviewView/ChannelView';
import { ReviewView } from '@/views/ReviewView/ReviewView';
import { ShareVoiceView } from '@/views/ReviewView/ShareVoiceView';
import { ModalConfirmView } from '@/views/AnalysisView/ModalConfirmView';
import { SalesView } from '@/views/AnalysisView/SalesView';
import { ModelResultView } from '@/views/AnalysisView/ModelResultView';
import { WarningView } from '@/views/AnalysisView/WarningView';
import { ResultsDashboard } from '@/views/ResultsDashboard/ResultsDashboard';
import { OptimiseResult } from './views/OptimiseResultView/OptimiseResultView';
import { MyAccount } from './views/AccountView/MyAccount';
import { TermsView } from './views/TermsView/TermsView';
import { OptimiseConfirmView } from './views/OptimiseResultView/OptimiseConfirmView';
import { NotFoundView } from './views/NotFoundView/NotFoundView';
import ToastContainerCustom from './components/Common/Toast';
import PdfGenerate from './views/PDFGenerate';
import { useOktaInitializer } from './components/Common/Okta';
import { PrivateRoute } from './components/Common/PrivateRoute';

function App() {
  const { authClient, loading } = useOktaInitializer();

  return (
    <div className='omni-app'>
      <Routes>
        <Route
          path='/'
          element={
            <PrivateRoute isLoading={loading}>
              <SplashView authClient={authClient} />
            </PrivateRoute>
          }
        />
        <Route
          path='/upload'
          element={
            <PrivateRoute isLoading={loading}>
              <UploadView />
            </PrivateRoute>
          }
        />
        <Route
          path='/review-validate'
          element={
            <PrivateRoute isLoading={loading}>
              <ReviewView />
            </PrivateRoute>
          }
        />
        <Route
          path='/review-channel'
          element={
            <PrivateRoute isLoading={loading}>
              <ChannelView />
            </PrivateRoute>
          }
        />
        <Route
          path='/review-voice'
          element={
            <PrivateRoute isLoading={loading}>
              <ShareVoiceView />
            </PrivateRoute>
          }
        />
        <Route
          path='/analysis-confirm'
          element={
            <PrivateRoute isLoading={loading}>
              <ModalConfirmView />
            </PrivateRoute>
          }
        />
        <Route
          path='/analysis-sales'
          element={
            <PrivateRoute isLoading={loading}>
              <SalesView />
            </PrivateRoute>
          }
        />
        <Route
          path='/analysis-model'
          element={
            <PrivateRoute isLoading={loading}>
              <ModelResultView />
            </PrivateRoute>
          }
        />
        <Route
          path='/analysis-warning'
          element={
            <PrivateRoute isLoading={loading}>
              <WarningView />
            </PrivateRoute>
          }
        />
        <Route
          path='/optimise-result'
          element={
            <PrivateRoute isLoading={loading}>
              <OptimiseResult />
            </PrivateRoute>
          }
        />
        <Route
          path='/optimise-confirm'
          element={
            <PrivateRoute isLoading={loading}>
              <OptimiseConfirmView />
            </PrivateRoute>
          }
        />
        <Route
          path='/result-dashboard-confirm'
          element={
            <PrivateRoute isLoading={loading}>
              <ResultsDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path='/my-account'
          element={
            <PrivateRoute isLoading={loading}>
              <MyAccount />
            </PrivateRoute>
          }
        />
        <Route path='/pdf-generate' element={<PdfGenerate />} />
        <Route path='/terms' element={<TermsView />} />
        <Route path='/404' element={<NotFoundView />} />
        <Route path='*' element={<Navigate to='/404' replace />} />
      </Routes>
      <ToastContainerCustom />
    </div>
  );
}

export default App;
