import React from 'react';
import { AppButtonWrapper, AppLayout, OverflowContent } from '@/components/Layout/DesignLayout';
import { Card } from '@/components/Common/Card';
import { GButton, SButton } from '@/components/Common/Button';
import { Title } from '@/components/Common/Typography';
import { ComposeBarLineChart } from '@/components/Chart/ComposeBarLineChart';
import { BarChartCustom } from '@/components/Chart/BarChart';
import { useNavigate } from 'react-router-dom';
import { flickHelpTooltip, modelsRun } from '@/store/modelsSlice';
import clsx from 'clsx';
import { cardHeight } from '@/utils/constants';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

export function ShareVoiceView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const headerData = React.useMemo(() => {
    return {
      title: 'Your Share of Voice',
      currentState: 'Review',
      subtleText: 'Please review your Share of Voice',
      helpTooltipProps: {
        title: 'Validate the Share of Voice',
        description: (
          <p>
            The Share of Voice is measured by the Total Spend of your client vs the Total Market Spend.
            <p className='mt-2'>
              The client's SOV is provided with their ranking shown amongst their competitors and how it has affected their Total Sales per
              quarter.
            </p>
            <p>
              Note: The competitors are determined by the industry mapping in DAWA. Please contact Andree Norbury’s team if you require
              amendments
            </p>
          </p>
        )
      }
    };
  }, []);
  const curSessionId = useAppSelector((state) => state.models.curSessionId);

  const [isLoading, setIsLoading] = React.useState(false);

  const competitorSpends = useAppSelector((state) => state.models.competitorSpends);
  const shareOfVoice = useAppSelector((state) => state.models.shareOfVoice);

  const handleRedirectToAnalysis = async () => {
    setIsLoading(true);
    try {
      const res = await dispatch(modelsRun(curSessionId)).unwrap();
      console.log('Success: ', res);
    } catch (error) {
      console.error('Error: ', error);
    }

    setIsLoading(false);
    navigate('/analysis-confirm');
  };

  return (
    <AppLayout headerProps={headerData}>
      <OverflowContent className='flex flex-col xl:flex-row gap-10 w-full  text-neutral-100'>
        <Card size='sm' className={clsx('flex-1 min-w-0', cardHeight.md)} noShadow>
          <Title as='h5' className='text-center mb-4'>
            Competitors by Advertising Spend $ ('000)
          </Title>
          <BarChartCustom
            data={competitorSpends}
            marginControl={{ left: -40, right: 35, bottom: 30 }}
            topYAxisLabel={true}
            singleBarColor='#48B8A7'
            maxDomainRef='spend'
            fieldKeys={{ yAxis: 'name', solidBar: 'spend' }}
            hasSingleBar
            maxBarSize={25}
            isDollar
            noLegend
            cartesianProps={{ horizontal: false }}
          />
        </Card>
        <Card size='sm' className={clsx('flex-1 min-w-0', cardHeight.md)} noShadow>
          <Title as='h5' className='text-center mb-4'>
            Sales ('000) & Share of Voice Over Time
          </Title>
          <ComposeBarLineChart
            data={shareOfVoice}
            hasSingleBar='#00A0D3'
            firstLabel='Sales'
            secondLabel='Share of voice'
            maxDomainRef='sales'
            fieldKeys={{
              xAxis: 'name',
              bar: 'sales',
              line: 'sov'
            }}
            isRightYAxis
            cartesianProps={{ vertical: false }}
            isLinePercentage
            xAxisTickMargin={14}
            rotateAngle={-32}
            fixedMultiple={50000}
            isMaxRange={false}
          />
        </Card>
      </OverflowContent>

      <div className='flex flex-col justify-center lg:flex-row lg:justify-between items-center'>
        <div className='flex flex-row items-center cursor-pointer' onClick={() => navigate('/review-channel')}>
          <GButton variant='default' size='md' className='px-0'>
            <i className='fa-regular fa-angle-left h-[16px] w-[10px]'></i>
            <div className='text-md font-semibold'>Previous</div>
          </GButton>
        </div>
        <AppButtonWrapper className='flex flex-row mx-auto mt-4 lg:mt-0 lg:mr-0'>
          <SButton onClick={() => dispatch(flickHelpTooltip())}>My data is wrong</SButton>
          <GButton
            disabled={isLoading}
            variant='primary'
            size='md'
            suffix={<i className='fa-light fa-chevron-right' />}
            onClick={handleRedirectToAnalysis}
          >
            Approve & continue
          </GButton>
        </AppButtonWrapper>
      </div>
    </AppLayout>
  );
}
