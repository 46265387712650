import React from 'react';
import { barChartRadius, channelDarkColorObj, darkColors } from '@/utils/constants';
import { Bar, Cell, BarChart, Legend, ResponsiveContainer, XAxis, Tooltip, YAxis } from 'recharts';
import { formatCurrency } from '@/utils/helpers';
import clsx from 'clsx';
import { BaseChartType, HashMap } from '@/types';

type BarMixChartType = BaseChartType & {
  hasSingleColor?: boolean;
  colorTypes?: { solid: string; border: string; stripe: string };
  margin?: Record<string, any>;
  minPointSize?: number;
  isPDF?: boolean;
  fieldKeys?: {
    xAxis: string;
    solidBar: string;
    stripeBar: string;
    lightBar: string;
  };
};

const negativeColorBand = '#E15778';
const positiveColorBand = '#48B8A7';

const renderCustomTooltip = (props: HashMap) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <>
        <div
          className='p-4 rounded-[8px]'
          style={{
            background: 'var(--gradient-radial-gray)'
          }}
        >
          <p className='flex flex-row items-center text-white'>
            <svg width='14' height='14' className='mr-2 rounded-sm'>
              <rect
                width='14'
                height='14'
                fill={props.payload[0].payload.pvCurrentColor}
                fillOpacity={1}
                stroke={props.payload[0].payload.pvCurrentColor}
                strokeWidth={1}
              />
            </svg>
            {`${props.payload[0].name} `} {formatCurrency(props.payload[0].value, 0)}
          </p>
          {props.payload[1] && (
            <p className='flex flex-row items-center text-white'>
              <svg width='14' height='14' className='mr-2'>
                <rect
                  width='14'
                  height='14'
                  fill={props.payload[1].payload.uvCurrentColor}
                  fillOpacity={1}
                  stroke={props.payload[1].payload.uvCurrentColor}
                  strokeWidth={1}
                />
              </svg>
              {`${props.payload[1].name} `} {formatCurrency(props.payload[1].value, 0)}
            </p>
          )}
          {props.payload[2] && (
            <p className='flex flex-row items-center text-white'>
              <svg width='14' height='14' className='mr-2 rounded-sm'>
                <rect
                  width='14'
                  height='14'
                  fill={props.payload[2].payload.amtCurrentColor}
                  fillOpacity={0.2}
                  stroke={props.payload[2].payload.amtCurrentColor}
                  strokeWidth={1}
                />
              </svg>
              {`${props.payload[2].name} `}{' '}
              {`${props.payload[2].payload?.['is_negative_total_spend'] ? '- ' : ''}${formatCurrency(
                props.payload[2].payload?.total_spend,
                0
              )}`}
            </p>
          )}
        </div>
      </>
    );
  }
  return null;
};

const renderLegend = (props: HashMap) => {
  const { payload } = props;

  return (
    <ul className='flex flex-row justify-center items-center mt-4'>
      {payload?.map((entry: any, index: number) => {
        if (index >= 3) {
          return null;
        }
        const fill = entry.payload.children[0].props.fill;

        const fillOpacity = index === 2 ? 0.4 : 1;

        return (
          <li key={`item-${index}`} className='flex flex-row items-center mr-4'>
            <svg width='18' height='18' className='mr-2'>
              <rect
                width='18'
                height='18'
                fill={fill}
                fillOpacity={fillOpacity}
                stroke={index === 2 ? '#00A0D3' : fill}
                strokeWidth={1}
                rx={4}
                ry={4}
              />
            </svg>
            {entry.value}
          </li>
        );
      })}
    </ul>
  );
};

export function OptimiseChart({
  data,
  margin,
  fieldKeys,
  minPointSize,
  id,
  isAnimationActive = true,
  formatter,
  isPDF = false
}: BarMixChartType) {
  const xaxisField = fieldKeys?.xAxis ?? 'name';

  const chartData: HashMap[] = React.useMemo(() => {
    return data.map((element) => {
      const currentColor = channelDarkColorObj[element?.[xaxisField]];
      const pvCurrentColor = currentColor;
      let uvCurrentColor = currentColor;
      uvCurrentColor = `url(#omni-color-stripes-${uvCurrentColor.substring(1)})`;
      const amtCurrentColor = currentColor;

      return {
        ...element,
        pvCurrentColor,
        uvCurrentColor,
        amtCurrentColor
      };
    });
  }, [data, xaxisField]);

  const renderCustomBarSolidLabel = ({ x, y, width, height, value }: HashMap) => {
    let label = formatter ? formatter(value) : formatCurrency(value, 0);

    return (
      <g className={clsx(height === 0 ? 'hidden' : '')}>
        <text
          x={x + width / 2}
          y={y + (height + 8) / 2}
          textAnchor='middle'
          className={clsx(isPDF ? 'text-base' : 'text-xs', 'fill-white text-white font-semibold')}
        >
          {label}
        </text>
      </g>
    );
  };

  const renderCustomBarStipeLabel = ({ x, y, width, height, values }: HashMap) => {
    let label = formatter ? formatter(values) : formatCurrency(values, 0);

    return (
      <g className={clsx(height === 0 ? 'hidden' : '')}>
        <text
          x={x + width / 2}
          y={y + (height + 8) / 2}
          textAnchor='middle'
          className={clsx(isPDF ? 'text-base' : 'text-xs', 'fill-white text-white font-semibold')}
        >
          {label}
        </text>
      </g>
    );
  };

  const renderCustomBarLabel = ({ x, y, width, height, values, ...rest }: HashMap) => {
    let label = formatter ? formatter(values) : formatCurrency(values, 0);
    const isNegativeTotalSpend = rest?.mode === 'true';

    const yHeight = isPDF ? y - 20 : y - 15;

    return (
      <g className={clsx(height === 0 ? 'hidden' : '')}>
        <foreignObject x={x} y={yHeight} width={width} height={height + 30}>
          <div
            className={clsx(isPDF ? 'text-base' : 'text-xs', 'fill-neutral-100 text-neutral-100 font-semibold text-center relative')}
            style={{
              color: isNegativeTotalSpend ? negativeColorBand : positiveColorBand
            }}
          >
            {isNegativeTotalSpend ? <>-</> : null}
            {label}
          </div>
        </foreignObject>
      </g>
    );
  };

  return (
    <ResponsiveContainer className={`recharts-responsive-container-large`} width='100%' height='100%'>
      <BarChart data={chartData} barCategoryGap={8} id={id} margin={margin ?? { top: 30, left: 30, right: 10 }}>
        <XAxis dataKey={fieldKeys?.xAxis ?? 'name'} axisLine={false} tickLine={false} tickMargin={10} />
        <YAxis hide domain={[0, 'dataMax']} />
        <Legend content={renderLegend} />
        <Tooltip
          isAnimationActive={isAnimationActive}
          content={(props) => renderCustomTooltip(props)}
          cursor={false}
          animationDuration={200}
          animationEasing='ease-out'
        />
        {/* Pattern for the stripe background of Bar */}
        <defs>
          {darkColors.map((color) => {
            const name = color.substring(1);
            return (
              <pattern
                id={`omni-color-stripes-${name}`}
                width='10'
                height='10'
                patternTransform='rotate(45 0 0)'
                patternUnits='userSpaceOnUse'
                key={color}
              >
                <g opacity={0.8} fill={color}>
                  <rect x='0' y='0' width='10' height='10' />
                  <line x1='0' y1='0' x2='0' y2='10' style={{ stroke: '#FFF', strokeWidth: 3, opacity: 0.3 }} />
                </g>
              </pattern>
            );
          })}
        </defs>

        {/* Solid Bar */}
        <Bar
          dataKey={fieldKeys?.solidBar ?? 'pv'}
          isAnimationActive={isAnimationActive}
          stackId={'currentSpend'}
          label={(props) => renderCustomBarSolidLabel(props)}
          name={'Current Spend'}
        >
          {chartData.map((entry: any, index: number) => {
            return (
              <Cell
                key={`cell-${index}`}
                className='omni-bar-solid'
                fill={entry.pvCurrentColor}
                stroke={entry.pvCurrentColor}
                data-legend-color={entry.amtCurrentColor}
                strokeWidth={2}
                // @ts-ignore
                radius={barChartRadius}
              />
            );
          })}
        </Bar>

        {/* Stripe Bar */}
        <Bar
          dataKey={fieldKeys?.stripeBar ?? 'uv'}
          isAnimationActive={isAnimationActive}
          stackId={'optimisedSpend'}
          label={(props) => renderCustomBarStipeLabel(props)}
          name={'Optimised Spend'}
        >
          {chartData.map((entry: any, index: number) => {
            return (
              <Cell
                key={`cell-${index}`}
                values={fieldKeys ? entry[fieldKeys.stripeBar] : entry.uv}
                className='omni-bar-mix-cell-stripe'
                fill={entry.uvCurrentColor}
                strokeWidth={2}
                // @ts-ignore
                radius={barChartRadius}
              />
            );
          })}
        </Bar>

        {/* Light Bar positive*/}
        <Bar
          dataKey={'pos_total_spend'}
          isAnimationActive={isAnimationActive}
          stackId={'currentSpend'}
          label={renderCustomBarLabel}
          name={'Total Spend Change'}
        >
          {chartData.map((entry: any, index: number) => {
            return (
              <Cell
                key={`cell-${index}`}
                className='omni-bar-cell'
                values={fieldKeys ? entry[fieldKeys.lightBar] : entry.amt}
                fill={'#FFFFFF'}
                mode={entry?.['is_negative_total_spend'] ? 'true' : 'false'}
                data-legend-color={entry.pvCurrentColor}
                stroke={positiveColorBand}
                strokeWidth={2}
                // @ts-ignore
                radius={barChartRadius}
              />
            );
          })}
        </Bar>

        {/* Light Bar negative*/}
        <Bar
          dataKey={'neg_total_spend'}
          isAnimationActive={isAnimationActive}
          stackId={'optimisedSpend'}
          label={renderCustomBarLabel}
          name={'Total Spend Change'}
        >
          {chartData.map((entry: any, index: number) => {
            return (
              <Cell
                key={`cell-${index}`}
                className='omni-bar-cell'
                values={fieldKeys ? entry[fieldKeys.lightBar] : entry.amt}
                fill={'#FFFFFF'}
                mode={entry?.['is_negative_total_spend'] ? 'true' : 'false'}
                data-legend-color={entry.pvCurrentColor}
                stroke={negativeColorBand}
                strokeWidth={2}
                // @ts-ignore
                radius={barChartRadius}
              />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
