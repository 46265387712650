import { HashMap } from '../types';
import axios from 'axios';
import api from './index';

export const oktaTokenCheckApi = (url, data) => {
  return axios.post<HashMap>(url + '/v1/introspect', data);
};

export const listClientsApi = () => {
  return api.get<HashMap>('/clients');
};

export const uploadClientCsvApi = (payload: any) => {
  const formData = new FormData();
  formData.append('file', payload);
  return api.post<HashMap>('/client_sales', formData);
};

export const totalMeasurableSpendApi = (sessionId: string) => {
  return api.get<HashMap[]>(`/total_measurable_spend/${sessionId}`);
};

export const mediaChannelsPassingModelApi = (sessionId: string) => {
  return api.get<HashMap[]>(`/models/${sessionId}/media_plan_check`);
};

export const competitorSpendApi = (sessionId: string) => {
  return api.get<HashMap[]>(`/competitor_spend/${sessionId}`);
};

export const modelledROIResultApi = (sessionId: string) => {
  return api.get<HashMap[]>(`/models/${sessionId}/roi`);
};

export const yearlyMMMLiteBudgetApi = (sessionId: string) => {
  return api.get<HashMap[]>(`/models/${sessionId}/yearly_mmmlite_budget_optimized`);
};

export const mmmliteDecompApi = (sessionId: string) => {
  return api.get<HashMap[]>(`/models/${sessionId}/mmmlite_decomp`);
};

export const mediaPlanVerifyApi = (sessionId: string) => {
  return api.post<any>(`/media_plan/${sessionId}/verify`, {});
};

export const modelsRunApi = (sessionId: string) => {
  return api.post<any>(`/models/${sessionId}/run`, {});
};

export const sessionsApi = () => {
  return api.get<HashMap[]>(`/sessions`);
};

export const shareOfVoiceApi = (sessionId: string) => {
  return api.get<HashMap[]>(`/share_of_voice/${sessionId}`);
};

export const evalutionMetricModelApi = (sessionId: string) => {
  return api.get<HashMap[]>(`/models/${sessionId}/evaluation_metrics`);
};

export const abcCoefficientModelApi = (sessionId: string) => {
  return api.get<HashMap[]>(`/models/${sessionId}/abc_coefficients`);
};

export const sessionRemovalApi = (sessionId: string) => {
  return api.delete<HashMap[]>(`/sessions/${sessionId}`);
};
