import { BarChartCustom } from '@/components/Chart/BarChart';
import { GButton } from '@/components/Common/Button';
import { Card } from '@/components/Common/Card';
import { Title } from '@/components/Common/Typography';
import { AppButtonWrapper, AppLayout, OverflowContent } from '@/components/Layout/DesignLayout';
import { useAppSelector } from '@/store/hooks';
import { cardHeight } from '@/utils/constants';
import { formatCurrency } from '@/utils/helpers';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export function ModelResultView() {
  const navigate = useNavigate();
  const modelledResultsData = useAppSelector((state) => state.models.modelledResults);
  const mediaSalesContribution = useAppSelector((state) => state.models.mediaSalesContribution);

  const headerData = React.useMemo(() => {
    return {
      title: 'Modelled Results',
      currentState: 'Analyse',
      subtleText: 'Media Spend, Contribution & ROI',
      helpTooltipProps: {
        title: 'Review your modelled results',
        description: 'Here, you can see the breakdown of your media spend, sales contribution and your Return on Investment per channel.'
      }
    };
  }, []);

  const handleRedirectToAnalysisConfirmation = () => {
    navigate('/optimise-result');
  };

  const formatter = (value: number) => {
    return formatCurrency(value, 0);
  };

  return (
    <AppLayout headerProps={headerData}>
      <OverflowContent className='flex flex-col xl:flex-row gap-10 w-full text-neutral-100 ' isRequireSpace>
        <Card size='sm' className={clsx('flex-1', cardHeight.md)} noShadow>
          <Title as='h5' className='text-center mb-4'>
            Media Channels Investment ('000)
          </Title>
          <BarChartCustom
            data={modelledResultsData}
            fieldKeys={{ yAxis: 'name', solidBar: 'spend' }}
            marginControl={{ left: 30, right: 60, bottom: 30 }}
            maxDomainRef='spend'
            hasSingleBar
            maxBarSize={25}
            noLegend
            isDollar
            noCartesian
          />
        </Card>
        <Card size='sm' className={clsx('flex-1', cardHeight.md)} noShadow>
          <Title as='h5' className='text-center mb-4'>
            Media Channel Contribution
          </Title>
          <BarChartCustom
            data={mediaSalesContribution}
            marginControl={{ left: 30, right: 55, bottom: 30 }}
            fieldKeys={{ yAxis: 'channel', solidBar: 'percent_contri_spend' }}
            isNoDataLabel={false}
            maxDomainRef='percent_contri_spend'
            hasSingleBar
            maxBarSize={25}
            isPercentage
            noLegend
            noCartesian
          />
        </Card>
        <Card size='sm' className={clsx('flex-1', cardHeight.md)} noShadow>
          <Title as='h5' className='text-center mb-4'>
            Media Channel ROI
          </Title>
          <BarChartCustom
            data={modelledResultsData}
            marginControl={{ left: 30, right: 55, bottom: 30 }}
            fieldKeys={{ yAxis: 'name', solidBar: 'roi' }}
            hasSingleBar
            maxBarSize={25}
            noLegend
            isDollar
            formatter={formatter}
            noCartesian
          />
        </Card>
      </OverflowContent>
      <div className='flex flex-col justify-center lg:flex-row lg:justify-between items-center'>
        <div className='flex flex-row items-center cursor-pointer' onClick={() => navigate('/analysis-sales')}>
          <GButton variant='default' size='md' className='px-0'>
            <>
              <i className='fa-regular fa-angle-left h-[16px] w-[10px]'></i>
              <div className='text-md font-semibold'>Previous</div>
            </>
          </GButton>
        </div>
        <AppButtonWrapper className='mx-auto mt-4 lg:mt-0 lg:mr-0'>
          <GButton
            variant='primary'
            size='md'
            suffix={<i className='fa-light fa-chevron-right' />}
            onClick={handleRedirectToAnalysisConfirmation}
          >
            Next
          </GButton>
        </AppButtonWrapper>
      </div>
    </AppLayout>
  );
}
