import React from 'react';
import { AppButtonWrapper, AppLayout, OverflowContent } from '@/components/Layout/DesignLayout';
import { Card, CardHeaderIcon } from '@/components/Common/Card';
import { GButton } from '@/components/Common/Button';
import { Title } from '@/components/Common/Typography';
import { ComposeBarLineChart } from '@/components/Chart/ComposeBarLineChart';
import { BarPositiveNegativeChart } from '@/components/Chart/BarPositiveNegativeChart';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BarMixChart } from '@/components/Chart/BarMixChart';
import { ReactComponent as GlobLogo } from '@/assets/images/omni-glob.svg';
import { Modal } from '@/components/Common/Modal';
import clsx from 'clsx';
import { cardHeight } from '@/utils/constants';
import { useAppSelector } from '@/store/hooks';

export function SalesView() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [searchParams] = useSearchParams();
  const liteDecomp = useAppSelector((state) => state.models.liteDecomp);
  const mediaSalesContribution = useAppSelector((state) => state.models.mediaSalesContribution);
  const externalSalesContribution = useAppSelector((state) => state.models.externalSalesContribution);

  const headerData = React.useMemo(() => {
    return {
      title: 'Contribution to Sales',
      currentState: 'Analyse',
      subtleText: 'View Contributors to Sales',
      helpTooltipProps: {
        title: 'Analyse the top contributors to your client’s sales',
        description:
          'Here, you will see how your media activities per channel and other external drivers have contributed to your client’s sales.'
      }
    };
  }, []);

  React.useEffect(() => {
    const isRedirect = searchParams.get('isRedirect');
    if (isRedirect === 'true') {
      setModalOpen(true);
      navigate('/analysis-sales', {
        replace: true
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectToModelledResults = () => {
    navigate('/analysis-model');
  };

  const handleSamePage = () => {
    setModalOpen(false);
  };

  const handleRedirectToWarning = () => {
    navigate('/analysis-warning');
  };

  const modalIcon = () => (
    <div className='relative'>
      <GlobLogo className='absolute -left-1' />
      <i className='relative text-[87px] text-neutral-100 fa-thin fa-party-horn'></i>
    </div>
  );

  return (
    <AppLayout headerProps={headerData}>
      <OverflowContent className='flex flex-col xl:flex-row gap-10 w-full text-neutral-100' isRequireSpace>
        <div className={clsx('flex-1 flex flex-col gap-5', cardHeight.md)}>
          <Card size='sm' className='flex-1' noShadow>
            <Title as='h5' className='text-center mb-4'>
              External Drivers of Sales
            </Title>
            <BarPositiveNegativeChart
              data={externalSalesContribution}
              fieldKeys={{
                bar: 'percent_contri_spend',
                xAxis: 'channel'
              }}
            />
          </Card>
          <Card size='sm' className='basis-[250px] gap-5' noShadow>
            <Title as='h5' className='text-center mb-4'>
              Media Contribution to Sales
            </Title>
            <ComposeBarLineChart
              fieldKeys={{
                bar: 'percent_contri_spend',
                xAxis: 'channel',
                line: ''
              }}
              isNoDataLabel={false}
              maxDomainRef='percent_contri_spend'
              data={mediaSalesContribution}
              rotateAngle={-25}
              noLine
              noLegend
              rotateValue
              noYAxis
              noCartesian
              isPercentage
            />
          </Card>
        </div>
        <Card size='sm' className={clsx('flex min-w-[450px]', cardHeight.md)} noShadow>
          <div className='flex flex-col h-full'>
            <div className='text-center mb-4'>
              <Title as='h5'>Major Contributors To Sales</Title>
            </div>
            <div className='flex-1'>
              <BarMixChart
                data={liteDecomp}
                fieldKeys={{ xAxis: 'name', solidBar: 'base', stripeBar: 'brand', lightBar: 'media' }}
                labelSet
                hasSingleColor
                isPercentage
                minPointSize={10}
              />
            </div>
          </div>
        </Card>
      </OverflowContent>
      {/* TODO: Create Generic AppFooter */}
      <div className='flex flex-col justify-center lg:flex-row lg:justify-between items-center'>
        <div className='flex flex-row items-center cursor-pointer' onClick={handleRedirectToWarning}>
          <GButton variant='default' size='md' className='px-0'>
            <i className='fa-regular fa-angle-left h-[16px] w-[10px]'></i>
            <div className='text-md font-semibold'>Back To Data Validation</div>
          </GButton>
        </div>
        <AppButtonWrapper className='mx-auto mt-4 lg:mt-0 lg:mr-0'>
          <GButton
            variant='primary'
            size='md'
            suffix={<i className='fa-light fa-chevron-right' />}
            onClick={handleRedirectToModelledResults}
          >
            Next
          </GButton>
        </AppButtonWrapper>
      </div>

      {/* Modal */}
      <Modal open={modalOpen} onClose={handleSamePage} size='lg'>
        <CardHeaderIcon icon={modalIcon} />
        <Title as='h4' className='card-title-text'>
          Congratulations!
        </Title>
        <div className='text-base tracking-[0.03em] text-neutral-100 pt-3'>
          <p>Your results have been successfully modelled, now you can optimise your data.</p>
          <br />
          <p>When you’re ready, you can submit your ABC curves to Omni, and export your data as CSV’s for your client.</p>
        </div>
        <div className='flex flex-col mt-[50px]'>
          <GButton variant='primary' size='md' onClick={handleSamePage}>
            Awesome - show me!
          </GButton>
        </div>
      </Modal>
    </AppLayout>
  );
}
