import React from 'react';
import Table from 'rc-table';
import { TableProps } from 'rc-table/lib/Table';
import { Title } from './Typography';
import clsx from 'clsx';
import { LoadingComp } from './Loading';

type TableType = TableProps<any> & { header?: string; isLoading?: boolean };

export function TableCustom({ header, className, data, isLoading, ...rest }: TableType) {
  return (
    <div className='omni-table-container h-full'>
      {header && (
        <Title as='h5' className='text-center pb-[45px]'>
          {header}
        </Title>
      )}
      {isLoading ? (
        <div className='relative -top-5 flex justify-center items-center h-[calc(100%-45px)]'>
          <LoadingComp />
        </div>
      ) : (
        <Table data={data} {...rest} className={clsx(className)} />
      )}
    </div>
  );
}
