import React from 'react';
import { barChartRadius, darkColors } from '@/utils/constants';
import { Bar, BarChart, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatPercentage, formatTitleCase } from '@/utils/helpers';
import { HashMap } from '@/types';
import clsx from 'clsx';

type BarPositiveNegativeChartType = {
  data: Array<Record<string, any>>;
  hasSingleBar?: string;
  maxBarSize?: number;
  isPDF?: boolean;
  fieldKeys?: {
    xAxis: string;
    bar: string;
  };
};

const defaultNegativeValue = -35;

const CustomTooltip = (props: HashMap) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <>
        <div
          className='p-4 rounded-[8px]'
          style={{
            background: 'radial-gradient(86.82% 192.89% at 73.58% 28.49%, #373F45 0%, #566169 100%)'
          }}
        >
          <p className='text-white flex flex-row items-center'>
            <svg width='14' height='14' className='mr-2 rounded-sm'>
              <rect
                width='14'
                height='14'
                fill={props.payload[0].payload.currentColor}
                fillOpacity={1}
                stroke={props.payload[0].payload.currentColor}
                strokeWidth={1}
              />
            </svg>
            {`${formatTitleCase(props.payload[0]?.payload?.channel)}  ${formatPercentage(props.payload[0].value)}`}
          </p>
          {props.payload[1] && (
            <p className='flex flex-row items-center text-white'>
              <svg width='14' height='14' className='mr-2 rounded-sm'>
                <rect
                  width='14'
                  height='14'
                  fill={props.payload[1].payload.currentColor}
                  fillOpacity={0.2}
                  stroke={props.payload[1].payload.currentColor}
                  strokeWidth={1}
                />
              </svg>
              {`${props.payload[1]?.payload?.channel}  ${formatPercentage(props.payload[1].value)}`}
            </p>
          )}
        </div>
      </>
    );
  }
  return null;
};

function formatXAxis(value: string) {
  return formatTitleCase(value);
}

export function BarPositiveNegativeChart({ data, maxBarSize, fieldKeys, isPDF }: BarPositiveNegativeChartType) {
  const chartData = React.useMemo(() => {
    let maxNegativeValue = 0;
    const payload = data.map((element, index) => {
      const currentColor = darkColors[index % data.length];
      if (element[fieldKeys?.bar] < maxNegativeValue) {
        maxNegativeValue = element[fieldKeys?.bar];
      }
      return {
        ...element,
        currentColor
      };
    });

    return {
      data: payload,
      maxNegativeValue
    };
  }, [data, fieldKeys?.bar]);

  const isPositiveAxis = chartData?.maxNegativeValue === 0 && isPDF;

  const minDomainRange = (dataMin) => {
    return isPositiveAxis ? 0 : dataMin >= defaultNegativeValue ? defaultNegativeValue : -110;
  };

  const renderCustomBarLabel = ({ x, y, value }: HashMap) => {
    return (
      <>
        {value > 0 ? (
          <g className=''>
            <text
              x={x + 15}
              y={y - 10}
              className={clsx(
                isPDF ? 'fill-black text-black text-[14px] leading-[16px]' : 'fill-neutral-100 text-neutral-100 text-[12px] leading-[16px]'
              )}
              textAnchor='middle'
            >
              {`${formatPercentage(value)}`}
            </text>
          </g>
        ) : (
          <>
            {value < 0 ? (
              <g className=''>
                <text
                  x={x + 10}
                  y={y + 15}
                  className={clsx(
                    isPDF
                      ? 'fill-black text-black text-[14px] leading-[16px] leading-[16px]'
                      : 'fill-neutral-100 text-neutral-100 text-[12px] leading-[16px]'
                  )}
                  textAnchor='middle'
                >
                  {`${formatPercentage(value)}`}
                </text>
              </g>
            ) : (
              <g className=''>
                <text
                  x={x + 10}
                  y={y - 10}
                  className={clsx(
                    isPDF
                      ? 'fill-black text-black text-[14px] leading-[16px] leading-[16px]'
                      : 'fill-neutral-100 text-neutral-100 text-[12px] leading-[16px]'
                  )}
                  textAnchor='middle'
                >
                  {`${formatPercentage(value)}`}
                </text>
              </g>
            )}
          </>
        )}
      </>
    );
  };

  const addtionalStyling = isPDF
    ? {
        fill: '#00000',
        color: '#00000',
        fontWeight: '500',
        fontSize: 14
      }
    : {};

  const formatYAxis = (value: number) => {
    if (value > 0) return 'Positive';
    if (value === 0) return 'Neutral';
    if (!isPositiveAxis && value < 0) return 'Negative';
    return value;
  };

  return (
    <ResponsiveContainer width='100%' height='100%' className={`block overflow-y-auto recharts-responsive-container-large`}>
      <BarChart
        width={500}
        height={200}
        data={chartData?.data ?? []}
        margin={{
          top: 20,
          right: 10,
          left: 15,
          bottom: 10
        }}
      >
        <defs>
          <linearGradient id='fadeGrad' x1='0%' y1='0%' x2='0%' y2='100%'>
            <stop offset='0%' stopColor='#228b22' />
            <stop offset='26.1%' stopColor='#4cbb17' />
            <stop offset='34.77%' stopColor='#CFCA7D' />
            <stop offset={chartData?.maxNegativeValue >= defaultNegativeValue ? '73%' : '61.46%'} stopColor='#E4D96D' />
            <stop offset={chartData?.maxNegativeValue >= defaultNegativeValue ? '88%' : '73.95%'} stopColor='#FA9885' />
            <stop offset='100%' stopColor='#FF8A8A' />
          </linearGradient>
        </defs>
        <line x1='72' y1='15' x2='72.001' y2='290' style={{ stroke: 'url(#fadeGrad)', strokeWidth: '9px', shapeRendering: 'crispEdges' }} />
        <XAxis
          tickFormatter={formatXAxis}
          dataKey={fieldKeys?.xAxis ?? 'name'}
          axisLine={false}
          tickLine={false}
          interval={0}
          fontSize={12}
          // @ts-ignore
          tick={{ angle: -12 }}
          tickMargin={10}
          {...addtionalStyling}
        />
        <YAxis
          // @ts-ignore
          tickFormatter={formatYAxis}
          domain={[minDomainRange, 100]}
          tickSize={10}
          fontSize={12}
          axisLine={false}
          tickLine={false}
          tickCount={isPositiveAxis ? 2 : 3}
          {...addtionalStyling}
        />
        <Tooltip content={CustomTooltip} cursor={false} animationDuration={200} animationEasing='ease-out' />
        <Bar dataKey={fieldKeys?.bar ?? 'pv'} barSize={25} label={renderCustomBarLabel} maxBarSize={maxBarSize}>
          {chartData?.data?.map((entry, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                className='omni-bar-cell'
                fill={entry.currentColor}
                stroke={entry.currentColor}
                strokeWidth={2}
                // @ts-ignore
                radius={barChartRadius}
              />
            );
          }) ?? []}
        </Bar>
        <ReferenceLine y={0} stroke='#A2A9AD' strokeWidth={2} />
      </BarChart>
    </ResponsiveContainer>
  );
}
