import React from 'react';
import { barChartRadius, channelDarkColorObj, defaultLineColor } from '@/utils/constants';
import { Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import {
  formatPercentage,
  formatNumber,
  formatToMillion,
  formatCurrency,
  maxDomainRangeSetterForBarChart,
  defaultChartValue,
  formatToMillionBase,
  getDataInTermsOfMultiples
} from '@/utils/helpers';
import { BaseChartType, HashMap } from '@/types';
import clsx from 'clsx';
import { round } from 'lodash';

type ComposeBarLineChartType = BaseChartType & {
  hasSingleBar?: string;
  maxBarSize?: number;
  noLine?: boolean;
  noLegend?: boolean;
  noTooltip?: boolean;
  noYAxis?: boolean;
  noCartesian?: boolean;
  firstLabel?: string;
  secondLabel?: string;
  rotateValue?: boolean;
  isPercentage?: boolean;
  isChannelView?: boolean;
  isLinePercentage?: boolean;
  rotateAngle?: number;
  hasCalculatePercentage?: string;
  fieldKeys?: {
    xAxis: string;
    bar: string;
    line: string;
  };
  xAxisTickMargin?: number;
  isRightYAxis?: boolean;
  isNoDataLabel?: boolean;
  fixedMultiple?: number;
  isMaxRange?: boolean;
  marginValue?: any;
  isPDF?: boolean;
};

export function ComposeBarLineChart({
  id,
  data,
  hasSingleBar,
  maxBarSize,
  noLine,
  noLegend,
  noTooltip = false,
  noYAxis = false,
  noCartesian = false,
  firstLabel,
  secondLabel,
  rotateValue = false,
  cartesianProps = {},
  isPercentage,
  isLinePercentage,
  barSize,
  hasCalculatePercentage,
  rotateAngle,
  isAnimationActive = true,
  maxDomainRef,
  isRightYAxis = false,
  fieldKeys,
  xAxisTickMargin,
  isNoDataLabel = true,
  fixedMultiple = 10,
  isMaxRange = true,
  marginValue,
  isPDF = false
}: ComposeBarLineChartType) {
  const xaxisField = fieldKeys?.xAxis ?? 'name';

  const chartData = React.useMemo(() => {
    let maximumValue = 0;

    const mapData = data.map((element) => {
      const currentColor = hasSingleBar ? hasSingleBar : channelDarkColorObj[element?.[xaxisField]];
      if (maxDomainRef) {
        maximumValue = Math.max(maximumValue, element?.[maxDomainRef]);
      }
      return {
        ...element,
        currentColor
      };
    });

    return {
      data: mapData,
      maximumValue
    };
  }, [data, hasSingleBar, maxDomainRef, xaxisField]);

  const renderLegend = (props: HashMap) => {
    const { payload } = props;
    return (
      <ul className={clsx('flex flex-row justify-center items-center', isPDF ? 'mt-6' : 'mt-4')}>
        {payload.map((entry: HashMap, index: number) => (
          <li key={`item-${index}`} className={`flex flex-row items-center ${index === 0 && 'mr-6'}`}>
            {entry.payload.children ? (
              <svg width='18' height='18' className='mr-2'>
                <rect
                  width='18'
                  height='18'
                  fill={entry.payload.children[0].props.fill}
                  fillOpacity={1}
                  stroke={entry.payload.children[0].props.fill}
                  strokeWidth={1}
                />
              </svg>
            ) : (
              <div className={`h-[18px] w-[18px] border-0 bg-[#A6CEE3] rounded-full mr-2`}></div>
            )}
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const renderCustomBarLabel = ({ x, y, width, value }: HashMap) => {
    if (value === 0 && isNoDataLabel) {
      value = defaultChartValue();
    } else if (isPercentage) {
      value = formatPercentage(value);
    } else {
      value = formatCurrency(value, 0);
    }

    return (
      <g className={clsx(!isPDF && 'omni-compose-bar-line-label', !isPDF && rotateValue && '!rotate-[-90deg]')}>
        <text
          x={x + width / 2}
          y={rotateValue ? y - 10 : y - 38}
          className={isPDF ? 'fill-black text-black text-sm' : 'fill-neutral-100 text-neutral-100 text-xs'}
          textAnchor='middle'
        >
          {value}
        </text>
      </g>
    );
  };

  const customisedYAxisLabel = (label: number) => {
    return `${label > 999999 ? formatToMillion(label) : formatCurrency(label, 0)}`;
  };

  const customisedRightYAxisLabel = (label: number) => {
    if (isLinePercentage) {
      return formatPercentage(label);
    }
    return formatToMillionBase(label);
  };

  const renderCustomToolTip = (props: HashMap) => {
    if (props.active && props.payload && props.payload.length) {
      let label;

      if (firstLabel) {
        label = props.payload[0].name;
      } else {
        label = props.payload[0]?.payload?.name;
      }

      if (typeof label === 'undefined') {
        label = props.payload[0]?.payload?.channel || '';
      }

      return (
        <>
          <div
            className='p-4 rounded-[8px]'
            style={{
              background: 'radial-gradient(86.82% 192.89% at 73.58% 28.49%, #373F45 0%, #566169 100%)'
            }}
          >
            <p className='flex flex-row items-center text-white'>
              <svg width='14' height='14' className='mr-2 rounded-sm'>
                <rect
                  width='14'
                  height='14'
                  fill={props.payload[0].payload.currentColor}
                  fillOpacity={1}
                  stroke={props.payload[0].payload.currentColor}
                  strokeWidth={1}
                />
              </svg>
              {`
                ${label} ${isPercentage ? formatPercentage(props.payload[0].value) : formatCurrency(props.payload[0].value, 0)}
              `}
            </p>
            {props.payload[1] && (
              <p className=' flex flex-row items-center text-white'>
                <svg width='14' height='14' className='mr-2 rounded-sm'>
                  <rect width='14' height='14' fill={'#A6CEE3'} fillOpacity={1} stroke={'#A6CEE3'} strokeWidth={1} />
                </svg>
                {`${props.payload[1].name} ${
                  hasCalculatePercentage
                    ? formatPercentage(props.payload[1].payload[hasCalculatePercentage])
                    : isPercentage || isLinePercentage
                    ? formatPercentage(props.payload[1].value)
                    : formatNumber(props.payload[1].value, 0)
                }`}
              </p>
            )}
          </div>
        </>
      );
    }
    return null;
  };
  const maxDomainRange = (dataMax: number) => {
    let maxRange = maxDomainRangeSetterForBarChart(dataMax, chartData.maximumValue);
    maxRange = round(maxRange + (maxRange * 2) / 100);
    maxRange = getDataInTermsOfMultiples(maxRange, dataMax, fixedMultiple, isMaxRange);
    return maxRange;
  };

  const maxRangeConfig = maxDomainRef ? maxDomainRange : 'dataMax';

  const cartesianStyle = isPDF
    ? {
        strokeWidth: 0.5,
        strokeOpacity: 0.5
      }
    : {
        strokeWidth: 1,
        strokeOpacity: 1
      };

  const addtionalStyling = isPDF
    ? {
        fill: '#00000',
        fontWeight: '500',
        fontSize: 14
      }
    : {};

  return (
    <ResponsiveContainer className={`recharts-responsive-container-large`} width='100%' height='100%'>
      <ComposedChart
        id={id}
        data={chartData.data}
        barCategoryGap={38}
        margin={
          marginValue ?? {
            top: 5,
            right: 0,
            left: 5,
            bottom: 15
          }
        }
      >
        <XAxis
          dataKey={fieldKeys?.xAxis ?? 'name'}
          axisLine={false}
          tickLine={false}
          // @ts-ignore
          tick={{ angle: rotateAngle }}
          interval={0}
          fontSize={12}
          tickMargin={xAxisTickMargin ?? 10}
          {...addtionalStyling}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          hide={noYAxis}
          tickFormatter={customisedYAxisLabel}
          fontSize={10}
          domain={[0, maxRangeConfig]}
          {...addtionalStyling}
        />
        {isRightYAxis && (
          <YAxis
            dataKey={fieldKeys?.line ?? 'percentage'}
            axisLine={false}
            tickLine={false}
            tickMargin={10}
            hide={noYAxis}
            tickFormatter={customisedRightYAxisLabel}
            fontSize={10}
            yAxisId='right'
            orientation='right'
            {...addtionalStyling}
          />
        )}
        {!noCartesian && <CartesianGrid stroke='#F0F4F9' {...cartesianStyle} {...cartesianProps} />}
        {!noLegend && <Legend content={renderLegend} />}
        {!noTooltip && (
          <Tooltip
            content={renderCustomToolTip}
            cursor={false}
            isAnimationActive={isAnimationActive}
            animationDuration={200}
            animationEasing='ease-out'
          />
        )}
        <Bar
          dataKey={fieldKeys?.bar ?? 'pv'}
          barSize={barSize ?? 25}
          name={firstLabel}
          label={(props) => renderCustomBarLabel(props)}
          isAnimationActive={isAnimationActive}
          maxBarSize={maxBarSize}
        >
          {chartData.data.map((entry, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                className='omni-bar-cell'
                fill={entry.currentColor}
                stroke={entry.currentColor}
                strokeWidth={2}
                // @ts-ignore
                radius={barChartRadius}
              />
            );
          })}
        </Bar>
        {!noLine && isRightYAxis ? (
          <Line
            type='monotone'
            yAxisId='right'
            dataKey={fieldKeys?.line ?? 'percentage'}
            isAnimationActive={isAnimationActive}
            name={secondLabel}
            stroke={defaultLineColor}
            strokeWidth={2}
            dot={{ fill: defaultLineColor, strokeWidth: 1, r: 4, fillOpacity: 1 }}
          />
        ) : (
          <Line
            type='monotone'
            yAxisId='right'
            dataKey={fieldKeys?.line ?? 'percentage'}
            isAnimationActive={isAnimationActive}
            name={secondLabel}
            stroke={defaultLineColor}
            strokeWidth={2}
            dot={{ fill: defaultLineColor, strokeWidth: 1, r: 4, fillOpacity: 1 }}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
