// @ts-nocheck
import React from 'react';
import { Page, View, Document } from '@react-pdf/renderer';
import Footer from './Footer';
import { pdfSettings } from '@/utils/pdf-constants';
import Review from './components/Review';
import Analysis from './components/Analysis';
import Optimise from './components/Optimise';
import Welcome from './components/Welcome';

export const MyDocument = (props) => {
  return (
    <Document>
      <Page orientation={pdfSettings.orientation} size={pdfSettings.size}>
        <Welcome currentClientData={props?.currentClientData} />
      </Page>
      <Page orientation={pdfSettings.orientation} size={pdfSettings.size}>
        <View>
          <Review {...props} />
        </View>
        <Footer />
      </Page>
      <Page orientation={pdfSettings.orientation} size={pdfSettings.size}>
        <View>
          <Analysis {...props} />
        </View>
        <Footer />
      </Page>
      <Page orientation={pdfSettings.orientation} size={pdfSettings.size} wrap={false}>
        <View wrap={false}>
          <Optimise {...props} />
          <Footer />
        </View>
      </Page>
    </Document>
  );
};
