import { HashMap } from '@/types';
import { formatISO, sub } from 'date-fns';

const millionBase = 1000000;
const thousandBase = 1000;

export const hexToRgb = (hex: string) => {
  const result = /^#?([A-F\d]{2})([A-F\d]{2})([A-F\d]{2})$/i.exec(hex);

  if (!result) {
    throw new Error('Wrong value provided to the Hex function');
  }

  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    : null;
};

export const formatNumber = (number: number, decimals = 2) => {
  return number.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const channelAcronyms = ['seo', 'cpi', 'tv', 'sem', 'cci', 'ooh'];
const channelCamelCaseName = {
  youtube: 'YouTube'
};

export const formatTitleCase = (string) => {
  string = string.toLowerCase().replace('_', ' ');

  const words = string.split(' ');

  if (channelAcronyms.includes(string)) {
    return string.toUpperCase();
  }

  if (channelCamelCaseName[string]) {
    return channelCamelCaseName[string];
  }

  return words
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

export const formatLabelAcronymsWithLaterChars = (string) => {
  let data = formatTitleCase(string);
  let word = data.split(' ');

  const key = word[0].toLowerCase();

  if (channelAcronyms.includes(key)) return key.toUpperCase() + ' ' + word[1];

  if (channelCamelCaseName[key]) return channelCamelCaseName[key] + ' ' + word[1];

  return data;
};

export const formatAUNumber = (num: number) => {
  const formatter = new Intl.NumberFormat('en-AU');
  return formatter.format(num);
};

export const formatCurrency = (number: number, decimals = 2) => {
  return '$' + formatAUNumber(number);

};

export const defaultChartValue = () => {
  return 'No data';
};

export const formatPercentage = (number: number) => {
  return formatNumber(number, 2) + '%';
};

export const formatToMillion = (number: number) => {
  return formatCurrency(number / millionBase) + 'm';
};

export const formatToMillionBase = (num: number) => {
  if (num > 999999) {
    return formatAUNumber(num / millionBase) + 'm';
  } else if (num > 999) {
    return formatAUNumber(num / thousandBase) + 'k';
  }

  return formatAUNumber(num);
};

export const formatDecimalPercentage = (number: number, decimal: number) => {
  return formatNumber(number, decimal) + '%';
};

export const getDatesBetween = (startDate: Date, endDate: Date) => {
  const dates: string[] = [];
  const currentDate = startDate;
  while (currentDate < endDate) {
    const formatedDate = formatISO(new Date(currentDate), { representation: 'date' });
    dates.push(formatedDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const substractYearDateFromCurrent = (currentDate = new Date(), years: number) => {
  return sub(currentDate, {
    years
  });
};

export const maxDomainRangeSetterForBarChart = (dataMax: number, value: number) => {
  return dataMax + value * 0.2;
};

export const getDataInTermsOfMultiples = (value: number, secondaryValue: number, range: number, isMaxRange = true) => {
  for (let index = 0; index < range + 5; index++) {
    if (isMaxRange) {
      if ((value + index) % range === 0) {
        return value + index;
      }
    } else {
      /* Special case for the Share voice */
      if ((secondaryValue + index) % range === 0) {
        return secondaryValue + index + range;
      }
    }
  }
};

export const isLandscapeOrientation = () => (!navigator.maxTouchPoints ? false : !window.screen.orientation.angle ? true : false);

export const sleepForSpecificTime = (time = 9000) => {
  return new Promise((res) => {
    setTimeout(() => {
      res(true);
    }, time);
  });
};

export const spendToolTipLabel = (data: HashMap) => {
  return `${data?.name} ${formatCurrency(data?.payload?.rawValue)}, Share ${formatPercentage(data.value)}`;
};
export const shareToolTipLabel = (data: HashMap) => {
  return `${data?.name} ${formatCurrency(data?.payload?.spend)}, Share ${formatPercentage(data.value)}`;
};

export const getFirstName = (name: string) => {
  const words = name.split(' ');
  return words[0];
};

export const getOktaAuthCheck = () => {
  return process.env.REACT_APP_OKTA_CHECK === 'true';
};
