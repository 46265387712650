import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import InlineTextLogo from '@/assets/images/omni-inline-text-logo.svg';
import { Pill } from '../Common/Pill';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as CircleInfo } from '@/assets/images/circle-info.svg';
import { useAppSelector } from '@/store/hooks';
import { isEmpty } from 'lodash';
import { Modal } from '../Common/Modal';
import { CardHeaderIcon } from '../Common/Card';
import { Title } from '../Common/Typography';
import { GButton } from '../Common/Button';

export type SideBarBreadcrumbType = { className?: string };

type pillStateType = {
  first: 'normal' | 'nav' | 'complete' | 'active';
  second: 'normal' | 'nav' | 'complete' | 'active';
  third: 'normal' | 'nav' | 'complete' | 'active';
  fourth: 'normal' | 'nav' | 'complete' | 'active';
};

export function SideBarBreadcrumb({ className }: SideBarBreadcrumbType) {
  const location = useLocation();
  const navigate = useNavigate();
  const measurableSpend = useAppSelector((state) => state.models.measurableSpend);
  const modelledResults = useAppSelector((state) => state.models.modelledResults);
  const { yearly_values } = useAppSelector((state) => state.models.yearlyMMMBudget);
  const [modalOpen, setModalOpen] = useState(false);

  const [pillState, setPillState] = useState<pillStateType>({
    first: 'nav',
    second: 'nav',
    third: 'nav',
    fourth: 'nav'
  });

  useEffect(() => {
    let currentStep = location.pathname.split('-')[0];
    if (currentStep === '/upload') {
      setPillState({
        first: 'active',
        second: 'nav',
        third: 'nav',
        fourth: 'nav'
      });
    } else if (currentStep === '/review') {
      setPillState({
        first: 'complete',
        second: 'active',
        third: 'nav',
        fourth: 'nav'
      });
    } else if (currentStep === '/analysis') {
      setPillState({
        first: 'complete',
        second: 'complete',
        third: 'active',
        fourth: 'nav'
      });
    } else if (currentStep === '/optimise') {
      setPillState({
        first: 'complete',
        second: 'complete',
        third: 'complete',
        fourth: 'active'
      });
    }
  }, [location.pathname]);

  const isDisabled = useMemo(() => {
    let counter = 0;
    if (!isEmpty(measurableSpend?.aggregated_spend)) {
      counter = 1;
    }
    if (!isEmpty(modelledResults)) {
      counter = 2;
    }
    if (!isEmpty(yearly_values)) {
      counter = 3;
    }

    return counter;
  }, [modelledResults, measurableSpend, yearly_values]);

  const handleUploadRedirect = () => {
    setModalOpen(true);
    navigate('/upload');
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleUploadRedirectTrigger = () => {
    if (isDisabled > 0) {
      setModalOpen(true);
    } else {
      navigate('/upload');
    }
  };

  return (
    <>
      <div className={clsx('omni-app-sidebar hidden lg:block', className)}>
        <div className='flex flex-col w-full h-full relative -left-[6px] items-center'>
          <div className='flex mb-auto w-[146px] h-[35px] relative top-[55px]'>
            <img src={InlineTextLogo} className='rotate-[270deg]' alt='Sidebar inline logo' />
          </div>
          <nav className='flex mt-auto'>
            <Pill
              invert
              text='Upload'
              state={pillState.first}
              icon={<i className='fa-light fa-arrow-up-to-line rotate-[180deg]' />}
              className='cursor-pointer'
              handleClick={handleUploadRedirectTrigger}
            />
            <Pill
              invert
              text='Review'
              state={pillState.second}
              icon={<i className='fa-light fa-magnifying-glass rotate-[180deg]' />}
              className={clsx(isDisabled > 0 ? 'cursor-pointer' : '')}
              handleClick={() => isDisabled > 0 && navigate('/review-validate')}
            />
            <Pill
              invert
              text='Analysis'
              state={pillState.third}
              icon={<i className='fa-solid fa-chart-mixed rotate-[180deg]' />}
              className={clsx(isDisabled > 1 ? 'cursor-pointer' : '')}
              handleClick={() => isDisabled > 1 && navigate('/analysis-sales')}
            />
            <Pill
              invert
              text='Optimise'
              state={pillState.fourth}
              icon={<i className='fa-solid fa-dial-high rotate-[180deg]' />}
              className={clsx(isDisabled > 2 ? 'cursor-pointer' : '')}
              handleClick={() => isDisabled > 2 && navigate('/optimise-result')}
            />
          </nav>
        </div>
      </div>
      <Modal open={modalOpen} onClose={handleModalClose} size='lg'>
        <CardHeaderIcon icon={CircleInfo} isBlob />
        <Title as='h4' className='text-left card-title-text'>
          Warning
        </Title>
        <div className='text-base text-left tracking-[0.03em] text-neutral-100 pt-3'>
          <p>You are about to leave this current project and start a new one.</p>
          <br />
          <p>Do you wish to proceed?</p>
        </div>
        <div className='flex flex-col gap-2 mt-[20px]'>
          <GButton type='button' bgGradient='gray' className='text-lg justify-end' onClick={handleModalClose}>
            No, stay here
          </GButton>
          <GButton type='button' className='text-base font-semibold justify-end' onClick={handleUploadRedirect}>
            Go to upload
          </GButton>
        </div>
      </Modal>
    </>
  );
}
