import React from 'react';
import clsx from 'clsx';
import { PieChart, Pie, Tooltip, Sector, Cell, Legend, ResponsiveContainer } from 'recharts';
import { formatCurrency, formatPercentage, formatToMillion } from '@/utils/helpers';
import { channelDarkColorObj, darkColors } from '@/utils/constants';
import { BaseChartType, HashMap } from '@/types';

type PieChartCustomType = BaseChartType & {
  averageTitle: string;
  fieldKey?: { value: string };
  isPercentage?: boolean;
  isChannelName?: boolean;
  isPDF?: boolean;
};

const spaceTransformTranslateX = '-translate-x-4';

export function PieChartCustom({
  data,
  averageTitle,
  fieldKey,
  isPercentage,
  isAnimationActive = true,
  id,
  toolTipLabelGenerator,
  isChannelName = false,
  isPDF = false
}: PieChartCustomType) {
  const fieldValueKey = fieldKey?.value ?? 'value';

  const renderActiveShape = (props: HashMap) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

    return (
      <g className={spaceTransformTranslateX}>
        <text x={cx} y={cy} dy={8} textAnchor='middle' className='text-neutral-100 font-bold'>
          {averageTitle}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          cornerRadius={40}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const renderLegend = (props: HashMap) => {
    const { payload } = props;

    const relativePos = isPDF ? 'left-0' : 'left-3';
    const textSize = isPDF ? 'text-base' : 'text-xs';
    const textColor = isPDF ? 'text-black' : 'text-neutral-100';

    return (
      <ul className={clsx('relative', relativePos)}>
        {payload.map((entry: HashMap, index: number) => (
          <li key={`item-${index}`} className={clsx('flex gap-1 items-center mt-[2px]', textSize, textColor)}>
            <i
              className={clsx('fa-solid fa-square')}
              /* NOTE: as tailwind doesn't support dynamic Arbitrary value, CSS style would be used */
              style={{
                color: isChannelName ? channelDarkColorObj[entry?.value] : darkColors[index % darkColors.length]
              }}
            ></i>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const customTooltip = (props: HashMap) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <>
          <div
            className='p-4 rounded-lg'
            style={{
              background: 'var(--gradient-radial-gray)'
            }}
          >
            <p className='flex flex-row items-center text-white'>
              <svg width='14' height='14' className='mr-2 rounded-sm'>
                <rect
                  width='14'
                  height='14'
                  fill={props.payload[0].payload.currentColor}
                  fillOpacity={1}
                  stroke={props.payload[0].payload.currentColor}
                  strokeWidth={1}
                />
              </svg>
              {toolTipLabelGenerator
                ? toolTipLabelGenerator(props.payload[0])
                : `${props.payload[0].name}  ${
                    isPercentage
                      ? `${formatPercentage(props.payload[0].value)}, ${
                          props.payload[0]?.payload?.total_spend
                            ? formatToMillion(props.payload[0]?.payload?.total_spend)
                            : formatToMillion(props.payload[0]?.payload?.rawValue)
                        }`
                      : formatCurrency(props.payload[0].value, 0)
                  }`}
            </p>
          </div>
        </>
      );
    }
    return null;
  };

  const chartData = React.useMemo(() => {
    if (data) {
      return data.map((element, index) => {
        const currentColor = isChannelName ? channelDarkColorObj[element?.name] : darkColors[index % darkColors.length];
        return {
          ...element,
          currentColor
        };
      });
    } else {
      return [];
    }
  }, [data, isChannelName]);

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <PieChart className='flex flex-row flex-wrap' id={id}>
        {/* @ts-ignore */}
        <Legend content={renderLegend} layout='vetical' verticalAlign='middle' align='right' />
        <Pie
          dataKey={fieldValueKey}
          className={spaceTransformTranslateX}
          activeIndex={0}
          data={chartData}
          cx='50%'
          cy='50%'
          cornerRadius={40}
          innerRadius={75}
          outerRadius={90}
          paddingAngle={2}
          activeShape={renderActiveShape}
          isAnimationActive={isAnimationActive}
          strokeWidth={0}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.currentColor} radius={6} />
          ))}
        </Pie>
        <Tooltip
          content={customTooltip}
          cursor={false}
          animationDuration={200}
          animationEasing='ease-out'
          isAnimationActive={isAnimationActive}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
