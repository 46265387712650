import React from 'react';
import { barChartRadius, darkColors } from '@/utils/constants';
import { Bar, Cell, BarChart, Legend, ResponsiveContainer, XAxis, Tooltip, YAxis } from 'recharts';
import { formatCurrency, formatPercentage } from '@/utils/helpers';
import clsx from 'clsx';
import { BaseChartType, HashMap } from '@/types';

type BarMixChartType = BaseChartType & {
  hasSingleColor?: boolean;
  labelSet?: boolean;
  colorTypes?: { solid: string; border: string; stripe: string };
  margin?: Record<string, any>;
  isPercentage?: boolean;
  minPointSize?: number;
  fieldKeys?: {
    xAxis: string;
    solidBar: string;
    stripeBar: string;
    lightBar: string;
  };
  isPDF?: boolean;
};

const renderCustomTooltip = (props: any, isPercentage?: boolean) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <>
        <div
          className='p-4 rounded-[8px]'
          style={{
            background: 'var(--gradient-radial-gray)'
          }}
        >
          <p className='flex flex-row items-center text-white'>
            <svg width='14' height='14' className='mr-2 rounded-sm'>
              <rect
                width='14'
                height='14'
                fill={props.payload[0].payload.pvCurrentColor}
                fillOpacity={1}
                stroke={props.payload[0].payload.pvCurrentColor}
                strokeWidth={1}
              />
            </svg>
            {`${props.payload[0].name} `}{' '}
            {isPercentage ? formatPercentage(props.payload[0].value) : formatCurrency(props.payload[0].value, 0)}
          </p>
          {props.payload[1] && (
            <p className='flex flex-row items-center text-white'>
              <svg width='14' height='14' className='mr-2'>
                <rect
                  width='14'
                  height='14'
                  fill={props.payload[1].payload.uvCurrentColor}
                  fillOpacity={1}
                  stroke={props.payload[1].payload.uvCurrentColor}
                  strokeWidth={1}
                />
              </svg>
              {`${props.payload[1].name} `}{' '}
              {isPercentage ? formatPercentage(props.payload[1].value) : formatCurrency(props.payload[1].value, 0)}
            </p>
          )}
          {props.payload[2] && (
            <p className='flex flex-row items-center text-white'>
              <svg width='14' height='14' className='mr-2 rounded-sm'>
                <rect
                  width='14'
                  height='14'
                  fill={props.payload[2].payload.amtCurrentColor}
                  fillOpacity={0.2}
                  stroke={props.payload[2].payload.amtCurrentColor}
                  strokeWidth={1}
                />
              </svg>
              {`${props.payload[2].name} `}{' '}
              {isPercentage
                ? `${formatPercentage(props.payload[2].value)}`
                : `${props.payload[2].payload?.['is_negative_total_spend'] ? '- ' : ''}${formatCurrency(props.payload[2].value, 0)}`}
            </p>
          )}
        </div>
      </>
    );
  }
  return null;
};

export function BarMixChart({
  data,
  hasSingleColor,
  labelSet,
  margin,
  isPercentage,
  fieldKeys,
  minPointSize,
  id,
  isAnimationActive = true,
  formatter,
  isPDF = false
}: BarMixChartType) {
  const chartData: HashMap[] = React.useMemo(() => {
    return data.map((element, index) => {
      const pvCurrentColor = hasSingleColor ? darkColors[1] : darkColors[index % darkColors.length];
      let uvCurrentColor = hasSingleColor ? darkColors[3] : darkColors[index % darkColors.length];
      uvCurrentColor = `url(#omni-color-stripes-${uvCurrentColor.substring(1)})`;
      const amtCurrentColor = hasSingleColor ? darkColors[4] : darkColors[index % darkColors.length];

      return {
        ...element,
        pvCurrentColor,
        uvCurrentColor,
        amtCurrentColor
      };
    });
  }, [data, hasSingleColor]);

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <ul className='flex flex-row justify-center items-center mt-4'>
        {payload.map((entry: any, index: number) => {
          const fill = entry.payload.children[0].props.fill;

          const fillOpacity = index === 2 ? 0.2 : 1;

          return (
            <li key={`item-${index}`} className='flex flex-row items-center mr-4'>
              <svg width='18' height='18' className='mr-2'>
                <rect width='18' height='18' fill={fill} fillOpacity={fillOpacity} stroke={fill} strokeWidth={1} />
              </svg>
              {entry.value}
            </li>
          );
        })}
      </ul>
    );
  };

  const renderCustomBarSolidLabel = ({ x, y, width, height, value }: any, isPercentage?: boolean) => {
    let label = formatter ? formatter(value) : isPercentage ? formatPercentage(value) : formatCurrency(value, 0);

    return (
      <g className={clsx(height === 0 ? 'hidden' : '')}>
        <text
          x={x + width / 2}
          y={y + (height + 8) / 2}
          textAnchor='middle'
          className={clsx(
            isPDF ? 'fill-black text-black font-semibold text-base' : 'fill-neutral-100 text-neutral-100 font-semibold text-xs'
          )}
        >
          {label}
        </text>
      </g>
    );
  };
  const renderCustomBarStipeLabel = ({ x, y, width, height, values }: any, isPercentage?: boolean) => {
    let label = formatter ? formatter(values) : isPercentage ? formatPercentage(values) : formatCurrency(values, 0);

    return (
      <g className={clsx(height === 0 ? 'hidden' : '')}>
        <text
          x={x + width / 2}
          y={y + height / 2}
          textAnchor='middle'
          className={isPDF ? 'fill-black text-black font-semibold text-base' : 'fill-neutral-100 text-neutral-100 font-semibold text-xs'}
        >
          {label}
        </text>
      </g>
    );
  };

  const renderCustomBarLabel = ({ x, y, width, height, values, ...rest }: HashMap) => {
    let label = formatter ? formatter(values) : isPercentage ? formatPercentage(values) : formatCurrency(values, 0);

    const isNegativeTotalSpend = rest?.mode === 'true';

    const minHeight = 19;

    return (
      <g className={clsx(height === 0 ? 'hidden' : '')}>
        <foreignObject x={x} y={y + (height - 38) / 2} width={width} height={height < minHeight ? minHeight : height}>
          <div
            className={
              isPDF
                ? 'fill-black text-black font-semibold text-base text-center'
                : 'fill-neutral-100 text-neutral-100 font-semibold text-xs text-center'
            }
          >
            {isNegativeTotalSpend ? (
              <>
                <i className='fa-solid fa-triangle-exclamation'></i> -{' '}
              </>
            ) : null}
            {label}
          </div>
        </foreignObject>
      </g>
    );
  };

  return (
    <ResponsiveContainer className={`recharts-responsive-container-large`} width='100%' height='100%'>
      <BarChart data={chartData} barCategoryGap={8} id={id} margin={margin ?? { top: 30, left: 30, right: 10 }}>
        <XAxis dataKey={fieldKeys?.xAxis ?? 'name'} axisLine={false} tickLine={false} tickMargin={10} />
        <YAxis hide domain={[0, 'dataMax']} />
        <Legend content={renderLegend} />
        <Tooltip
          isAnimationActive={isAnimationActive}
          content={(props) => renderCustomTooltip(props, isPercentage)}
          cursor={false}
          animationDuration={200}
          animationEasing='ease-out'
        />
        {/* Pattern for the stripe background of Bar */}
        <defs>
          {darkColors.map((color) => {
            const name = color.substring(1);
            return (
              <pattern
                id={`omni-color-stripes-${name}`}
                width='10'
                height='10'
                patternTransform='rotate(45 0 0)'
                patternUnits='userSpaceOnUse'
                opacity={0.5}
                key={color}
              >
                <g opacity={0.7} fill={color}>
                  <rect x='0' y='0' width='10' height='10' />
                  <line x1='0' y1='0' x2='0' y2='10' style={{ stroke: '#FFF', strokeWidth: 3 }} />
                </g>
              </pattern>
            );
          })}
        </defs>

        {/* Solid Bar */}
        <Bar
          dataKey={fieldKeys?.solidBar ?? 'pv'}
          isAnimationActive={isAnimationActive}
          stackId={'barMixChart'}
          label={(props) => renderCustomBarSolidLabel(props, isPercentage)}
          name={labelSet ? 'Base' : 'Current Spend'}
          minPointSize={minPointSize}
        >
          {chartData.map((entry: any, index: number) => {
            return (
              <Cell
                key={`cell-${index}`}
                className='omni-bar-solid'
                fill={entry.pvCurrentColor}
                stroke={entry.pvCurrentColor}
                data-legend-color={entry.amtCurrentColor}
                strokeWidth={2}
                // @ts-ignore
                radius={barChartRadius}
              />
            );
          })}
        </Bar>

        {/* Stripe Bar */}
        <Bar
          dataKey={fieldKeys?.stripeBar ?? 'uv'}
          isAnimationActive={isAnimationActive}
          stackId={'barMixChart'}
          label={(props) => renderCustomBarStipeLabel(props, isPercentage)}
          style={{ transform: 'translate(0,-6px)' }}
          name={labelSet ? 'Brand' : 'Optimised Spend'}
          minPointSize={minPointSize}
        >
          {chartData.map((entry: any, index: number) => {
            return (
              <Cell
                key={`cell-${index}`}
                values={fieldKeys ? entry[fieldKeys.stripeBar] : entry.uv}
                className='omni-bar-mix-cell-stripe'
                fill={entry.uvCurrentColor}
                strokeWidth={2}
                // @ts-ignore
                radius={barChartRadius}
              />
            );
          })}
        </Bar>

        {/* Light Bar */}
        <Bar
          dataKey={fieldKeys?.lightBar ?? 'amt'}
          isAnimationActive={isAnimationActive}
          stackId={'barMixChart'}
          label={renderCustomBarLabel}
          style={{ transform: 'translate(0,-12px)' }}
          name={labelSet ? 'Media' : 'Total Spend Change'}
          minPointSize={minPointSize}
        >
          {chartData.map((entry: any, index: number) => {
            return (
              <Cell
                key={`cell-${index}`}
                className='omni-bar-cell'
                values={fieldKeys ? entry[fieldKeys.lightBar] : entry.amt}
                fill={entry.amtCurrentColor}
                mode={entry?.['is_negative_total_spend'] ? 'true' : 'false'}
                data-legend-color={entry.pvCurrentColor}
                fillOpacity={0.2}
                stroke={entry.amtCurrentColor}
                strokeWidth={2}
                // @ts-ignore
                radius={barChartRadius}
              />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
