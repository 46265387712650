import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GButton } from '@/components/Common/Button';
import { Card, CardHeaderIcon } from '@/components/Common/Card';
import { DefaultLayout } from '@/components/Layout/DesignLayout';
import { ReactComponent as InlineTextLogo } from '@/assets/images/omni-inline-text-logo-reveal.svg';
import { ReactComponent as CommentsQuestionCheck } from '@/assets/images/comments-question-check.svg';
import { ReactComponent as CircleInfo } from '@/assets/images/circle-info.svg';
import ArrowRightIcon from '@/assets/images/omni-arrow-gradient-right.png';
import { Title } from '@/components/Common/Typography';
import { useClearCurrentSession } from '@/components/Common/hook/session';
import { getOktaAuthCheck } from '@/utils/helpers';

export function SplashView({ authClient }) {
  const [termsConditionFlag, setTermsConditionFlag] = React.useState(false);
  const [helpFlag, setHelpFlag] = React.useState(false);
  const navigate = useNavigate();

  useClearCurrentSession();

  const onGetStartedClick = () => {
    setTermsConditionFlag(true);
  };

  const acceptTermsOnClick = () => {
    navigate('/upload');
  };

  const headerData = React.useMemo(() => {
    return {
      helpTooltipProps: {
        title: 'Welcome to omni romi™',
        description:
          'This is a tool designed to give you and your clients confidence by analysing the return on your media investments and predict your future optimal media mix. To start, select whether you want to view your previous models or create a new project.'
      }
    };
  }, []);

  const onRedirectToMyAccount = () => {
    navigate('/my-account');
  };

  const enableOktaAuthClick = getOktaAuthCheck();

  return (
    <DefaultLayout headerProps={headerData}>
      <div className='flex justify-center flex-col h-full relative px-10 lg:items-center'>
        {/* Reason of keeping the flag instead of route, is down the line we would be binding the API for this which would be flag from the response
         * so rather than just redirecting to another route and then check we would simply check at this level whether terms card needs to be displayed.
         */}
        {!termsConditionFlag && !helpFlag ? (
          <React.Fragment>
            <InlineTextLogo
              className='max-w-[252px] mb-6 lg:-translate-x-[12rem] lg:max-w-full'
              width={675}
              /* TODO: would be removed when moving to iframe */
              onClick={() => {
                if (!enableOktaAuthClick) {
                  authClient?.signInWithRedirect();
                }
              }}
            />

            <h2 className='font-normal text-white text-3xl lg:text-7xl lg:leading-tight fade-in mb-12'>
              Return on media <br /> investment analyser
            </h2>
            <div className='flex max-w-[675px] w-full gap-4 fade-in'>
              <GButton
                type='button'
                noShade
                className='bg-white text-primary-50 border-2 border-[#FFF] rounded-[100px] text-lg flex-1 font-bold lg:bg-transparent lg:text-white lg:font-normal'
                suffix={<i className='fa-regular fa-arrow-right-long'></i>}
                onClick={onRedirectToMyAccount}
              >
                View previous models
              </GButton>
              <GButton
                type='button'
                isGradient
                className='bg-white rounded-[100px] text-lg flex-1 font-bold hidden lg:flex'
                suffix={<img src={ArrowRightIcon} alt='Button right arrow icon' />}
                onClick={onGetStartedClick}
              >
                Create new project
              </GButton>
            </div>
          </React.Fragment>
        ) : (
          <>
            {termsConditionFlag && !helpFlag ? (
              <TermsUse setHelpFlag={setHelpFlag} />
            ) : (
              <HereToHelp acceptTermsOnClick={acceptTermsOnClick} />
            )}
          </>
        )}
      </div>
    </DefaultLayout>
  );
}

type TermsUseType = { setHelpFlag: Function };

const TermsUse = ({ setHelpFlag }: TermsUseType) => {
  return (
    <Card className='justify-around mx-auto'>
      <CardHeaderIcon icon={CircleInfo} isBlob />
      <Title as='h4' className='card-title-text'>
        Terms of use
      </Title>
      <p className='text-base tracking-[0.03em] text-neutral-100 min-h-[130px]'>
        By continuing, you accept responsibility for the validity of all KPI data used in the analyser.
      </p>
      <GButton
        type='button'
        bgGradient='gray'
        className='text-lg justify-end'
        suffix={<i className='fa-light fa-chevron-right' />}
        onClick={() => setHelpFlag(true)}
      >
        Agree and continue
      </GButton>
    </Card>
  );
};

type HereToHelpType = { acceptTermsOnClick?: React.MouseEventHandler<HTMLButtonElement> };

const HereToHelp = ({ acceptTermsOnClick }: HereToHelpType) => {
  return (
    <Card className='justify-around mx-auto'>
      <CardHeaderIcon icon={CommentsQuestionCheck} isBlob />
      <Title as='h4' className='card-title-text'>
        We’re here to help
      </Title>
      <p className='text-base tracking-[0.03em] text-neutral-100 min-h-[130px]'>
        If at any point you need help while using omni romi, please click on the ‘help’ button in the top right of the screen. You can also
        contact us from there if you need.
      </p>
      <GButton
        type='button'
        bgGradient='gray'
        className='text-lg justify-end mt-10'
        suffix={<i className='fa-light fa-chevron-right' />}
        onClick={acceptTermsOnClick}
      >
        Continue
      </GButton>
    </Card>
  );
};
