// @ts-nocheck
import React from 'react';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { AnalysisImg } from './svg/Analysis-img';

const styles = StyleSheet.create({
  mainStyle: {
    width: '100%',
    margin: '40px 0 0 40px'
  },
  chartWrapper: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexGrow: 1
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20
  },
  colWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    margin: '5px 10px 0 10px'
  },
  cardWrapper: {
    width: '45%',
    display: 'flex',
    border: '1px solid #D6D7D8',
    borderRadius: '10px',
    margin: '0 10px 0 10px'
  },
  posNegChart: {
    display: 'flex',
    border: '1px solid #D6D7D8',
    borderRadius: '10px',
    padding: '0 20px 5px 20px'
  },
  salesChart: {
    display: 'flex',
    border: '1px solid #D6D7D8',
    borderRadius: '10px',
    marginTop: '10px',
    padding: '0 20px 0 20px'
  },
  salesChartWrapper: {
    width: '100%',
    display: 'flex',
    border: '1px solid #D6D7D8',
    borderRadius: '10px',
    marginTop: '5px',
    margin: '0 10px 0 10px'
  },
  salesCardWrapper: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '5px'
  },
  cardHeader: {
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#373F45',
    fontSize: '12px',
    fontWeight: 700,
    marginTop: '10px'
  },
  barChart: {
    marginTop: '10px'
  },
  positiveNegativechart: {
    width: '100%'
  },
  barMixChartImg: {
    marginTop: '15px'
  },
  scatterChart: {
    marginTop: '10px',
    marginLeft: '25px'
  },
  analyseWrapper: {
    display: 'flex',
    width: '95%',
    flexDirection: 'row',
    margin: '0 10px 0 0px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  analyseHeading: {
    fontSize: '28px',
    fontWeight: 700,
    marginLeft: '10px'
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 10px 0 10px',
    marginBottom: '10px'
  },
  headerDate: {
    fontSize: '14px',
    fontWeight: 500,
    marginRight: '20px'
  },
  roiWrapper: {
    display: 'flex',
    width: '50%',
    border: '1px solid #D6D7D8',
    borderRadius: '10px',
    margin: '0 10px 0 10px'
  }
});

const Analysis = (props) => {
  const clientDetail = `${props?.currentClientData?.name}, ${props?.currentClientData?.startDate} - ${props?.currentClientData?.endDate}`;
  return (
    <View style={styles.mainStyle}>
      <View style={styles.analyseWrapper}>
        <View style={styles.headerTitle}>
          <View>
            <AnalysisImg />
          </View>
          <Text style={styles.analyseHeading}>Analyse</Text>
        </View>
        <Text style={styles.headerDate}>{clientDetail}</Text>
      </View>
      <View style={styles.chartWrapper}>
        <View style={styles.rowWrapper}>
          <View style={styles.colWrapper}>
            <View style={styles.posNegChart}>
              <Text style={styles.cardHeader}>External Drivers of Sales</Text>
              <Image src={props?.mediaContributionChartResponse} style={styles.positiveNegativechart} />
            </View>
            <View style={styles.salesChart}>
              <Text style={styles.cardHeader}>Media Contribution To Sales</Text>
              <Image src={props?.externalDriveChartResponse} style={styles.positiveNegativechart} />
            </View>
          </View>
          <View style={styles.salesCardWrapper}>
            <View style={styles.salesChartWrapper}>
              <Text style={styles.cardHeader}>Major Contributors To Sales</Text>
              <Image src={props?.majorContributionChartResponse} style={styles.barMixChartImg} />
            </View>
          </View>
        </View>
        <View style={styles.rowWrapper}>
          <View style={styles.roiWrapper}>
            <Text style={styles.cardHeader}>Contribution</Text>
            <Image src={props?.contributionhartResponse} style={styles.scatterChart} />
          </View>
          <View style={styles.roiWrapper}>
            <Text style={styles.cardHeader}>Return On Investment</Text>
            <Image src={props?.roiChartResponse} style={styles.barChart} />
          </View>
        </View>
      </View>
    </View>
  );
};

export default Analysis;
