import React from 'react';
import Slider from 'rc-slider';
import { optimsieBudgetSliderValues } from '@/utils/constants';

type SliderCustomType = { disabled?: boolean; handleChange?: (value: number | number[]) => void; value: number };

export function SliderCustom({ disabled = false, handleChange, value }: SliderCustomType) {
  return (
    <Slider
      min={20}
      defaultValue={60}
      marks={optimsieBudgetSliderValues}
      step={null}
      className='mb-7 mt-5'
      disabled={disabled}
      onChange={handleChange}
      value={value}
    />
  );
}
