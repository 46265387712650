import React from 'react';
import clsx from 'clsx';
import { AppButtonWrapper, AppLayout, OverflowContent } from '@/components/Layout/DesignLayout';
import { Card } from '@/components/Common/Card';
import { GButton, SButton } from '@/components/Common/Button';
import { BarChartCustom } from '@/components/Chart/BarChart';
import { PieChartCustom } from '@/components/Chart/PieChart';
import { Title } from '@/components/Common/Typography';
import { useNavigate } from 'react-router-dom';
import { flickHelpTooltip, mediaPlanVerify } from '@/store/modelsSlice';
import { cardHeight, graphLayoutContainer } from '@/utils/constants';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { formatCurrency, shareToolTipLabel, spendToolTipLabel } from '@/utils/helpers';

export function ReviewView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allData = useAppSelector((state) => state.models.measurableSpend);
  const curSessionId = useAppSelector((state) => state.models.curSessionId);
  const [isLoading, setIsLoading] = React.useState(false);

  const headerData = React.useMemo(() => {
    return {
      title: 'Validate Media Spend',
      currentState: 'Review',
      subtleText: 'Please validate the Total and Measurable Spend matches your plans',
      helpTooltipProps: {
        title: 'Validate the Total Media and Measurable Spend',
        description: (
          <p>
            The report shows you the breakdown of your Total and Measurable Spend.
            <p className='mt-2'>
              Any spend that has recorded impressions are counted as Measurable Spend and will be used to measure your media ROI.
            </p>
          </p>
        )
      }
    };
  }, []);

  const handleApprove = async () => {
    setIsLoading(true);
    try {
      await dispatch(mediaPlanVerify(curSessionId)).unwrap();
      console.log('Media plan verify success');
    } catch (error) {
      console.error('Media plan verify error: ', error);
    }

    setIsLoading(false);
    navigate('/review-channel');
  };

  return (
    <AppLayout headerProps={headerData}>
      <OverflowContent className={clsx('flex flex-col xl:flex-row gap-10 w-full')} isRequireSpace>
        <Card size='sm' className={clsx('flex flex-col lg:w-2/3', cardHeight.md)} noShadow>
          <Title as='h5' className='text-center mb-4'>
            Total vs Measurable Spend $ ('000)
          </Title>
          <div style={graphLayoutContainer}>
            <BarChartCustom
              maxBarSize={20}
              fieldKeys={{ yAxis: 'name', solidBar: 'total_spend', lightBar: 'spend' }}
              maxDomainRef='total_spend'
              data={allData?.aggregated_spend}
              barCategoryGap={5}
              barGap={8}
              firstLabel='Total spend'
              secondLabel='Measurable spend'
              noCartesian
              isDollar
            />
          </div>
        </Card>
        <div className={clsx('flex justify-around flex-col gap-8 lg:w-1/3', cardHeight.md)}>
          <Card size='sm' className='flex-1' noShadow>
            <Title as='h5' className='text-center text-neutral-100 mb-4'>
              % Spend Included In Model
            </Title>
            <PieChartCustom
              toolTipLabelGenerator={spendToolTipLabel}
              data={allData?.spend_percentage}
              averageTitle={allData?.spend_percentage?.[0]?.value ? `${allData?.spend_percentage?.[0]?.value}%` : '0%'}
              isPercentage
            />
          </Card>
          <Card size='sm' className='flex-1' noShadow>
            <Title as='h5' className='text-center text-neutral-100 mb-4'>
              Share Of Spend Across Channels
            </Title>
            <PieChartCustom
              isChannelName
              toolTipLabelGenerator={shareToolTipLabel}
              data={allData?.aggregated_spend}
              fieldKey={{ value: 'spend_percent' }}
              isPercentage
              averageTitle={`${formatCurrency(allData?.measurable_total_spend)} Spend`}
            />
          </Card>
        </div>
      </OverflowContent>
      <div className='flex flex-col justify-center lg:flex-row lg:justify-between items-center'>
        <GButton variant='default' size='md' className='px-0' onClick={() => navigate('/upload')}>
          <i className='fa-regular fa-angle-left h-[16px] w-[10px]'></i>
          <div className='text-md font-semibold'>Back to new project</div>
        </GButton>

        <AppButtonWrapper className='flex flex-row mx-auto mt-4 lg:mt-0 lg:mr-0'>
          <SButton onClick={() => dispatch(flickHelpTooltip())}>My data is wrong</SButton>
          <GButton
            disabled={isLoading}
            variant='primary'
            size='md'
            suffix={<i className='fa-light fa-chevron-right' />}
            onClick={handleApprove}
          >
            Approve & continue
          </GButton>
        </AppButtonWrapper>
      </div>
    </AppLayout>
  );
}
