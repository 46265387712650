import clsx from 'clsx';
import React from 'react';

type AppLinkType = React.ComponentPropsWithRef<'a'>;

export function AppLink({ children, className, ...rest }: AppLinkType) {
  return (
    <a className={clsx('text-sm text-neutral-100 flex items-center gap-2', className)} {...rest}>
      {children}
    </a>
  );
}
