import { utils, writeFile } from 'xlsx';

import { HashMap } from '@/types';

export const xlsxDataPrepareMapper = (data: HashMap, aggregatedSpend: HashMap[]) => {
  const shareOfVoiceData = data.share_of_voice.map((data) => [
    data.quarter,
    data.sov,
    data.total_client_spend,
    data.total_competitor_spend,
    data.total_kpi
  ]);
  const shareOfVoiceWholePayload = [['quarter', 'sov', 'total_client_spend', 'total_competitor_spend', 'total_kpi'], ...shareOfVoiceData];
  const worksheetShareVoice = utils.aoa_to_sheet(shareOfVoiceWholePayload);
  const mmmLiteDecompData = data.mmmlite_decomp.map((data) => [data.date, data.base, data.brand, data.media]);

  const mmmLiteDecompWholePayload = [['date', 'base', 'brand', 'media'], ...mmmLiteDecompData];
  const worksheetMMMLiteDecomp = utils.aoa_to_sheet(mmmLiteDecompWholePayload);

  const yearlyMMMLiteData = data.yearly_mmmlite_budget_optimized.map((data) => [
    data.total_budget_change,
    data.media_channels,
    data.current_spend,
    data.optimal_spend,
    data.current_contribution,
    data.optimal_contribution,
    data.difference_contribution
  ]);
  const yearlymmmLiteWholePayload = [
    [
      'total_budget_change',
      'media_channels',
      'current_spend',
      'optimal_spend',
      'current_contribution',
      'optimal_contribution',
      'difference_contribution'
    ],
    ...yearlyMMMLiteData
  ];
  const worksheetYearlyMMMLite = utils.aoa_to_sheet(yearlymmmLiteWholePayload);

  const mediaPlanData = data.media_plan_check.map((data) => [
    data.channel,
    data.has_media_spend,
    data.zero_values,
    data.standard_deviation,
    data.is_numeric,
    data.contains_no_nulls,
    data.valid,
    data.warnings
  ]);
  const mediaPlanWholePayload = [
    ['channel', 'has_media_spend', 'zero_values', 'standard_deviation', 'is_numeric', 'contains_no_nulls', 'valid', 'warnings'],
    ...mediaPlanData
  ];
  const worksheetMediaPlanCheck = utils.aoa_to_sheet(mediaPlanWholePayload);

  const totalMeasurableSpendData = data.total_measurable_spend.map((data) => [
    data.date,
    data.ooh_spend,
    data.total_ooh_spend,
    data.social_spend,
    data.total_social_spend,
    data.youtube_spend,
    data.total_youtube_spend,
    data.display_spend,
    data.total_display_spend,
    data.sem_spend,
    data.total_sem_spend,
    data.magazine_spend,
    data.total_magazine_spend,
    data.newspaper_spend,
    data.total_newspaper_spend,
    data.radio_spend,
    data.total_radio_spend,
    data.tv_spend,
    data.total_tv_spend,
    data.radio_impressions,
    data.magazine_impressions,
    data.newspaper_impressions,
    data.ooh_impressions,
    data.tv_impressions,
    data.youtube_impressions,
    data.display_impressions,
    data.sem_impressions,
    data.social_impressions
  ]);
  const totalMeasurableSpendPayload = [
    [
      'date',
      'ooh_spend',
      'total_ooh_spend',
      'social_spend',
      'total_social_spend',
      'youtube_spend',
      'total_youtube_spend',
      'display_spend',
      'total_display_spend',
      'sem_spend',
      'total_sem_spend',
      'magazine_spend',
      'total_magazine_spend',
      'newspaper_spend',
      'total_newspaper_spend',
      'radio_spend',
      'total_radio_spend',
      'tv_spend',
      'total_tv_spend',
      'radio_impressions',
      'magazine_impressions',
      'newspaper_impressions',
      'ooh_impressions',
      'tv_impressions',
      'youtube_impressions',
      'display_impressions',
      'sem_impressions',
      'social_impressions'
    ],
    ...totalMeasurableSpendData
  ];
  const worksheetTotalMeasurableSpend = utils.aoa_to_sheet(totalMeasurableSpendPayload);

  const competitorSpendData = data.competitor_spend.map((data) => [data.client_id, data.date, data.competitor, data.spend]);
  const competitorSpendPayload = [['client_id', 'date', 'competitor', 'spend'], ...competitorSpendData];
  const worksheetCompetitorSpend = utils.aoa_to_sheet(competitorSpendPayload);

  const aggSpendData = aggregatedSpend.map((data) => [
    data.name,
    data.raw_spend,
    data.raw_total_spend,
    data.raw_impression,
    data.spend_percent + '%'
  ]);
  const aggSpendDataPayload = [['channel', 'measurable_spend', 'total_spend', 'impression', 'spend_percent'], ...aggSpendData];
  const worksheetAggSpend = utils.aoa_to_sheet(aggSpendDataPayload);

  const modelRoiData = data.model_roi.map((data) => [
    data.group,
    data.category,
    data.channel,
    data.roi,
    data.reach_per_dollar,
    data.spend,
    data.perc,
    data.contribution
  ]);
  const modelRoiPayload = [['group', 'category', 'channel', 'roi', 'reach_per_dollar', 'spend', 'perc', 'contribution'], ...modelRoiData];
  const worksheetModelRoi = utils.aoa_to_sheet(modelRoiPayload);

  const bubbleAPIData = data.bubble_data.map((data) => [
    data.channel,
    data.spend_proportion + '%',
    data.sales_proportion + '%',
    data.total_contribution + '%'
  ]);
  const bubbleAPIPayload = [['channel', 'spend_proportion', 'sales_proportion', 'total_contribution'], ...bubbleAPIData];
  const worksheetBubbleAPI = utils.aoa_to_sheet(bubbleAPIPayload);

  const wb = utils.book_new();
  utils.book_append_sheet(wb, worksheetTotalMeasurableSpend, 'Total Measurable Spend');
  utils.book_append_sheet(wb, worksheetAggSpend, 'Measurable Spend');
  utils.book_append_sheet(wb, worksheetMediaPlanCheck, 'Media Plan Check');
  utils.book_append_sheet(wb, worksheetShareVoice, 'Share of Voice');
  utils.book_append_sheet(wb, worksheetCompetitorSpend, 'Competitor Spend');
  utils.book_append_sheet(wb, worksheetModelRoi, 'Model ROI');
  utils.book_append_sheet(wb, worksheetBubbleAPI, 'Bubble API Data');
  utils.book_append_sheet(wb, worksheetMMMLiteDecomp, 'MMMLite Decomp');
  utils.book_append_sheet(wb, worksheetYearlyMMMLite, 'Yearly MMMLite Budget Optimised');

  writeFile(wb, 'omni-romi-result.xlsx');
};
