import React, { useState } from 'react';
import clsx from 'clsx';
import Papaparser from 'papaparse';
import { HashMap } from '@/types';

type FileUploadType = {
  data: any;
  setData: Function;
  text: string;
  loadingText: string;
  className?: string;
  setModalOpen?: Function;
  clientData?: HashMap;
};

const validColumns = ['client_id', 'client_name', 'date', 'kpi', 'web_traffic'];

export function FileUpload({ text, loadingText, className, setData, data, setModalOpen, clientData }: FileUploadType) {
  const [isLoading, setIsLoading] = useState(false);

  const isCsvValid = (file): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      Papaparser.parse(file, {
        header: true,
        complete(results) {
          const columns = Object.keys(results.data[0]);
          const firstDataSet = results?.data?.[1] ?? {};
          if (columns.length !== 4 && columns.length !== 5) {
            reject('invalid file');
          }
          columns.forEach((element) => {
            if (!validColumns.includes(element)) {
              reject('invalid file');
            }
          });

          if (
            firstDataSet?.[validColumns[0]] &&
            firstDataSet?.[validColumns[1]] &&
            (clientData?.id?.toString() !== firstDataSet?.[validColumns[0]] || clientData?.name !== firstDataSet?.[validColumns[1]])
          ) {
            reject('invalid file');
          }
          resolve(results.data);
        },
        error(err) {
          reject(err);
        }
      });
    });
  };

  const handleFileSelect = async (e: any) => {
    try {
      setIsLoading(true);
      if (e.target.files && e.target.files[0]) {
        await isCsvValid(e.target.files[0]);

        setData(e.target.files[0]);
      }
      setIsLoading(false);
    } catch (error) {
      if (error === 'invalid file') {
        setModalOpen(true);
      }
      console.log('File Error: ', error);
      setIsLoading(false);
    }
  };

  return (
    <div className={clsx('flex w-full h-screen bg-grey-lighter', className)}>
      {isLoading ? (
        <div className='flex items-center p-[18px] bg-white text-neutral-100 rounded-md tracking-default cursor-pointer border-2 border-dashed border-[#00A0D3] flex-1'>
          <div className='flex flex-row justify-center w-full items-center'>
            <div className='text-lg loader h-2 w-2' />
            <span className='text-md text-[#00A0D3] ml-[8px]'>{loadingText}</span>
          </div>
        </div>
      ) : (
        <React.Fragment>
          {data ? (
            <label className='flex items-center p-[18px] text-neutral-100 rounded-md tracking-default cursor-pointer border-2 border-dashed border-secondary-50 bg-secondary-50 flex-1'>
              <div className='flex flex-row justify-center w-full items-center'>
                <span className='text-md text-[#fff] ml-[8px]'>{data?.name}</span>
                <input type='file' className='hidden' accept='.csv' onChange={handleFileSelect} />
              </div>
            </label>
          ) : (
            <label className='flex items-center p-[18px] bg-white text-neutral-100 rounded-md tracking-default cursor-pointer border-2 border-dashed border-[#566169] flex-1'>
              <div className='flex flex-row justify-between w-full items-center'>
                <span className='text-lg'>{text}</span>
                <span className='text-lg'>
                  <i className='fa-solid fa-plus'></i>
                </span>
              </div>
              <input type='file' className='hidden' accept='.csv' onChange={handleFileSelect} />
            </label>
          )}
        </React.Fragment>
      )}
    </div>
  );
}
