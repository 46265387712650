// @ts-nocheck
import React from 'react';
import { Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  footerContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    bottom: 30,
    left: 38,
    right: 0,
    marginLeft: '10px'
  },
  logoImage: {
    position: 'relative',
    top: 1
  },
  footerText: {
    fontSize: 12,
    color: 'black'
  }
});

const Footer = () => {
  return (
    <View style={styles.footerContainer}>
      <Svg width='80' height='18' viewBox='0 0 188 46' style={styles.logoImage}>
        <Path d='M183.85 14.5923H187.67V30.6923H183.85V14.5923Z' fill='black' />
        <Path
          d='M156.87 14.5922H160.38V15.9522H160.44C160.76 15.4322 161.85 14.0322 164.61 14.0322C165.47 14.0322 167.71 14.2322 169.21 16.5622C170.87 14.1522 173.22 14.0322 174.34 14.0322C177.47 14.0322 179.02 15.7122 179.6 16.5322C180.23 17.3722 180.81 18.9122 180.81 21.2922V30.6922H176.99V21.7522C176.99 20.8222 176.99 17.5722 173.97 17.5722C172.91 17.5722 171.87 18.1522 171.33 19.0822C170.78 19.9522 170.76 21.3722 170.76 22.3622V30.6922H166.94V21.7522C166.94 19.5422 166.11 17.5722 163.75 17.5722C160.7 17.5722 160.7 20.9422 160.7 22.6522V30.6922H156.88V14.5922H156.87Z'
          fill='black'
        />
        <Path
          d='M137.66 22.6522C137.66 17.7822 141.59 14.0322 146.16 14.0322C150.73 14.0322 154.61 17.6622 154.61 22.5922C154.61 27.8722 150.59 31.2122 146.16 31.2122C141.42 31.2122 137.66 27.2622 137.66 22.6522ZM150.79 22.6822C150.79 19.7522 148.55 17.5722 146.16 17.5722C144.18 17.5722 141.48 19.2822 141.48 22.6522C141.48 25.7022 143.75 27.6722 146.16 27.6722C148.95 27.6722 150.79 25.2322 150.79 22.6822Z'
          fill='black'
        />
        <Path
          d='M128.07 14.592H131.58V16.012H131.64C132.36 14.972 133.08 14.042 135.06 14.042H135.38V17.782C131.9 17.922 131.9 20.682 131.9 21.642V30.702H128.08V14.592H128.07Z'
          fill='black'
        />
        <Path d='M118.02 14.5127H114.244V30.5327H118.02V14.5127Z' fill='black' />
        <Path
          d='M104.308 17.4927C103.837 17.4927 103.367 17.6227 102.866 17.8827C102.366 18.1427 101.954 18.5927 101.611 19.2327C101.268 19.8727 101.081 20.7427 101.081 21.8327V30.5827H97.3052V14.5427H100.797V15.8927C101.454 15.2527 102.121 14.7427 102.807 14.4527C103.504 14.1327 104.249 13.9727 105.132 13.9727C106.015 13.9727 106.858 14.1927 107.8 14.6127C108.741 15.0327 109.526 15.7627 110.222 16.8227C110.919 17.8827 111.262 19.2927 111.262 21.0527V30.5027H107.496V22.4627C107.496 19.1627 106.427 17.4927 104.318 17.4927'
          fill='black'
        />
        <Path
          d='M87.6442 17.4927C86.6732 17.4927 85.8788 17.8427 85.251 18.5227C84.6528 19.2327 84.3389 20.2827 84.3389 21.7627V30.5427H80.5628V21.5327C80.5628 20.3127 80.2784 19.3227 79.7488 18.5827C79.2191 17.8427 78.4247 17.4927 77.4537 17.4927C76.414 17.4927 75.6588 17.8827 75.1586 18.6127C74.6584 19.3527 74.4328 20.6027 74.4328 22.3627V30.5327H70.6567V14.5127H74.1092V15.8627H74.168C74.5407 15.2527 75.0801 14.7727 75.8059 14.4527C76.5612 14.1327 77.3752 13.9727 78.2874 13.9727C79.1995 13.9727 80.0822 14.1927 80.8669 14.6127C81.6515 15.0327 82.2792 15.6727 82.8187 16.4727C83.4464 15.5727 84.1722 14.9327 84.9862 14.5527C85.8003 14.1727 86.7811 13.9727 87.8796 13.9727C89.0467 13.9727 90.1158 14.2327 91.0868 14.7127C92.0578 15.1927 92.813 15.9627 93.3819 17.0227C93.9507 18.0527 94.2352 19.3627 94.2352 20.9327V30.5827H90.4591V21.5427C90.4591 20.2627 90.2041 19.2627 89.7333 18.5627C89.2919 17.8627 88.5661 17.5027 87.6246 17.5027'
          fill='black'
        />
        <Path
          d='M60.1132 31.0824C55.4937 31.0824 51.7568 27.2324 51.7568 22.5224C51.7568 17.8124 55.4937 14.0024 60.1132 14.0024C64.7328 14.0024 68.4697 17.8124 68.4697 22.5224C68.4697 27.2324 64.7328 31.0824 60.1132 31.0824ZM60.1132 17.5224C57.4062 17.5224 55.2093 19.7624 55.2093 22.5224C55.2093 25.2824 57.4062 27.5224 60.1132 27.5224C62.8203 27.5224 65.0172 25.2824 65.0172 22.5224C65.0172 19.7624 62.8497 17.5224 60.1132 17.5224Z'
          fill='black'
        />
        <Path
          d='M22.0776 31.0727C17.458 31.0727 13.7212 27.2227 13.7212 22.5127C13.7212 17.8027 17.458 13.9927 22.0776 13.9927C26.6972 13.9927 30.434 17.8027 30.434 22.5127C30.434 27.2227 26.6972 31.0727 22.0776 31.0727Z'
          fill='black'
        />
        <Path
          d='M40.507 34.9025C41.3014 33.6725 41.988 32.3525 42.5274 30.9825C43.057 29.6425 43.469 28.2425 43.7436 26.8125C44.0084 25.4025 44.1457 23.9525 44.1457 22.5125C44.1457 21.0725 44.0084 19.6225 43.7436 18.2125C43.469 16.7925 43.0668 15.3825 42.5274 14.0425C41.9781 12.6725 41.3014 11.3625 40.507 10.1225C39.6929 8.8625 38.7415 7.6725 37.6822 6.5925C36.623 5.5125 35.4558 4.5425 34.22 3.7125C33.0136 2.9025 31.719 2.2025 30.3753 1.6525C29.061 1.1125 27.6781 0.6925 26.2854 0.4125C23.5195 -0.1375 20.6261 -0.1375 17.8603 0.4125C16.4676 0.6925 15.0846 1.1025 13.7704 1.6525C12.4267 2.2025 11.1418 2.9025 9.92562 3.7125C9.10175 4.2625 8.87616 5.3925 9.4156 6.2425C9.95504 7.0825 11.0633 7.3125 11.8872 6.7625C12.8974 6.0825 13.9763 5.5025 15.1042 5.0425C16.2027 4.5825 17.3601 4.2425 18.5272 4.0025C20.8517 3.5425 23.2743 3.5425 25.5988 4.0025C26.7659 4.2325 27.9233 4.5825 29.0218 5.0425C30.1497 5.5025 31.2286 6.0825 32.2388 6.7625C33.2785 7.4625 34.2494 8.2725 35.142 9.1825C36.0345 10.0825 36.829 11.0825 37.5155 12.1425C38.1825 13.1725 38.7513 14.2725 39.2025 15.4225C39.6537 16.5425 39.9871 17.7225 40.2127 18.9125C40.4383 20.0925 40.556 21.3125 40.556 22.5125C40.556 23.7125 40.4383 24.9325 40.2127 26.1125C39.9871 27.3125 39.6438 28.4825 39.2025 29.6025C38.7513 30.7425 38.1825 31.8525 37.5155 32.8825C36.9761 33.7225 37.2017 34.8525 38.0255 35.4125C38.3296 35.6125 38.6729 35.7125 39.0063 35.7125C39.585 35.7125 40.1539 35.4225 40.4971 34.8925'
          fill='black'
        />
        <Path
          d='M26.2952 44.6128C27.688 44.3328 29.0709 43.9228 30.3852 43.3728C31.7289 42.8228 33.0137 42.1228 34.2299 41.3128C35.0538 40.7628 35.2794 39.6328 34.7399 38.7928C34.1907 37.9528 33.0922 37.7228 32.2585 38.2728C31.2483 38.9528 30.1694 39.5328 29.0415 39.9928C27.943 40.4528 26.7856 40.7928 25.6185 41.0328C23.294 41.4928 20.8714 41.4928 18.5469 41.0328C17.3798 40.8028 16.2224 40.4528 15.1239 39.9928C14.0058 39.5328 12.9171 38.9528 11.9069 38.2728C10.8673 37.5728 9.89627 36.7628 9.00374 35.8528C8.12102 34.9428 7.31676 33.9528 6.6302 32.8928C5.96326 31.8628 5.3944 30.7628 4.94323 29.6128C4.49206 28.4928 4.14878 27.3128 3.9232 26.1228C3.69761 24.9428 3.58973 23.7228 3.58973 22.5228C3.58973 21.3228 3.70742 20.1028 3.9232 18.9228C4.14878 17.7228 4.49206 16.5528 4.94323 15.4328C5.3944 14.2928 5.96326 13.1828 6.6302 12.1528C7.17945 11.3128 6.94406 10.1828 6.12019 9.63277C5.29632 9.08277 4.18801 9.31277 3.63877 10.1528C2.84432 11.3828 2.15776 12.7028 1.61832 14.0728C1.08869 15.4128 0.676752 16.8128 0.402128 18.2428C0.137312 19.6528 0 21.1028 0 22.5428C0 23.9828 0.137312 25.4328 0.402128 26.8428C0.676752 28.2628 1.07888 29.6728 1.61832 31.0128C2.15776 32.3828 2.84432 33.6928 3.63877 34.9328C4.45283 36.1928 5.40421 37.3828 6.46347 38.4628C7.52273 39.5428 8.68988 40.5128 9.92569 41.3428C11.1321 42.1528 12.4267 42.8528 13.7704 43.4028C15.0847 43.9428 16.4578 44.3628 17.8604 44.6428C19.2433 44.9128 20.6654 45.0528 22.0778 45.0528C23.4901 45.0528 24.9123 44.9128 26.2952 44.6428'
          fill='black'
        />
      </Svg>

      <Text style={styles.footerText}>is created by OMG Media Group. ©omni romi™ All rights reserved 2022</Text>
    </View>
  );
};

export default Footer;
