import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  mapAllMeasurableAndSpends,
  mapCompetitorSpendValidation,
  mapMediaChannelModelValidation,
  mapMMMLiteDecomp,
  mapModelledRoi,
  mapYearlyMMMLiteBudget,
  mapShareOfVoiceData
} from '@/utils/chart-mapping';
import {
  competitorSpendApi,
  listClientsApi,
  mediaChannelsPassingModelApi,
  mediaPlanVerifyApi,
  mmmliteDecompApi,
  modelledROIResultApi,
  modelsRunApi,
  sessionsApi,
  shareOfVoiceApi,
  totalMeasurableSpendApi,
  yearlyMMMLiteBudgetApi,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  uploadClientCsvApi,
  evalutionMetricModelApi,
  sessionRemovalApi
} from '@/api/modelApi';
import { HashMap, IClientType, IGeneralChartType } from '@/types';
import { isEmpty } from 'lodash';
import { toastErrorMsg } from '@/components/Common/Toast';
import { sleepForSpecificTime } from '@/utils/helpers';

export type ModelState = {
  clients: IClientType[];
  curSessionId?: string;
  isHelpTooltipOpen: boolean;
  measurableSpend: HashMap;
  yearlyMMMBudget: HashMap;
  competitorSpends: IGeneralChartType[];
  liteDecomp: HashMap[];
  sessions: HashMap[];
  bubbleData: HashMap[];
  evalutionMetric: HashMap[];
  mediaSalesContribution: HashMap[];
  externalSalesContribution: HashMap[];
  clientLoadingState: boolean;
  clientsCsvUploadLoading: boolean;
  reviewStageLoading: boolean;
  competitorSpendStageLoading: boolean;
  mediaChannelModelValidationStageLoading: boolean;
  evalutionMetricStageLoading: boolean;
  sessionsStageLoading: boolean;
  yearlyMMMBudgetStageLoading: boolean;
  mediaChannelModelValidation: any;
  modelledResults: any;
  modelledResultsStageLoading: boolean;
  shareOfVoice: HashMap[];
  shareOfVoiceLoading: boolean;
  currentClientData?: IClientType;
  apiData: any;
  optimiseUserConfig: any;
};

const initialState: ModelState = {
  clients: [],
  clientLoadingState: false,
  curSessionId: undefined,
  clientsCsvUploadLoading: false,
  measurableSpend: {
    aggregated_spend: {},
    spend_percentage: [],
    measurable_total_spend: 0,
    unmeasurable_total_spend: 0,
    monthChannelDataAggregation: [],
    firstKpiDate: '',
    lastKpiDate: ''
  },
  mediaSalesContribution: [],
  externalSalesContribution: [],
  bubbleData: [],
  evalutionMetric: [],
  yearlyMMMBudget: {
    yearly_values: {}
  },
  competitorSpends: [],
  liteDecomp: [],
  sessions: [],
  reviewStageLoading: false,
  evalutionMetricStageLoading: false,
  competitorSpendStageLoading: false,
  isHelpTooltipOpen: false,
  mediaChannelModelValidation: [],
  mediaChannelModelValidationStageLoading: false,
  sessionsStageLoading: false,
  modelledResults: [],
  modelledResultsStageLoading: false,
  yearlyMMMBudgetStageLoading: false,
  shareOfVoice: [],
  shareOfVoiceLoading: false,
  currentClientData: {
    id: null,
    name: '',
    startDate: '',
    endDate: ''
  },
  apiData: {},
  optimiseUserConfig: {
    spendCheck: true,
    optimisedSpendCheck: true,
    totalSpendCheck: true,
    spendSliderValue: 60
  }
};

export const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    updateCurrentSessionId: (state, action) => {
      state.curSessionId = action.payload;
    },
    flickHelpTooltip: (state) => {
      state.isHelpTooltipOpen = !state.isHelpTooltipOpen;
    },
    updateCurrentClientData: (state, action) => {
      if (action.payload?.id) state.currentClientData.id = action.payload?.id;
      if (action.payload?.name) state.currentClientData.name = action.payload?.name;
      if (action.payload?.startDate) state.currentClientData.startDate = action.payload?.startDate;
      if (action.payload?.endDate) state.currentClientData.endDate = action.payload?.endDate;
    },
    updateOptimiseUserConfig: (state, action) => {
      if (action.payload.spendCheck !== state.optimiseUserConfig.spendCheck)
        state.optimiseUserConfig.spendCheck = action.payload.spendCheck;
      if (action.payload.optimisedSpendCheck !== state.optimiseUserConfig.optimisedSpendCheck)
        state.optimiseUserConfig.optimisedSpendCheck = action.payload.optimisedSpendCheck;
      if (action.payload.totalSpendCheck !== state.optimiseUserConfig.totalSpendCheck)
        state.optimiseUserConfig.totalSpendCheck = action.payload.totalSpendCheck;
      if (action.payload.spendSliderValue !== state.optimiseUserConfig.spendSliderValue)
        state.optimiseUserConfig.spendSliderValue = action.payload.spendSliderValue;
    },
    clearAllModelData: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClients.pending, (state, _) => {
      state.clientLoadingState = true;
    });
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      state.clientLoadingState = false;
      state.clients = (action?.payload as IClientType[]) ?? [];
    });

    builder.addCase(fetchClients.rejected, (state, action) => {
      state.clientLoadingState = false;
    });

    /* Upload CSV */
    builder.addCase(uploadCsvKpi.pending, (state, action) => {
      state.curSessionId = undefined;
      state.clientsCsvUploadLoading = true;
    });
    builder.addCase(uploadCsvKpi.fulfilled, (state, action) => {
      state.curSessionId = action?.payload?.session_id;
      state.clientsCsvUploadLoading = false;
    });
    builder.addCase(uploadCsvKpi.rejected, (state, action) => {
      state.clientsCsvUploadLoading = false;
    });

    builder.addCase(responseAPIForDownload.fulfilled, (state, action) => {
      state.apiData = action?.payload;
    });

    /* Total Measure Spend */
    builder.addCase(fetchTotalMeasurableSpend.pending, (state, action) => {
      state.reviewStageLoading = true;
    });
    builder.addCase(fetchTotalMeasurableSpend.fulfilled, (state, action) => {
      state.measurableSpend = action?.payload;
      state.reviewStageLoading = false;
      if (isEmpty(state.measurableSpend?.aggregated_spend)) {
        toastErrorMsg('Empty data, please check the csv file.');
      }
    });
    builder.addCase(fetchTotalMeasurableSpend.rejected, (state, action) => {
      state.reviewStageLoading = false;
    });

    /* Media channel model validation */
    builder.addCase(fetchMediaChannelPassingModel.pending, (state, action) => {
      state.mediaChannelModelValidationStageLoading = true;
    });
    builder.addCase(fetchMediaChannelPassingModel.fulfilled, (state, action) => {
      state.mediaChannelModelValidation = action?.payload;
      state.mediaChannelModelValidationStageLoading = false;
    });
    builder.addCase(fetchMediaChannelPassingModel.rejected, (state, action) => {
      state.mediaChannelModelValidationStageLoading = false;
    });

    /* Competitor Spends */
    builder.addCase(fetchCompetitorSpend.pending, (state, action) => {
      state.competitorSpendStageLoading = true;
    });
    builder.addCase(fetchCompetitorSpend.fulfilled, (state, action) => {
      state.competitorSpends = action?.payload;
      state.competitorSpendStageLoading = false;
    });
    builder.addCase(fetchCompetitorSpend.rejected, (state, action) => {
      state.competitorSpendStageLoading = false;
    });

    /* Modelled Result (ROI) & Sales Contributions */
    builder.addCase(fetchModelledResult.pending, (state, action) => {
      state.modelledResultsStageLoading = true;
    });
    builder.addCase(fetchModelledResult.fulfilled, (state, action) => {
      state.modelledResults = action?.payload?.modelledResultData;
      state.mediaSalesContribution = action?.payload?.mediaSalesContribution;
      state.externalSalesContribution = action?.payload?.externalSalesContribution;
      state.bubbleData = action?.payload?.mappedBubbleData;
      state.modelledResultsStageLoading = false;
    });
    builder.addCase(fetchModelledResult.rejected, (state, action) => {
      state.modelledResultsStageLoading = false;
    });
    /* Yearly MMM Lite Budget */
    builder.addCase(fetchYearlyMMMLiteBudgetResult.pending, (state, action) => {
      state.yearlyMMMBudgetStageLoading = true;
    });
    builder.addCase(fetchYearlyMMMLiteBudgetResult.fulfilled, (state, action) => {
      state.yearlyMMMBudget = action?.payload;
      state.yearlyMMMBudgetStageLoading = false;
    });
    builder.addCase(fetchYearlyMMMLiteBudgetResult.rejected, (state, action) => {
      state.yearlyMMMBudgetStageLoading = false;
    });
    /* Scatter/Bubble Chart */
    builder.addCase(fetchSessions.pending, (state, action) => {
      state.sessionsStageLoading = true;
    });
    builder.addCase(fetchSessions.fulfilled, (state, action) => {
      state.sessions = action?.payload;
      state.sessionsStageLoading = false;
    });
    builder.addCase(fetchSessions.rejected, (state, action) => {
      state.sessionsStageLoading = false;
    });

    /* MMMLite Decomp */
    builder.addCase(fetchMMMliteDecomp.pending, (state, action) => {});
    builder.addCase(fetchMMMliteDecomp.fulfilled, (state, action) => {
      state.liteDecomp = action?.payload;
    });
    builder.addCase(fetchMMMliteDecomp.rejected, (state, action) => {});

    /* Share Of Voice Chart */
    builder.addCase(fetchShareOfVoice.pending, (state, action) => {
      state.shareOfVoiceLoading = true;
    });
    builder.addCase(fetchShareOfVoice.fulfilled, (state, action) => {
      state.shareOfVoice = action?.payload;
      state.shareOfVoiceLoading = false;
    });
    builder.addCase(fetchShareOfVoice.rejected, (state, action) => {
      state.shareOfVoiceLoading = false;
    });

    /* Evalution Metric */
    builder.addCase(fetchEvalutionMetricCheck.pending, (state, action) => {
      state.evalutionMetricStageLoading = true;
    });
    builder.addCase(fetchEvalutionMetricCheck.fulfilled, (state, action) => {
      state.evalutionMetric = action?.payload;
      state.evalutionMetricStageLoading = false;
    });
    builder.addCase(fetchEvalutionMetricCheck.rejected, (state, action) => {
      state.evalutionMetricStageLoading = false;
    });
  }
});

export const { flickHelpTooltip, clearAllModelData, updateCurrentSessionId, updateCurrentClientData, updateOptimiseUserConfig } =
  modelsSlice.actions;

/* Async Thunk */

export const fetchClients = createAsyncThunk('models/fetchClients', async (_, { rejectWithValue }) => {
  try {
    const response = await listClientsApi();
    return response?.data ?? [];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue(error?.response);
    } else {
      return rejectWithValue(error);
    }
  }
});

export const sessionDataGathering = createAsyncThunk(
  'models/sessionDataGathering',
  async ({ id }: { id: string; mediaPlan?: boolean }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(updateCurrentSessionId(id));
      await dispatch(fetchTotalMeasurableSpend(id)).unwrap();
      await dispatch(fetchCompetitorSpend(id));
      await dispatch(fetchMediaChannelPassingModel(id));
      await dispatch(fetchShareOfVoice(id));

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const sessionDataForCompletedModel = createAsyncThunk(
  'models/sessionDataForCompletedModel',
  async (sessionId: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(updateCurrentSessionId(sessionId));
      await sleepForSpecificTime();
      await dispatch(fetchTotalMeasurableSpend(sessionId)).unwrap();
      await dispatch(fetchCompetitorSpend(sessionId));
      await dispatch(fetchMediaChannelPassingModel(sessionId));
      await dispatch(fetchShareOfVoice(sessionId));
      await dispatch(fetchModelledResult(sessionId));
      await dispatch(fetchYearlyMMMLiteBudgetResult(sessionId));
      await dispatch(fetchMMMliteDecomp(sessionId));

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const uploadCsvKpi = createAsyncThunk('models/uploadCsvKpi', async (payload: any, { rejectWithValue, dispatch }) => {
  try {
    const response = await uploadClientCsvApi(payload);

    // As Model data is fetched from s3, it take some-time to
    // to get the respective data
    await sleepForSpecificTime();

    if (response.data?.session_id) {
      dispatch(fetchTotalMeasurableSpend(response.data.session_id));
      dispatch(fetchCompetitorSpend(response.data.session_id));
      dispatch(fetchMediaChannelPassingModel(response.data.session_id));
      dispatch(fetchShareOfVoice(response.data.session_id));
    }
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue(error?.response);
    } else {
      return rejectWithValue(error);
    }
  }
});

export const mediaPlanVerify = createAsyncThunk('models/mediaPlanVerify', async (payload: string, { rejectWithValue }) => {
  try {
    const response = await mediaPlanVerifyApi(payload);
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue(error?.response);
    } else {
      return rejectWithValue(error);
    }
  }
});

export const modelsRun = createAsyncThunk('models/modelsRun', async (payload: string, { rejectWithValue }) => {
  try {
    const response = await modelsRunApi(payload);
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue(error?.response);
    } else {
      return rejectWithValue(error);
    }
  }
});

export const fetchSessions = createAsyncThunk('models/fetchSessions', async (_: any, { rejectWithValue }) => {
  try {
    const response = await sessionsApi();
    return response?.data ?? [];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue(error?.response);
    } else {
      return rejectWithValue(error);
    }
  }
});

export const responseAPIForDownload = createAsyncThunk(
  'models/responseAPIForDownload',
  async (payload: any, { getState, rejectWithValue }) => {
    try {
      // @ts-ignore
      const apiData = getState().models?.apiData;
      const response = {
        ...apiData,
        ...payload
      };
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchTotalMeasurableSpend = createAsyncThunk(
  'models/fetchTotalMeasurableSpend',
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await totalMeasurableSpendApi(payload);

      dispatch(
        responseAPIForDownload({
          total_measurable_spend: response?.data ?? []
        })
      );

      const data = mapAllMeasurableAndSpends(response?.data ?? []);

      if (data?.firstKpiDate && data?.lastKpiDate) {
        dispatch(
          updateCurrentClientData({
            startDate: data?.firstKpiDate,
            endDate: data?.lastKpiDate
          })
        );
      }
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchMediaChannelPassingModel = createAsyncThunk(
  'models/fetchMediaChannelPassingModel',
  async (payload: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // @ts-ignore
      const currentSessionId = payload || getState().models?.curSessionId;
      if (!currentSessionId) return [];

      const response = await mediaChannelsPassingModelApi(currentSessionId);

      dispatch(
        responseAPIForDownload({
          media_plan_check: response?.data ?? []
        })
      );
      const data = mapMediaChannelModelValidation(response?.data ?? []);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchCompetitorSpend = createAsyncThunk(
  'models/fetchCompetitorSpend',
  async (payload: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await competitorSpendApi(payload);

      dispatch(
        responseAPIForDownload({
          competitor_spend: response?.data ?? []
        })
      );
      const data = mapCompetitorSpendValidation(response?.data ?? []);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchShareOfVoice = createAsyncThunk('models/fetchShareOfVoice', async (payload: string, { rejectWithValue, dispatch }) => {
  try {
    // @ts-ignore
    const response = await shareOfVoiceApi(payload);

    dispatch(
      responseAPIForDownload({
        share_of_voice: response?.data ?? []
      })
    );
    // @ts-ignore
    const data = mapShareOfVoiceData(response?.data ?? []);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue(error?.response);
    } else {
      return rejectWithValue(error);
    }
  }
});

export const fetchModelledResult = createAsyncThunk(
  'models/fetchModelledResult',
  async (payload: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await modelledROIResultApi(payload);

      const data = mapModelledRoi(response?.data ?? []);
      dispatch(
        responseAPIForDownload({
          model_roi: response?.data ?? [],
          bubble_data: data?.mappedBubbleData ?? []
        })
      );
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchYearlyMMMLiteBudgetResult = createAsyncThunk(
  'models/fetchYearlyMMMLiteBudgetResult',
  async (payload: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await yearlyMMMLiteBudgetApi(payload);
      dispatch(
        responseAPIForDownload({
          yearly_mmmlite_budget_optimized: response?.data ?? []
        })
      );
      // @ts-ignore
      const data = mapYearlyMMMLiteBudget(response?.data ?? []);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchMMMliteDecomp = createAsyncThunk(
  'models/fetchMMMliteDecomp',
  async (payload: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // @ts-ignore
      const sessionId = getState().models?.curSessionId;
      const response = await mmmliteDecompApi(sessionId);
      dispatch(
        responseAPIForDownload({
          mmmlite_decomp: response?.data ?? []
        })
      );
      // @ts-ignore
      const data = mapMMMLiteDecomp(response?.data ?? []);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchEvalutionMetricCheck = createAsyncThunk(
  'models/fetchEvalutionMetricCheck',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await evalutionMetricModelApi(payload);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const sessionRemovalCheck = createAsyncThunk(
  'models/sessionRemovalCheck',
  async (payload: string, { rejectWithValue, dispatch }) => {
    try {
      await sessionRemovalApi(payload);

      dispatch(fetchSessions(''));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export default modelsSlice.reducer;
