import React from 'react';
import { ReactComponent as UserLogo } from '@/assets/images/omni-user.svg';
import { ReactComponent as Home } from '@/assets/images/omni-home.svg';
import { GButton, IButton } from '../Common/Button';
import { Title } from '../Common/Typography';
import clsx from 'clsx';
import { HelpTooltip } from '../Common/Tooltip';
import { useNavigate, useLocation } from 'react-router-dom';
import InlineTextLogo from '@/assets/images/omni-inline-text-logo.svg';
import { ReactComponent as CircleInfo } from '@/assets/images/circle-info.svg';
import { Modal } from '../Common/Modal';
import { useState } from 'react';
import { CardHeaderIcon } from '../Common/Card';
import { editingRoutes } from '@/utils/constants';
import { useAppSelector } from '@/store/hooks';

type HelpTooltipProsp = { title: string; description: string | React.ReactNode };

type HeaderBtnGroupType = { primary?: boolean; helpTooltipProps: HelpTooltipProsp };

function HeaderBtnGroup({ primary, helpTooltipProps }: HeaderBtnGroupType) {
  const navigate = useNavigate();
  const location = useLocation();

  const [modalOpen, setModalOpen] = useState(false);

  const handleRedirectAccount = () => {
    if (location.pathname === '/my-account') {
      navigate('/');
    } else {
      navigate('/my-account');
    }
  };

  const isEditing = () => {
    return editingRoutes.includes(location.pathname);
  };

  const handleAccountClick = () => {
    if (location.pathname === '/my-account') {
      handleRedirectAccount();
    }

    // detect user in project editing phase
    if (isEditing()) {
      setModalOpen(true);
    } else {
      handleRedirectAccount();
    }
  };

  return (
    <>
      <div className='justify-end flex items-center gap-[0.3rem]'>
        <IButton
          className={clsx(
            'border rounded-full w-8 h-8 px-0 py-0 flex justify-center items-center',
            primary ? 'user-logo-header-primary' : 'user-logo-header-default'
          )}
          onClick={handleAccountClick}
        >
          <div className='filledLogo'>
            <i className='fa-solid fa-user h-[16px] w-[14px]'></i>
          </div>
          <div className='borderLogo'>{location.pathname === '/my-account' ? <Home /> : <UserLogo />}</div>
        </IButton>
        <HelpTooltip primary={primary} {...helpTooltipProps} />
      </div>

      {/* Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} size='lg'>
        <CardHeaderIcon icon={CircleInfo} isBlob />
        <Title as='h4' className='text-left card-title-text'>
          Warning
        </Title>
        <div className='text-base text-left tracking-[0.03em] text-neutral-100 pt-3'>
          <p>Leaving your current project now could result in the current progress being lost.</p>
        </div>
        <div className='flex flex-col gap-2 mt-[50px]'>
          <GButton type='button' bgGradient='gray' className='text-lg justify-end' onClick={() => setModalOpen(false)}>
            No, stay here
          </GButton>
          <GButton type='button' className='text-base font-semibold justify-end' onClick={handleRedirectAccount}>
            Go to my account
          </GButton>
        </div>
      </Modal>
    </>
  );
}

export function Header({ helpTooltipProps }: { helpTooltipProps: HelpTooltipProsp }) {
  return (
    <header className='relative p-12 m-auto flex justify-end flex-wrap text-white w-full max-w-[1550px] text-xs h-[83px]'>
      <div>
        <HeaderBtnGroup helpTooltipProps={helpTooltipProps} />
      </div>
    </header>
  );
}

export type AppHeaderType = { helpTooltipProps: HelpTooltipProsp; title?: string; currentState?: string; subtleText?: string };

export function AppHeader({ title, currentState, subtleText, helpTooltipProps }: AppHeaderType) {
  const currentClientData = useAppSelector((state) => state.models.currentClientData);

  const dateFormat = React.useMemo(() => {
    if (!currentClientData?.startDate && !currentClientData?.endDate) {
      return '';
    } else {
      return currentClientData?.startDate + ' to ' + currentClientData?.endDate;
    }
  }, [currentClientData]);

  return (
    <header className='flex lg:justify-between'>
      <div className='flex flex-col mr-auto'>
        <Title as='h1' className='text-xl lg:text-4xl'>
          {title || 'Getting Started'}
        </Title>
        <div className='lg:text-xl font-light mt-1 tracking-default lg:mt-0'>
          <span className='font-bold text-primary-50'>{currentState || 'Upload'}</span>
          <span className='text-neutral-100'>&nbsp; {subtleText || 'Select your client'}</span>
        </div>

        <div className='flex gap-2 mt-1 text-sm xl:hidden'>
          {dateFormat ? (
            <>
              <span>{currentClientData?.name || ''}</span>: <span>{dateFormat}</span>
            </>
          ) : null}
        </div>
      </div>
      <div className='flex flex-col text-right justify-center text-xs text-neutral-100'>
        <HeaderBtnGroup primary helpTooltipProps={helpTooltipProps} />

        <div className='mt-2.5 hidden xl:block'>
          {dateFormat ? (
            <>
              <span>{currentClientData?.name || ''}</span>: <span>{dateFormat}</span>
            </>
          ) : null}
        </div>
      </div>
    </header>
  );
}

export function ResultsDashboardHeader({ title = 'Results Dashboard', subtleText, helpTooltipProps }: AppHeaderType) {
  return (
    <header className='relative flex justify-around flex-wrap text-white w-[90%] z-50 mx-auto text-xs mt-[30px] mb-[16px]'>
      <div className='flex items-center justify-start w-full mb-4 lg:hidden'>
        <img src={InlineTextLogo} className='mr-auto w-32' alt='Omni Romi Logo' />
      </div>

      <div className='flex flex-col mr-auto'>
        <Title as='h1' className='text-white text-xl lg:text-4xl'>
          {title}
        </Title>
        <div className='text-base lg:text-xl font-light mt-1 lg:mt-0'>
          {subtleText ? (
            <span>
              <span>{subtleText}</span>
            </span>
          ) : (
            <div className='flex gap-2 mt-0 text-sm lg:hidden'>
              <span>Client Name</span>
              <span>12 Dec, 21 - 30 Jan, 22</span>
            </div>
          )}
        </div>
      </div>
      <div className='absolute right-0 top-0 flex flex-col text-right justify-center text-xs text-neutral-100 lg:relative'>
        <HeaderBtnGroup helpTooltipProps={helpTooltipProps} />
      </div>
    </header>
  );
}
