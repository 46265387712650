import React from 'react';
import clsx from 'clsx';
import { PolymorphicComponentProps } from '@/types/index';

type TitleType<C extends React.ElementType> = PolymorphicComponentProps<
  C,
  {
    children: React.ReactNode;
    className?: string;
  }
>;

type TextType = React.ComponentPropsWithRef<'p'>;

export function Title<C extends React.ElementType = 'h1'>({ as, children, className, ...rest }: TitleType<C>) {
  const Component = as || 'h1';

  const baseClassName = React.useMemo(() => {
    let name = '';

    switch (as) {
      case 'h1':
        name += 'font-bold text-2xl leading-tight text-neutral-100 tracking-app';
        break;
      case 'h2':
      case 'h3':
      case 'h4':
        name += 'font-bold text-[27px] leading-[36.77px]';
        break;
      case 'h5':
        name += 'font-bold text-[16px] leading-[22px]';
        break;
      case 'h6':
    }

    return name;
  }, [as]);

  return (
    <Component {...rest} className={clsx(baseClassName, className)}>
      {children}
    </Component>
  );
}

/* NOTE: To update all the text with this component */
export function Text({ children, className, ...rest }: TextType) {
  return (
    <p className={clsx('text-sm text-neutral-100', className)} {...rest}>
      {children}
    </p>
  );
}
