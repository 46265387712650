import React from 'react';
import clsx from 'clsx';
import { Title } from './Typography';
import OmniGlob from '@/assets/images/omni-glob.svg';

type CardType = React.ComponentProps<'div'> & { size?: 'lg' | 'md' | 'sm' | 'xsm' | 'noshadow'; isActive?: boolean; noShadow?: boolean };

export function Card({ className, size = 'md', isActive, noShadow, ...rest }: CardType) {
  const sizeClassName = React.useMemo(() => {
    switch (size) {
      case 'lg':
        return `p-[75px] ${isActive ? 'border-[3px] border-[#00A0D3] card-pulse' : ''}`;
      case 'md':
        return 'px-10 py-14 shadow-3xl min-h-[2rem] w-[24.75rem]';
      case 'sm':
        return `p-[25px] ${isActive ? 'border-[3px] border-[#00A0D3] shadow-5xl card-pulse' : 'shadow-3xl'}`;
      case 'xsm':
        return 'p-[7px]';
      case 'noshadow':
        return 'p-[20px] border border-[#D6D7D8]';
      default:
        return '';
    }
  }, [size, isActive]);

  return (
    <div className={clsx('rounded-[15px] bg-white flex flex-col', sizeClassName, noShadow ? 'shadow-none' : '', className)} {...rest}></div>
  );
}

type CardHeaderIconType = { icon: React.FunctionComponent<any>; className?: string; isBlob?: boolean };

export function CardHeaderIcon({ icon: Icon, className, isBlob = false }: CardHeaderIconType) {
  return (
    <div className={clsx(className ?? 'mb-[40px]')}>
      {isBlob ? (
        <div
          className='w-[105px] h-[85px]'
          style={{
            backgroundImage: `url(${OmniGlob})`
          }}
        >
          <Icon className='text-neutral-100 w-[87px] h-[87px]' />
        </div>
      ) : (
        <Icon className='text-neutral-100 w-[87px] h-[87px]' />
      )}
    </div>
  );
}

export function PriceAggregationCard({
  title,
  value,
  unformattedValue,
  isPDF
}: {
  title: string;
  value: string;
  unformattedValue: number;
  isPDF?: boolean;
}) {
  const updatedTitle = React.useMemo(() => {
    return title.replace(/uplift/g, unformattedValue >= 0 ? 'uplift' : 'decrease');
  }, [title, unformattedValue]);

  const textSize = isPDF ? 'text-base' : 'text-xs';

  return (
    <div className='flex py-[11px] px-[15px] w-full min-h-[70px] splash-wrapper text-white rounded-[10px]'>
      <div className='ml-auto mr-auto flex'>
        <span className={`self-center ${unformattedValue === 0 ? '' : 'hidden'}`}>
          <i className={`fa-light fa-minus mr-[18px] inline-block text-lg lg:text-4xl`}></i>
        </span>
        <span className={`self-center ${unformattedValue <= 0 ? 'hidden' : ''}`}>
          <i className={`fa-light fa-arrow-up-to-line mr-[18px] inline-block text-lg lg:text-4xl`}></i>
        </span>
        <span className={`self-center ${unformattedValue >= 0 ? 'hidden' : ''}`}>
          <i className={`fa-light fa-arrow-down-to-line mr-[18px] inline-block text-lg lg:text-4xl`}></i>
        </span>

        <div className='inline-flex flex-col justify-center'>
          <Title className={clsx('font-semibold', textSize)}>{updatedTitle}</Title>
          <Title as='h4' className='text-xl lg:text-4xl'>
            {value}
          </Title>
        </div>
      </div>
    </div>
  );
}
