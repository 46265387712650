import React from 'react';
import InlineTextLogo from '@/assets/images/omni-inline-text-logo.svg';
import { ReactComponent as GlobeIcon } from '@/assets/images/omni-globe.svg';
import { ReactComponent as EnvelopeIcon } from '@/assets/images/omni-envelope.svg';
import { ReactComponent as InfoCircleIcon } from '@/assets/images/omni-info-circle.svg';
import { Link } from 'react-router-dom';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className='relative flex flex-col justify-center p-12 flex-wrap text-white w-full max-w-[1550px] mx-auto text-xs leading-6 lg:justify-between lg:items-end lg:flex-row'>
      <div className='flex flex-col items-center text-center max-w-sm mx-auto lg:text-left lg:flex-row lg:items-end xl:mx-0 lg:max-w-full'>
        <img src={InlineTextLogo} className='h-[23px] max-w-[100%] mb-4 lg:mb-0' alt='Footer inline logo' />
        <span>&nbsp; is created by OMG Media Group. ©omni romi™ All rights reserved {currentYear}</span>
      </div>
      <nav className='flex flex-col items-center gap-2 mt-10 mx-auto lg:mt-4 xl:mx-0 lg:flex-row xl:mt-0'>
        <Link to='/terms' className='flex items-center gap-1'>
          <InfoCircleIcon />
          Terms & Conditions
        </Link>
        <a href='mailto:help@omniromi.com' className='flex items-center gap-1 mt-1 lg:mt-0 underline'>
          <EnvelopeIcon />
          help@omniromi.com
        </a>
        <a href='https://omniromi.com' target='__blank' className='flex items-center gap-1 mt-1 lg:mt-0 underline'>
          <GlobeIcon />
          omniromi.com
        </a>
      </nav>
    </div>
  );
}

type AppFooterType = { leftSection?: React.ReactNode; rightSection?: React.ReactNode };

export function AppFooter({ leftSection, rightSection }: AppFooterType) {
  return (
    <div className='flex justify-between items-center'>
      {leftSection ?? null}
      {rightSection ?? null}
    </div>
  );
}
