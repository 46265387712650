import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { updateOptimiseUserConfig } from '@/store/modelsSlice';
import { HashMap } from '@/types';
import { optimsieBudgetDataConst } from '@/utils/constants';
import React from 'react';

export function useOptimiseResult(payload: any) {
  const { spendCheck, optimisedSpendCheck, totalSpendCheck, spendSliderValue } = useAppSelector((state) => state.models.optimiseUserConfig);
  const dispatch = useAppDispatch();
  const changeInSpendCheckbox = () => {
    dispatch(
      updateOptimiseUserConfig({
        spendCheck: !spendCheck,
        optimisedSpendCheck,
        totalSpendCheck,
        spendSliderValue
      })
    );
  };

  const changeInOptimisedSpendCheckbox = () => {
    dispatch(
      updateOptimiseUserConfig({
        spendCheck,
        optimisedSpendCheck: !optimisedSpendCheck,
        totalSpendCheck,
        spendSliderValue
      })
    );
  };

  const changeInTotalSpendCheckbox = () => {
    dispatch(
      updateOptimiseUserConfig({
        spendCheck,
        optimisedSpendCheck,
        totalSpendCheck: !totalSpendCheck,
        spendSliderValue
      })
    );
  };

  const changeInSpendSlider = (num: number | number[]) => {
    dispatch(
      updateOptimiseUserConfig({
        spendCheck,
        optimisedSpendCheck,
        totalSpendCheck,
        spendSliderValue: num
      })
    );
  };

  const filterDataV3 = React.useMemo(() => {
    // @ts-ignore
    let changeInData = payload[optimsieBudgetDataConst[spendSliderValue]];

    changeInData = changeInData.map((element: HashMap) => {
      const totalSpend = totalSpendCheck ? element.total_spend : 0;

      return {
        ...element,
        current_spend: spendCheck ? element.current_spend : 0,
        optimal_spend: optimisedSpendCheck ? element.optimal_spend : 0,
        total_spend: totalSpend,
        is_negative_total_spend: element?.is_negative_total_spend,
        pos_total_spend: !element?.is_negative_total_spend ? totalSpend : 0,
        neg_total_spend: element?.is_negative_total_spend ? totalSpend : 0
      };
    });

    return changeInData;
  }, [payload, spendCheck, optimisedSpendCheck, spendSliderValue, totalSpendCheck]);

  const aggregateDataOptimisedV3 = React.useMemo(() => {
    // @ts-ignore
    const currentDataSet = payload[optimsieBudgetDataConst[spendSliderValue]];
    let totRevenue = 0,
      revenueUplift = 0,
      expanditureUplift = 0,
      sumOfOptimalContribution = 0,
      sumOfCurrentContribution = 0,
      sumOfOptimalSpend = 0,
      sumOfCurrentSpend = 0;

    currentDataSet.forEach((dataSet: HashMap) => {
      sumOfOptimalContribution += dataSet.optimal_contribution;
      sumOfCurrentContribution += dataSet.current_contribution;
      sumOfOptimalSpend += dataSet.optimal_spend;
      sumOfCurrentSpend += dataSet.current_spend;
    });

    totRevenue = sumOfOptimalContribution - sumOfCurrentContribution;

    revenueUplift = (sumOfOptimalContribution / sumOfCurrentContribution - 1) * 100;
    if (!optimisedSpendCheck || !spendCheck) {
    } else expanditureUplift = sumOfOptimalSpend - sumOfCurrentSpend;

    return { totRevenue, revenueUplift, expanditureUplift };
  }, [payload, spendSliderValue, spendCheck, optimisedSpendCheck]);

  return {
    changeInSpendSlider,
    changeInTotalSpendCheckbox,
    changeInOptimisedSpendCheckbox,
    changeInSpendCheckbox,
    spendCheck,
    optimisedSpendCheck,
    totalSpendCheck,
    spendSliderValue,
    filterDataV3,
    aggregateDataOptimisedV3
  };
}
