import React from 'react';
import { formatCurrency, formatNumber } from '@/utils/helpers';
import { Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { HashMap } from '@/types';
import { defaultLineColor } from '@/utils/constants';

type ComposeAreaLineChartType = {
  currentActiveShade: string;
  data: HashMap[];
};

export function ComposeAreaLineChart({ currentActiveShade, data }: ComposeAreaLineChartType) {
  const CustomTooltip = (props: any) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <>
          <div
            className='p-4 rounded-[8px]'
            style={{
              background: 'radial-gradient(86.82% 192.89% at 73.58% 28.49%, #373F45 0%, #566169 100%)'
            }}
          >
            <p className='text-white'>{`Date: ${props?.payload?.[0]?.payload?.date}`}</p>
            <p className='text-white flex flex-row items-center'>
              <svg width='14' height='14' className='mr-2 rounded-sm'>
                <rect width='14' height='14' fill={currentActiveShade} fillOpacity={1} stroke={currentActiveShade} strokeWidth={1} />
              </svg>
              {`Spend ${formatCurrency(props.payload[0].value, 0)}`}
            </p>
            {props.payload[1] && (
              <p className='flex flex-row items-center text-white'>
                <svg width='14' height='14' className='mr-2 rounded-sm'>
                  <rect width='14' height='14' fill={'#A6CEE3'} fillOpacity={1} stroke={'#A6CEE3'} strokeWidth={1} />
                </svg>
                {`Impressions ${formatNumber(props.payload[1].value, 0)}`}
              </p>
            )}
          </div>
        </>
      );
    }
    return null;
  };

  const AxisLabel = ({ axisType, x, y, width, height, children }: any) => {
    const isVert = axisType === 'yAxis';
    const rot = isVert ? -90 : 0;
    const cx = isVert ? -height / 2 - 40 : x + width / 2;
    const cy = isVert ? y + 45 : y + height / 2 + 100;

    return (
      <text x={cx} y={cy} transform={`rotate(${rot})`}>
        {children}
      </text>
    );
  };

  const RightAxisLabel = ({ axisType, x, y, width, height, children }: any) => {
    const isVert = axisType === 'yAxis';
    const rot = isVert ? 90 : 0;
    const cx = isVert ? height / 2 - 40 : x + width / 2;
    const cy = isVert ? y - height - 175 : y + height / 2 + 100;

    return (
      <text x={cx} y={cy} transform={`rotate(${rot})`}>
        {children}
      </text>
    );
  };

  return (
    <ResponsiveContainer className={`recharts-responsive-container-large`} width='100%' height='100%'>
      <ComposedChart
        data={data}
        margin={{
          bottom: 14
        }}
      >
        <defs>
          <linearGradient id='color' x1='0' x2='0' y1='0' y2='1'>
            <stop offset='0%' stopColor={currentActiveShade} stopOpacity='0.4'></stop>
            <stop offset='75%' stopColor={currentActiveShade} stopOpacity='0.05'></stop>
          </linearGradient>
        </defs>
        <Tooltip content={CustomTooltip} cursor={false} animationDuration={200} animationEasing='ease-out' />
        <CartesianGrid strokeWidth={1} stroke='#F0F4F9' vertical={false} horizontalPoints={[100, 200, 300]} />
        {/* @ts-ignore */}
        <XAxis dataKey='date' interval={1} tickLine={false} axisLine={false} tick={{ angle: -25 }} tickMargin={14} />
        <YAxis
          yAxisId='left'
          orientation='left'
          tick={false}
          axisLine={false}
          label={({ viewBox }: any) => (
            <AxisLabel axisType='yAxis' {...viewBox}>
              {'Impressions'}
            </AxisLabel>
          )}
        />
        <YAxis
          yAxisId='right'
          orientation='right'
          tick={false}
          axisLine={false}
          label={({ viewBox }: any) => (
            <RightAxisLabel axisType='yAxis' {...viewBox}>
              {'Spend'}
            </RightAxisLabel>
          )}
        />
        <Area
          type='monotone'
          yAxisId={'right'}
          dataKey='spend'
          stroke={currentActiveShade}
          strokeWidth={2}
          fill='url(#color)'
          dot={{ stroke: currentActiveShade, fill: currentActiveShade, strokeWidth: 1, r: 4, fillOpacity: 1 }}
        />
        <Line
          type='monotone'
          yAxisId='left'
          dataKey='impressions'
          stroke={defaultLineColor}
          strokeWidth={2}
          dot={{ fill: defaultLineColor, strokeWidth: 1, r: 4, fillOpacity: 1 }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
