import React from 'react';
import { ReactComponent as MainBgLogo } from '@/assets/images/omni-rotate-logo.svg';
import { ReactComponent as GlobLogo } from '@/assets/images/omni-glob.svg';
import { GButton } from '@/components/Common/Button';
import { Title } from '@/components/Common/Typography';
import { Card, CardHeaderIcon } from '@/components/Common/Card';
import { useNavigate } from 'react-router-dom';

export function WarningView() {
  const navigate = useNavigate();

  const redirectPrevStage = () => {
    navigate('/review-voice');
  };

  const redirectToSales = () => {
    navigate('/analysis-sales');
  };

  const icon = () => (
    <div className='relative'>
      <GlobLogo className='absolute -left-1' />
      <i className='relative text-[87px] fa-light fa-triangle-exclamation'></i>
    </div>
  );

  return (
    <React.Fragment>
      <div className='relative splash-wrapper h-full flex flex-col items-center justify-center overflow-hidden text-neutral-100'>
        <MainBgLogo
          className='absolute'
          style={{
            transform: `rotate(110deg)`,
            transition: 'transform 0.5s ease-in-out'
          }}
        />
        <div className='bg-white relative w-[417px] h-[380px] rounded-full flex justify-center items-center'>
          <Card className='flex-1'>
            <CardHeaderIcon icon={icon} />
            <Title as='h4' className='card-title-text'>
              Warning
            </Title>
            <p className='text-base tracking-[0.03em] min-h-[151px]'>
              You are about to go back to data validation.
              <br />
              <br />
              Any changes you make will need to be remodelled.
            </p>
            <div className='flex flex-col gap-2'>
              <GButton type='button' variant='secondary' size='sm' isDanger className='text-lg justify-end' onClick={redirectPrevStage}>
                Confirm - Go To Data Validation
              </GButton>
              <GButton type='button' className='text-base font-semibold justify-end' onClick={redirectToSales}>
                Cancel
              </GButton>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}
