import { channelGroups, channelNamesList, channelNameSortedList, dateFormatForClient } from './constants';
import { format, parseISO } from 'date-fns';
import sortBy from 'lodash/sortBy';
import toNumber from 'lodash/toNumber';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import { HashMap } from '@/types';
import { cloneDeep } from 'lodash';
import { formatLabelAcronymsWithLaterChars, formatTitleCase } from './helpers';

/* Main API mapping logic start's here */

const trailingNumbersToBeRemoved = 1000;
const rollingMonthDuration = 24;

export const mapAllMeasurableAndSpends = (apiData: HashMap[]) => {
  let channelSpend: HashMap = {}, // also called measurable spend
    totalChannelSpend: HashMap = {},
    channelImpression: HashMap = {};
  let aggregated_spend: HashMap[] = [];
  let measurable_spend = 0,
    unmeasurable_spend = 0; // also called total spend

  apiData.forEach((spend: HashMap) => {
    const keyNameList = Object.keys(spend);

    keyNameList.forEach((key) => {
      const channelKey = channelNamesList.find((channelKey) => `${channelKey}_spend` === key);
      const channelTotalKey = channelNamesList.find((channelKey) => `total_${channelKey}_spend` === key);
      const channelImpressionKey = channelNamesList.find((channelKey) => `${channelKey}_impressions` === key);

      // Spend
      if (channelKey) {
        if (!channelSpend[channelKey]) {
          channelSpend[channelKey] = toNumber(spend[channelKey + '_spend'] || '0');
        } else {
          channelSpend[channelKey] += toNumber(spend[channelKey + '_spend'] || '0');
        }
      }

      // Total Spend
      if (channelTotalKey) {
        if (!totalChannelSpend[channelTotalKey]) {
          totalChannelSpend[channelTotalKey] = toNumber(spend['total_' + channelTotalKey + '_spend'] || '0');
        } else {
          totalChannelSpend[channelTotalKey] += toNumber(spend['total_' + channelTotalKey + '_spend'] || '0');
        }
      }

      // Impression
      if (channelImpressionKey) {
        if (!channelImpression[channelImpressionKey]) {
          channelImpression[channelImpressionKey] = toNumber(spend[channelImpressionKey + '_impressions'] || '0');
        } else {
          channelImpression[channelImpressionKey] += toNumber(spend[channelImpressionKey + '_impressions'] || '0');
        }
      }
    });
  });

  channelNamesList.forEach((name) => {
    if (channelSpend[name] !== undefined || totalChannelSpend[name] !== undefined) {
      aggregated_spend.push({
        name: formatTitleCase(name),
        spend: round(channelSpend[name] / trailingNumbersToBeRemoved, 0), // measurable spend
        total_spend: round(totalChannelSpend[name] / trailingNumbersToBeRemoved, 0), // total spend
        impression: round(channelImpression[name] / trailingNumbersToBeRemoved, 0), // impression
        raw_spend: round(channelSpend[name], 0),
        raw_total_spend: round(totalChannelSpend[name], 0),
        raw_impression: round(channelImpression[name], 0)
      });
    }
  });

  const { measurable_total_spend, unmeasurable_total_spend } = aggregated_spend.reduce(
    (acc: HashMap, channel: HashMap) => {
      return {
        measurable_total_spend: acc.measurable_total_spend + channel.spend,
        unmeasurable_total_spend: acc.unmeasurable_total_spend + channel.total_spend
      };
    },
    { measurable_total_spend: 0, unmeasurable_total_spend: 0 }
  );

  measurable_spend = round((measurable_total_spend / unmeasurable_total_spend) * 100);
  unmeasurable_spend = round(((unmeasurable_total_spend - measurable_total_spend) / unmeasurable_total_spend) * 100);

  aggregated_spend = aggregated_spend.map((singleSpend: HashMap) => {
    return {
      ...singleSpend,
      spend_percent: round((singleSpend.spend / measurable_total_spend) * 100, 1)
    };
  });

  /* Aggregation with date as source */

  const monthYearData = new Map();
  let monthChannelDataAggregation = [];
  const apiDataWithSortedDate = sortBy(apiData, function (dateObj) {
    return new Date(dateObj.date);
  });

  try {
    const availableChannel = {};
    Object.keys(apiData?.[0] ?? {}).forEach((name) => {
      const channelName = channelNamesList.find((channel) => `${channel}_spend` === name);
      if (channelName) {
        availableChannel[channelName] = {};
      }
    });

    apiDataWithSortedDate.forEach((data) => {
      const date = parseISO(data.date);

      const monthYearFormat = format(date, 'MMM-yyyy');

      const currentAllChannelData = updateChannelWithSpendAndImpressionInNumFormat(data, availableChannel);
      if (!monthYearData.get(monthYearFormat)) {
        monthYearData.set(monthYearFormat, currentAllChannelData);
      } else {
        const prevChannelData = updateChannelWithSpendAndImpressionInNumFormat(monthYearData.get(monthYearFormat), availableChannel);

        monthYearData.set(
          monthYearFormat,
          sumUpChannelPreviousAndCurrentSpendImpressionData(prevChannelData, currentAllChannelData, availableChannel)
        );
      }
    });

    Object.keys(availableChannel).forEach((ckey) => {
      const payload: HashMap = {};
      payload.channel = formatTitleCase(ckey);
      payload.data = [];

      monthYearData.forEach((value, key) => {
        if (payload.data.length < rollingMonthDuration) {
          payload.data.push({
            date: key,
            spend: round(value[`${ckey}_spend`] / trailingNumbersToBeRemoved, 0),
            impressions: round(value[`${ckey}_impressions`] / trailingNumbersToBeRemoved, 0)
          });
        }
      });

      monthChannelDataAggregation.push(payload);

      monthChannelDataAggregation = monthChannelDataAggregation.filter((channelData) => {
        const allZerosInChannel = channelData.data?.every((data) => data.spend === 0 && data.impressions === 0);

        return !allZerosInChannel;
      });
    });
  } catch (error) {
    console.log('ERROR on aggregation part of date: ', error);
  }

  aggregated_spend = filterOutZeroValueFromSpend(aggregated_spend);

  const spend_percentage = [
    {
      name: 'Included',
      value: measurable_spend,
      rawValue: measurable_total_spend
    },
    {
      name: 'Not Included',
      value: unmeasurable_spend,
      /* According to the feedback, the unmeasurable dollar value is diff between total and measurable spend */
      rawValue: unmeasurable_total_spend - measurable_total_spend
    }
  ];

  const firstKpiDate = apiData?.[0]?.date ? format(parseISO(apiData?.[0]?.date), dateFormatForClient) : '';
  const lastKpiDate = apiData?.[apiData?.length - 1]?.date
    ? format(parseISO(apiData?.[apiData?.length - 1]?.date), dateFormatForClient)
    : '';

  aggregated_spend = sortBy(aggregated_spend, (item) => {
    return channelNameSortedList.indexOf(item.name);
  });

  monthChannelDataAggregation = sortBy(monthChannelDataAggregation, (item) => {
    return channelNameSortedList.indexOf(item.channel);
  });

  console.log('aggregated_spend: ', aggregated_spend);
  console.log('spend_percentage: ', spend_percentage);
  console.log('monthChannelDataAggregation: ', monthChannelDataAggregation);

  return {
    aggregated_spend,
    spend_percentage,
    measurable_total_spend, // measurable spend
    unmeasurable_total_spend, // total spend
    monthChannelDataAggregation,
    firstKpiDate,
    lastKpiDate
  };
};

export const mapMediaChannelModelValidation = (apiData: HashMap[]) => {
  const validatedMedia = [];

  apiData.forEach((media: HashMap) => {
    let channel_name = media.channel.replace('_impressions', '').replace('_spend', '');
    if (channelNamesList.includes(channel_name)) {
      validatedMedia.push({
        ...media,
        isPass: media.valid,
        hasSpend: media.has_media_spend,
        zeroValues: round(toNumber(media.zero_values) * 100),
        sd: media.standard_deviation,
        isNun: media.contains_no_nulls,
        name: formatLabelAcronymsWithLaterChars(media.channel)
      });
    }
  });

  console.log('Channel Validation: ', validatedMedia);

  return validatedMedia;
};

export const mapCompetitorSpendValidation = (apiData: HashMap[]) => {
  const comSpendsMap = {};
  apiData.forEach((data) => {
    if (!comSpendsMap?.[data.competitor]) {
      comSpendsMap[data.competitor] = toNumber(data.spend);
    } else {
      comSpendsMap[data.competitor] = comSpendsMap[data.competitor] + toNumber(data.spend);
    }
  });

  let compSpendsArr = [];
  Object.keys(comSpendsMap).forEach((data) => {
    compSpendsArr.push({
      name: data,
      spend: comSpendsMap[data]
    });
  });

  compSpendsArr = orderBy(compSpendsArr, ['spend'], ['desc']);

  /* Other aggregation */
  if (compSpendsArr.length > 5) {
    let otherCompSpendArr = [];
    for (let index = 0; index < compSpendsArr.length; index++) {
      if (index >= 5) {
        if (!otherCompSpendArr[5]) {
          otherCompSpendArr[5] = {
            name: 'Other',
            spend: compSpendsArr[index].spend
          };
        } else {
          otherCompSpendArr[5] = {
            name: 'Other',
            spend: otherCompSpendArr[5].spend + compSpendsArr[index].spend
          };
        }
      } else {
        otherCompSpendArr.push(compSpendsArr[index]);
      }
    }

    otherCompSpendArr = mapCompetitorSpendTrailing(otherCompSpendArr);

    return otherCompSpendArr;
  }

  compSpendsArr = mapCompetitorSpendTrailing(compSpendsArr);

  return compSpendsArr;
};

export const mapModelledRoi = (apiData: HashMap[]) => {
  let modelledResultData = [];
  const apiDataClone = cloneDeep(apiData);

  console.log('ROI API data: ', apiDataClone);

  let total_spend_proportion = 0;
  let total_sales_proportion = 0;

  modelledResultData = apiDataClone.filter((element) => {
    if (element.group === channelGroups.MEDIA) {
      element.name = formatTitleCase(element.channel);
      element.roi = Number(Number(element.roi).toFixed(2));
      element.raw_roi = Number(element.roi);
      element.spend = Number((Number(element.spend) / trailingNumbersToBeRemoved).toFixed(0));
      element.contribution = Number((Number(element.contribution) / trailingNumbersToBeRemoved).toFixed(0));
      element.raw_spend = Number(element.spend);
      element.raw_contribution = Number(element.contribution);
      total_spend_proportion = total_spend_proportion + Number(element.spend);
      total_sales_proportion = total_sales_proportion + Number(element.contribution);
      return true;
    }
    return false;
  });

  let mappedBubbleData = modelledResultData.map((bubbleData) => {
    let spend_proportion_in_percentage = (bubbleData.raw_spend / total_spend_proportion) * 100;
    let sales_proportion_in_percentage = (bubbleData.raw_contribution / total_sales_proportion) * 100;

    return {
      channel: formatTitleCase(bubbleData.channel),
      spend_proportion: round(toNumber(spend_proportion_in_percentage), 0),
      sales_proportion: round(toNumber(sales_proportion_in_percentage), 0),
      total_contribution: round((toNumber(sales_proportion_in_percentage) / toNumber(spend_proportion_in_percentage)) * 100, 0)
    };
  });

  let externalSalesContribution = [];
  let mediaSalesContribution = [];

  apiData.forEach((data) => {
    if (data.group === channelGroups.EXTERNAL) {
      externalSalesContribution.push(data);
    }
    if (data.group === channelGroups.MEDIA) {
      mediaSalesContribution.push(data);
    }
  });

  mediaSalesContribution = mediaSalesContribution.map((data) => ({
    ...data,
    channel: formatTitleCase(data.channel),
    percent_contri_spend: round(toNumber(data.perc) * 100, 1)
  }));

  externalSalesContribution = externalSalesContribution.map((data) => ({
    ...data,
    channel: formatTitleCase(data.channel),
    percent_contri_spend: round(toNumber(data.perc) * 100, 2)
  }));

  modelledResultData = sortBy(modelledResultData, (item) => {
    return channelNameSortedList.indexOf(item.name);
  });

  mediaSalesContribution = sortBy(mediaSalesContribution, (item) => {
    return channelNameSortedList.indexOf(item.channel);
  });

  externalSalesContribution = sortBy(externalSalesContribution, (item) => {
    return channelNameSortedList.indexOf(item.channel);
  });

  mappedBubbleData = sortBy(mappedBubbleData, (item) => {
    return channelNameSortedList.indexOf(item.channel);
  });

  return { modelledResultData, mediaSalesContribution, externalSalesContribution, mappedBubbleData };
};

export const mapYearlyMMMLiteBudget = (apiData: HashMap[]) => {
  let yearly_values: HashMap = {};

  apiData.forEach((yearData: HashMap) => {
    let name = channelNamesList.find((cName) => yearData.media_channels.startsWith(cName));
    if (name) {
      name = formatTitleCase(name);
      const optimalSpend = round(toNumber(yearData['optimal_spend']) / trailingNumbersToBeRemoved, 0);
      const currentSpend = round(toNumber(yearData['current_spend']) / trailingNumbersToBeRemoved, 0);
      const isNegativeTotalSpend = optimalSpend - currentSpend < 0;
      const totalSpend = Math.abs(optimalSpend - currentSpend);

      if (!yearly_values[yearData['total_budget_change']]) {
        yearly_values[yearData['total_budget_change']] = [
          {
            name,
            current_spend: currentSpend,
            current_contribution: round(toNumber(yearData['current_contribution'] / trailingNumbersToBeRemoved), 0),
            optimal_spend: optimalSpend,
            optimal_contribution: round(toNumber(yearData['optimal_contribution']) / trailingNumbersToBeRemoved, 0),
            total_spend: totalSpend,
            is_negative_total_spend: isNegativeTotalSpend
          }
        ];
      } else {
        yearly_values[yearData['total_budget_change']].push({
          name,
          current_spend: currentSpend,
          current_contribution: round(toNumber(yearData['current_contribution'] / trailingNumbersToBeRemoved), 0),
          optimal_spend: optimalSpend,
          optimal_contribution: round(toNumber(yearData['optimal_contribution']) / trailingNumbersToBeRemoved, 0),
          total_spend: totalSpend,
          is_negative_total_spend: isNegativeTotalSpend
        });
      }
    }
  });

  Object.keys(yearly_values).forEach((key) => {
    if (yearly_values[key]) {
      yearly_values[key] = sortBy(yearly_values[key], (item) => {
        return channelNameSortedList.indexOf(item.name);
      });
    }
  });

  return { yearly_values };
};

export const mapMMMLiteDecomp = (apiData: HashMap[]) => {
  const latestApiData = apiData.reverse();

  const yearMonthMap = new Map();

  latestApiData.forEach((field) => {
    const date = parseISO(field.date);
    const yearMonthFormat = format(date, 'MMM-yyy');

    if (yearMonthMap.has(yearMonthFormat)) {
      yearMonthMap.set(yearMonthFormat, {
        brand: yearMonthMap.get(yearMonthFormat)?.brand + toNumber(field.brand),
        media: yearMonthMap.get(yearMonthFormat)?.media + toNumber(field.media),
        base: yearMonthMap.get(yearMonthFormat)?.base + toNumber(field.base)
      });
    } else {
      yearMonthMap.set(yearMonthFormat, {
        brand: toNumber(field.brand),
        media: toNumber(field.media),
        base: toNumber(field.base)
      });
    }
  });

  let loopCounter = 0;
  const finalYearMap = new Map();

  yearMonthMap.forEach((valueObj) => {
    if (loopCounter < 12) {
      if (finalYearMap.has('Year 2')) {
        finalYearMap.set('Year 2', {
          brand: finalYearMap.get('Year 2').brand + valueObj.brand,
          media: finalYearMap.get('Year 2').media + valueObj.media,
          base: finalYearMap.get('Year 2').base + valueObj.base
        });
      } else {
        finalYearMap.set('Year 2', {
          brand: valueObj.brand,
          media: valueObj.media,
          base: valueObj.base
        });
      }
    } else if (loopCounter < 24) {
      if (finalYearMap.has('Year 1')) {
        finalYearMap.set('Year 1', {
          brand: finalYearMap.get('Year 1').brand + valueObj.brand,
          media: finalYearMap.get('Year 1').media + valueObj.media,
          base: finalYearMap.get('Year 1').base + valueObj.base
        });
      } else {
        finalYearMap.set('Year 1', {
          brand: valueObj.brand,
          media: valueObj.media,
          base: valueObj.base
        });
      }
    }

    loopCounter = loopCounter + 1;
  });

  console.log('Major Contribution year map: ', finalYearMap);

  let yearOneObj = {};
  let yearTwoObj = {};

  finalYearMap.forEach((value, key) => {
    if (key === 'Year 1') {
      const total = value?.brand + value?.media + value?.base;
      yearOneObj = {
        name: key,
        brand: round((value.brand / total) * 100, 0),
        media: round((value.media / total) * 100, 0),
        base: round((value.base / total) * 100, 0)
      };
    } else if (key === 'Year 2') {
      const total = value?.brand + value?.media + value?.base;
      yearTwoObj = {
        name: key,
        brand: round((value.brand / total) * 100, 0),
        media: round((value.media / total) * 100, 0),
        base: round((value.base / total) * 100, 0)
      };
    }
  });

  const year_list_contribution = [!isEmpty(yearOneObj) && yearOneObj, !isEmpty(yearTwoObj) && yearTwoObj].filter((value) => value);

  console.log('Major Contributors To Sales: ', year_list_contribution);

  return year_list_contribution;
};

export const mapBubbleData = (apiData: HashMap[]) => {
  const data = apiData.map((bubbleData) => {
    return {
      channel: bubbleData.channel_name,
      spend_proportion: round(toNumber(bubbleData.spend_proportion), 2),
      sales_proportion: round(toNumber(bubbleData.sales_proportion), 2),
      total_contribution: round((toNumber(bubbleData.sales_proportion) / toNumber(bubbleData.spend_proportion)) * 100, 2)
    };
  });

  return data;
};

export const mapShareOfVoiceData = (apiData: HashMap[]) => {
  const quarterTitles = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'];

  console.log('Share of voice: ', apiData);

  const data = apiData.map((sovData) => {
    const quarterWithYear = sovData.quarter.split('.');
    let year = quarterWithYear?.[0].slice(-2);
    let sovTitle = '';

    switch (quarterWithYear[1]) {
      case 'Q1':
        sovTitle = `${quarterTitles[0]} '${year}`;
        break;

      case 'Q2':
        sovTitle = `${quarterTitles[1]} '${year}`;
        break;

      case 'Q3':
        sovTitle = `${quarterTitles[2]} '${year}`;
        break;

      case 'Q4':
        sovTitle = `${quarterTitles[3]} '${year}`;
        break;
    }

    return {
      name: sovTitle,
      sov: round(toNumber(sovData.sov) * 100, 2),
      sales: toNumber(round(sovData.total_kpi / trailingNumbersToBeRemoved, 0))
    };
  });

  return data;
};

/* Helper */

const updateChannelWithSpendAndImpressionInNumFormat = (data: HashMap, channelList: HashMap) => {
  let resultPayload = {};
  Object.keys(channelList).forEach((key) => {
    resultPayload = {
      ...resultPayload,
      [`${key}_spend`]: toNumber(data[`${key}_spend`] ?? 0),
      [`${key}_impressions`]: toNumber(data[`${key}_impressions`] ?? 0)
    };
  });

  return resultPayload;
};

const sumUpChannelPreviousAndCurrentSpendImpressionData = (prevData: HashMap, currentData: HashMap, channelList: HashMap) => {
  let resultPayload = {};
  Object.keys(channelList).forEach((key) => {
    resultPayload = {
      ...resultPayload,
      [`${key}_spend`]: prevData[`${key}_spend`] + currentData[`${key}_spend`],
      [`${key}_impressions`]: prevData[`${key}_impressions`] + currentData[`${key}_impressions`]
    };
  });

  return resultPayload;
};

export const filterOutZeroValueFromSpend = (data: HashMap[]) => {
  return data.filter((element) => element.spend !== 0 && element.total_spend !== 0);
};

const mapCompetitorSpendTrailing = (channelSpends) => {
  return channelSpends.map((data) => ({ ...data, spend: round(data.spend / trailingNumbersToBeRemoved, 0) }));
};

export const pickMaxNegativeValue = (data: HashMap[], targetKey: string) => {
  let maxNegativeValue = 0;
  data?.forEach((entry) => {
    if (entry[targetKey] < maxNegativeValue) {
      maxNegativeValue = entry[targetKey];
    }
  });

  return maxNegativeValue;
};
