/* eslint-disable no-cond-assign */

export const downloadCsvFork = (csvFile, filename = 'export.csv') => {
  if (!csvFile) {
    console.log('the file is null');
    return;
  }

  const client = detectionClient();
  const bomCode = '\ufeff';
  let text = `data:attachment/csv;charset=utf-8,${bomCode}${encodeURIComponent(csvFile)}`;

  if (window.Blob && window.URL && window.URL.createObjectURL) {
    const csvData = new Blob([bomCode + csvFile], { type: 'text/csv' });
    text = URL.createObjectURL(csvData);
  }

  if (client.name === 'IE') {
    const oWin = window.top.open('about:blank', '_blank');
    oWin.document.write(`sep=,\r\n${csvFile}`);
    oWin.document.close();
    oWin.document.execCommand('SaveAs', true, filename);
    oWin.close();
    return;
  }

  if (client.name === 'Safari') {
    const link = document.createElement('a');
    link.id = 'csvDwnLink';
    document.body.appendChild(link);

    const csv = bomCode + csvFile;
    const csvData = 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(csv);

    document.getElementById('csvDwnLink').setAttribute('href', csvData);
    document.getElementById('csvDwnLink').setAttribute('download', filename);
    document.getElementById('csvDwnLink').click();

    document.body.removeChild(link);

    return;
  }

  if (client.name === 'Firefox') {
    const a = document.createElement('a');
    a.download = filename;
    a.target = '_blank';
    a.href = text;

    const event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    a.dispatchEvent(event);
    return;
  }

  // chrome and other browsers
  const a = document.createElement('a');
  a.download = filename;
  a.href = text;
  a.click();
};

const detectionClient = () => {
  const Sys: any = {};
  const ua = navigator.userAgent.toLowerCase();
  let s;

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  (s = ua.match(/msie ([\d.]+)/))
    ? (Sys.ie = s[1])
    : (s = ua.match(/firefox\/([\d.]+)/))
    ? (Sys.firefox = s[1])
    : (s = ua.match(/chrome\/([\d.]+)/))
    ? (Sys.chrome = s[1])
    : (s = ua.match(/opera.([\d.]+)/))
    ? (Sys.opera = s[1])
    : (s = ua.match(/version\/([\d.]+).*safari/))
    ? (Sys.safari = s[1])
    : 0;

  // 以下进行测试
  if (Sys.ie) return { name: 'IE', version: Sys.ie };
  if (Sys.firefox) return { name: 'Firefox', version: Sys.firefox };
  if (Sys.chrome) return { name: 'Chrome', version: Sys.chrome };
  if (Sys.opera) return { name: 'Opera', version: Sys.opera };
  if (Sys.safari) return { name: 'Safari', version: Sys.safari };
  return { name: '' };
};
