import React from 'react';
import clsx from 'clsx';
import Tippy from '@tippyjs/react/headless';
import 'tippy.js/dist/tippy.css';
import { ReactComponent as GlobeIcon } from '@/assets/images/omni-globe.svg';
import { ReactComponent as EnvelopeIcon } from '@/assets/images/omni-envelope.svg';
import { ReactComponent as QuestionMarkLogo } from '@/assets/images/omni-question-circle.svg';
import { GButton, IButton } from './Button';
import { Text, Title } from './Typography';
import { AppLink } from './Link';
import { Divider } from './Spacer';
import { useAppSelector } from '@/store/hooks';
import { flickHelpTooltip } from '@/store/modelsSlice';
import { useDispatch } from 'react-redux';

type TooltipType = { primary?: boolean; title: string; description: string | React.ReactNode };

export function HelpTooltip({ primary, title, description }: TooltipType) {
  const dispatch = useDispatch();
  const externalOpenToggle = useAppSelector((state) => state.models.isHelpTooltipOpen);

  const handleToggleVisible = () => {
    dispatch(flickHelpTooltip());
  };

  return (
    <Tippy
      render={(attrs) => (
        <div className='omni-help-tooltip' tabIndex={-1} {...attrs}>
          <div className='omni-arrow-tooltip'>
            <div className='tooltip-triangle-bg-lg'></div>
          </div>
          <div className='p-[40px]'>
            <div className='min-w-[272px] max-w-[272px] overflow-auto max-h-[calc(100vh-215px)]'>
              <div className='flex items-start justify-between'>
                <GButton
                  className={clsx('border rounded-[50px] h-[30px] px-2 py-[7px] font-semibold', 'text-neutral-100')}
                  noShade
                  suffix={<QuestionMarkLogo />}
                  size='xs'
                >
                  Help
                </GButton>
                <IButton className='px-[15px] py-[15px] -translate-y-3' onClick={handleToggleVisible}>
                  <i className='fa-light fa-xmark text-2xl'></i>
                </IButton>
              </div>
              <Title as='h5' className='mt-5 mb-7'>
                {title}
              </Title>
              <Text className='mb-7'>{description}</Text>
              <AppLink className='mb-2 underline' href='mailto:help@omniromi.com'>
                <EnvelopeIcon />
                help@omniromi.com
              </AppLink>
              <AppLink className='mb-7 underline' href='https://omniromi.com' target='__blank'>
                <GlobeIcon />
                omniromi.com
              </AppLink>
              <Divider />
              <Text className='mt-7'>©omni romi™ is created by OMG Media Group. All rights reserved 2022</Text>
            </div>
          </div>
        </div>
      )}
      placement='bottom-end'
      visible={externalOpenToggle}
    >
      <button onClick={handleToggleVisible} className={clsx('demo', primary ? 'help-tooltip-primary' : 'help-tooltip-default')}>
        <QuestionMarkLogo />
        Help
      </button>
    </Tippy>
  );
}
