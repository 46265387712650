import React from 'react';
import { ReactComponent as MainBgLogo } from '@/assets/images/omni-rotate-logo.svg';
import { GButton } from '@/components/Common/Button';
import { ReactComponent as GlobLogo } from '@/assets/images/omni-glob.svg';
import { Title } from '@/components/Common/Typography';
import { Card, CardHeaderIcon } from '@/components/Common/Card';
import { LoadingSection } from '@/components/Common/Loading';
import { useNavigate } from 'react-router-dom';

export function OptimiseConfirmView() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = React.useState(false);

  const redirectNextStage = () => {
    setLoading(true);
  };

  const handleLoadingComplete = React.useCallback(() => {
    navigate('/result-dashboard-confirm');
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const icon = () => (
    <div className='relative'>
      <GlobLogo className='absolute -left-1' />
      <i className='relative text-[87px] fa-light fa-chart-mixed'></i>
    </div>
  );

  return (
    <LoadingSection isLoading={isLoading} onLoadingComplete={handleLoadingComplete}>
      <div className='relative splash-wrapper h-full flex flex-col items-center justify-center overflow-hidden text-neutral-100'>
        <MainBgLogo
          className='absolute'
          style={{
            transform: `rotate(110deg)`,
            transition: 'transform 0.5s ease-in-out'
          }}
        />
        <div className='bg-white relative w-[380px] h-[380px] rounded-full flex justify-center items-center'>
          <Card>
            <CardHeaderIcon icon={icon} />
            <Title as='h4' className='card-title-text'>
              Results Dashboard
            </Title>
            <p className='text-base tracking-[0.03em] min-h-[151px]'>
              Giving you and your clients confidence by analysing the return on your media investments and predicting your future optimal
              media mix
            </p>
            <div className='flex flex-col gap-2'>
              <GButton type='button' bgGradient='gray' className='text-lg justify-end' onClick={redirectNextStage}>
                View results
              </GButton>
              <GButton type='button' className='text-base font-semibold justify-end' onClick={() => navigate('/optimise-result')}>
                Back to optimisation
              </GButton>
            </div>
          </Card>
        </div>
      </div>
    </LoadingSection>
  );
}
