import React, { Fragment, useState, useMemo } from 'react';
import clsx from 'clsx';
import { Combobox, Transition } from '@headlessui/react';
import { ReactComponent as AngleDown } from '@/assets/images/omni-angle-down.svg';
import { IClientType } from '@/types';

export type AutoCompleteType = {
  data: IClientType[];
  size?: 'lg' | 'md' | 'sm';
  className?: string;
  placeholder?: string;
  handleSelect?: Function;
  loading?: boolean;
};

export function AutoComplete({ data, loading, size = 'lg', className, placeholder, handleSelect }: AutoCompleteType) {
  const [selected, setSelected] = useState('');
  const [query, setQuery] = useState('');

  const filterData =
    query === ''
      ? data
      : data?.filter((person) => person.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')));

  const sizeParams = useMemo(() => {
    let className = '';
    if (size === 'lg') {
      className += 'w-full';
    } else if (size === 'md') {
      className += 'w-[203]';
    } else {
      className += 'w-[103]';
    }

    return className;
  }, [size]);

  React.useEffect(() => {
    if (selected) {
      handleSelect?.(selected);
    }
  }, [selected, handleSelect]);

  return (
    <div className={clsx(sizeParams, className)}>
      <Combobox value={selected} onChange={setSelected}>
        <div className='relative mt-1'>
          <div className='relative w-full cursor-default rounded-md overflow-hidden bg-white text-left focus:outline-none text-neutral-100 sm:text-sm text-base border border-neutral-100 focus-within:border-input-text hover:border-primary-50 hover:text-primary-50 focus-within:ring-2'>
            <Combobox.Input
              className='w-full h-[62px] rounded-md py-2 pl-3 pr-10 leading-[23px] focus:outline-none focus:text-input-text hover:bg-input-bg'
              placeholder={loading ? 'Loading...' : placeholder ?? 'Type and select client'}
              displayValue={(person: { id: number; name: string }) => person.name}
              onChange={(event) => setQuery(event.target.value)}
              disabled={loading}
            />
            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center px-4'>
              {/* TODO: updating the loading state */}
              <AngleDown className='w-[14px] h-[16px]' />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className='absolute mt-1 max-h-64 w-full text-neutral-100 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {filterData?.length === 0 && query !== '' ? (
                <div className='relative cursor-default select-none py-2 px-4'>Nothing found.</div>
              ) : (
                filterData?.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none rounded-md py-4 px-4 hover:bg-input-bg hover:text-primary-50 combobox-option ${
                        active ? 'text-primary-50' : ''
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <Fragment>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{person.name}</span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'}`}
                          ></span>
                        ) : null}
                      </Fragment>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
