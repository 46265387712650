import React from 'react';
import clsx from 'clsx';
import { creatCsvFile } from 'download-csv';
import { downloadCsvFork } from '@/utils/downloadCsv';
import { toast } from 'react-toastify';
import { AppButtonWrapper, AppLayout } from '@/components/Layout/DesignLayout';
import { AutoComplete } from '@/components/Common/Autocomplete';
import { Card, CardHeaderIcon } from '@/components/Common/Card';
import { ReactComponent as UserGlob } from '@/assets/images/omni-user-glob.svg';
import { ReactComponent as UploadGlob } from '@/assets/images/omni-upload-glob.svg';
import { Title } from '@/components/Common/Typography';
import { GButton, SButton } from '@/components/Common/Button';
import { FileUpload } from '@/components/Common/FileUpload';
import { useNavigate } from 'react-router-dom';
import { LoadingSection } from '@/components/Common/Loading';
import { cardHeight } from '@/utils/constants';
import { fetchClients, updateCurrentClientData, uploadCsvKpi } from '@/store/modelsSlice';
import { SuccesToastMessage } from '@/components/Common/Toast';
import { getDatesBetween, substractYearDateFromCurrent } from '@/utils/helpers';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { ReactComponent as GlobLogo } from '@/assets/images/omni-glob.svg';
import { isEmpty } from 'lodash';
import { useClearCurrentSession } from '@/components/Common/hook/session';
import { Modal } from '@/components/Common/Modal';

export function UploadView() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = React.useState(false);
  const [client, setClient] = React.useState<any>(undefined);
  const [clientCsv, setClientCsv] = React.useState<any>(undefined);
  const [modalOpen, setModalOpen] = React.useState(false);
  const allClients = useAppSelector((state) => state.models.clients);
  const isLoadingAllClients = useAppSelector((state) => state.models.clientLoadingState);
  const curSessionId = useAppSelector((state) => state.models.curSessionId);
  const measurableSpend = useAppSelector((state) => state.models.measurableSpend);
  const reviewStageLoading = useAppSelector((state) => state.models.reviewStageLoading);

  const dispatch = useAppDispatch();
  const headerData = React.useMemo(() => {
    return {
      title: 'Getting Started',
      currentState: 'Upload',
      subtleText: !Boolean(client) ? 'Select your client' : 'Upload KPI Data',
      helpTooltipProps: {
        title: 'Select your client and upload their KPI data',
        description: (
          <p>
            Make sure to follow the KPI Template provided and follow these rules for your CSV upload:
            <ul className='mt-2 list-disc pl-5'>
              <li>Date has to be equal or later than 2020-01-01 and follow YYYY-MM-DD format</li>
              <li>Provide the daily data</li>
              <li>Min. 365-day time period</li>
            </ul>
          </p>
        )
      }
    };
  }, [client]);

  useClearCurrentSession();

  React.useEffect(() => {
    dispatch(fetchClients());

    return () => setLoading(false);
  }, [dispatch]);

  React.useEffect(() => {
    if (curSessionId && !reviewStageLoading && isEmpty(measurableSpend.aggregated_spend) && clientCsv) {
      setLoading(false);
    }

    if (curSessionId && !isEmpty(measurableSpend.aggregated_spend) && clientCsv) {
      navigate(`/review-validate?session_id=${curSessionId}`);
      setLoading(false);
    }
  }, [curSessionId, measurableSpend, navigate, reviewStageLoading, clientCsv]);

  const callApiForData = React.useCallback(() => {
    dispatch(uploadCsvKpi(clientCsv))
      .unwrap()
      .catch((error: any) => {
        console.log('ERROR on adding the CSV file: ', error);
        if (error.status === 400) {
          setModalOpen(true);
        }
        setLoading(false);
      });
  }, [dispatch, clientCsv]);

  const redirectNextStage = () => {
    setLoading(true);
  };

  const handleLoadingComplete = () => {};

  const handleSamePage = () => {
    setModalOpen(false);
  };

  const modalIcon = () => (
    <div className='relative'>
      <GlobLogo className='absolute -left-1' />
      <i className='relative text-[87px] text-neutral-100 fa-thin fa-triangle-exclamation'></i>
    </div>
  );

  const handleClientSelect = React.useCallback(
    (data: any) => {
      setClient(data);
      dispatch(
        updateCurrentClientData({
          id: data.id,
          name: data.name
        })
      );
    },
    [dispatch]
  );

  const handleDownloadKPITemplate = () => {
    const columns = {
      client_id: 'client_id',
      client_name: 'client_name',
      date: 'date',
      kpi: 'kpi',
      web_traffic: 'web_traffic'
    };
    const exportFileName = 'sample_input_template.csv';
    const initialDate = substractYearDateFromCurrent(new Date(), 2);
    const listOfDates = getDatesBetween(initialDate, new Date());
    const data = listOfDates.map((date) => ({
      client_id: client?.id,
      client_name: client?.name || 'N/A',
      date,
      kpi: '',
      web_traffic: ''
    }));
    const csvFile = creatCsvFile(data, columns);
    downloadCsvFork(csvFile, exportFileName);

    toast(<SuccesToastMessage title='KPI Template successfully downloaded' />, {});
  };

  return (
    <LoadingSection isLoading={isLoading} onLoadingComplete={handleLoadingComplete} callApiForData={callApiForData}>
      <AppLayout headerProps={headerData} sidebarClassname='omni-sidebar-animate'>
        <div className='overflow-scroll-custom flex flex-col xl:flex-row gap-10 w-full'>
          <Card size='lg' isActive={!Boolean(client)} className={clsx(cardHeight.sm, client ? 'xl:flex-1' : 'xl:w-[50%]')}>
            <CardHeaderIcon icon={UserGlob} />
            <Title as='h4' className='card-title-regular'>
              Select Client
            </Title>
            <AutoComplete
              data={allClients}
              loading={isLoadingAllClients}
              className='my-[60px]'
              placeholder='Type and select client'
              handleSelect={handleClientSelect}
            />
          </Card>
          {client && (
            <Card size='lg' isActive className={clsx(cardHeight.sm, 'xl:flex-1')}>
              <CardHeaderIcon icon={UploadGlob} />
              <Title as='h4' className='card-title-regular'>
                Upload KPI Data
              </Title>
              <GButton
                variant='secondary'
                className='mt-[60px]'
                suffix={<i className='fa-thin fa-download' />}
                onClick={handleDownloadKPITemplate}
              >
                Download KPI Template
              </GButton>
              <FileUpload
                data={clientCsv}
                setData={setClientCsv}
                setModalOpen={setModalOpen}
                clientData={client}
                className='mt-[18px]'
                text='Upload Client KPI Data'
                loadingText='Validating data'
              />
            </Card>
          )}
        </div>
        <AppButtonWrapper className={clsx(!clientCsv && 'invisible')}>
          <GButton variant='primary' size='md' suffix={<i className='fa-light fa-chevron-right' />} onClick={redirectNextStage}>
            Continue
          </GButton>
        </AppButtonWrapper>

        {/* Invalid CSV Modal */}
        <Modal open={modalOpen} onClose={handleSamePage} size='lg'>
          <CardHeaderIcon icon={modalIcon} />
          <Title as='h4' className='card-title-text'>
            Invalid CSV
          </Title>
          <div className='text-base tracking-[0.03em] text-neutral-100 pt-3'>
            <p>Please check and upload a different CSV to continue.</p>
            <br />
          </div>
          <div className='flex flex-col mt-[50px]'>
            <SButton fullWidth suffix={<i className='fa-light fa-chevron-right' />} onClick={handleSamePage}>
              Close message
            </SButton>
          </div>
        </Modal>
      </AppLayout>
    </LoadingSection>
  );
}
