// @ts-nocheck
import React from 'react';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { ReviewImg } from './svg/Review-img';

const styles = StyleSheet.create({
  mainStyle: {
    width: '100%',
    margin: '40px 0 0 40px'
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '20px'
  },
  cardWrapper: {
    width: '45%',
    display: 'flex',
    border: '1px solid #D6D7D8',
    borderRadius: '10px',
    marginTop: '5px',
    margin: '0 10px 0 10px'
  },
  pieCardWrapper: {
    width: '47.5%',
    display: 'flex',
    flexDirection: 'row',
    marginRight: '20px'
  },
  pieChartWrapper: {
    width: '45%',
    height: '320px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #D6D7D8',
    borderRadius: '10px',
    marginTop: '5px',
    margin: '0 10px 0 10px'
  },
  pieChartImg: {
    width: '100%',
    height: '66%',
    marginTop: '15%',
    marginLeft: '-15px'
  },
  cardHeader: {
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#373F45',
    fontSize: '12px',
    fontWeight: 700,
    marginTop: '10px'
  },
  barChart: {
    width: '100%',
    height: '280px',
    marginLeft: '0px',
    marginBottom: '10px'
  },
  monthlySpend: {
    width: '75%',
    height: '300px',
    marginLeft: '55px',
    marginTop: '20px'
  },
  reviewWrapper: {
    display: 'flex',
    width: '95%',
    flexDirection: 'row',
    margin: '0 10px 0 0px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  reviewHeading: {
    fontSize: '28px',
    fontWeight: 700,
    marginLeft: '10px'
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 10px 0 10px',
    marginBottom: '10px'
  },
  headerDate: {
    fontSize: '14px',
    fontWeight: 500,
    marginRight: '20px'
  },
  reviewLogo: {
    display: 'flex',
    height: '30px',
    width: '30px'
  }
});

const Review = (props) => {
  const clientDetail = `${props?.currentClientData?.name}, ${props?.currentClientData?.startDate} - ${props?.currentClientData?.endDate}`;

  return (
    <View style={styles.mainStyle}>
      <View style={styles.reviewWrapper}>
        <View style={styles.headerTitle}>
          <View>
            <ReviewImg />
          </View>
          <Text style={styles.reviewHeading}>Review</Text>
        </View>
        <Text style={styles.headerDate}>{clientDetail}</Text>
      </View>
      <View style={styles.innerWrapper}>
        <View style={styles.cardWrapper}>
          <Text style={styles.cardHeader}>Total VS Measurable Spend $ ('000)</Text>
          <Image src={props?.totalSpendMeasurableBarChartResponse} style={styles.barChart} />
        </View>
        <View style={styles.pieCardWrapper}>
          <View style={styles.pieChartWrapper}>
            <Text style={styles.cardHeader}>% Spend Included In Model</Text>
            <Image src={props?.spendPercentagePieChartResponse} style={styles.pieChartImg} />
          </View>
          <View style={styles.pieChartWrapper}>
            <Text style={styles.cardHeader}>Share Of Spend Across Channels</Text>
            <Image src={props?.aggregateSpendPieChartChartResponse} style={styles.pieChartImg} />
          </View>
        </View>
      </View>
      <View style={styles.innerWrapper}>
        <View style={styles.cardWrapper}>
          <Text style={styles.cardHeader}>Competitors by Advertising Spend $ (’000)</Text>
          <Image src={props?.competitorBarChartResponse} style={styles.monthlySpend} />
        </View>
        <View style={styles.cardWrapper}>
          <Text style={styles.cardHeader}>Sales (’000) & Share of Voice Over Time</Text>
          <Image src={props?.shareVoiceChartResponse} style={styles.monthlySpend} />
        </View>
      </View>
    </View>
  );
};

export default Review;
