import { HashMap } from '@/types';
import { formatTitleCase } from './helpers';

export const API_STATUS_CONST = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

export const editingRoutes = [
  '/review-validate',
  '/review-channel',
  '/review-voice',
  '/analysis-confirm',
  '/analysis-sales',
  '/analysis-model',
  '/analysis-warning',
  '/optimise-result',
  '/optimise-confirm',
  '/result-dashboard-confirm',
  '/pdf-generate'
];

export const channelNames = {
  META: 'meta',
  SOCIAL: 'social',
  INSTAGRAM: 'instagram',
  SEMBRAND: 'sem',
  SEMNOBRAND: 'semno',
  DISPLAY: 'display',
  VIDEO: 'video',
  RETARGETING: 'retargeting',
  YOUTUBE: 'youtube',
  TV: 'tv',
  OOH: 'ooh',
  NEWSPAPER: 'newspaper',
  MAGAZINE: 'magazine',
  RADIO: 'radio'
};

export const channelGroups = {
  EXTERNAL: 'External',
  MEDIA: 'Media'
};

export const channelNamesList = Object.values(channelNames);

export const channelNameSortedList = [
  channelNames.SOCIAL,
  channelNames.SEMBRAND,
  channelNames.DISPLAY,
  channelNames.YOUTUBE,
  channelNames.TV,
  channelNames.RADIO,
  channelNames.OOH,
  channelNames.NEWSPAPER,
  channelNames.MAGAZINE,
  channelNames.META,
  channelNames.INSTAGRAM,
  channelNames.SEMNOBRAND,
  channelNames.VIDEO,
  channelNames.RETARGETING
].map((item) => formatTitleCase(item));

export const darkColors = ['#2AC8DD', '#4693E2', '#9362E3', '#DD54E9', '#E04A89', '#E78742', '#E0C06E', '#C7E472'];

export let channelDarkColorObj: HashMap = {};

channelNameSortedList.forEach((key, index) => {
  channelDarkColorObj[key] = darkColors[index % darkColors.length];
});

export const defaultLineColor = '#A6CEE3';

export const barChartRadius = [3, 3, 3, 3];

export const cardHeight = {
  sm: 'h-[520px]',
  md: 'h-[680px]'
  // lg: 'h-[780px]' This would be required for large number of channel
};

export const cardMaxWidth = {
  md: 'max-w-[572px]'
};

export const graphLayoutContainer = {
  width: '100%',
  height: '100%'
};

export const optimsieBudgetSliderValues = {
  20: '-10',
  40: '-5',
  60: '0',
  80: '+5',
  100: '+10'
};

export const optimsieBudgetDataConst = {
  20: '-0.1',
  40: '-0.05',
  60: '0',
  80: '0.05',
  100: '0.1'
};

export const barChartYAxisMaxLength = 65;
export const barChartGapBetweenBarAndValue = 5;
export const intervalBetweenModelApiCheck = 5000;
export const intervalBetweenEvaluationAndRoiApiCheck = 2000;
export const intervalModelChannelValidationTime = 10000;

export const dateFormatForClient = 'dd/MM/yy';

export const OKTAConst = {
  accessToken: 'oktaAccessToken',
  accessUserName: 'oktaAccessUserName'
};

export const defaultName = 'DummyName';

export const defaultTimezone = 'Australia/Sydney';
export const defaultTimestamp = 'yyyy-MM-dd HH:mm';
export const pdfDateFormat = 'dd MMM yy';
