import React from 'react';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export type ToastType = { title: string };

const bounce = cssTransition({
  enter: 'omni-task-animate omni-task-animate-in',
  exit: 'omni-task-animate omni-task-animate-out'
});

export const SuccesToastMessage = ({ title }: ToastType) => {
  return (
    <div className='flex justify-center items-center gap-2'>
      <i className='fa-light text-md text-primary-50 fa-circle-check'></i>
      <span>{title}</span>
    </div>
  );
};

export const ErrorToastMessage = ({ title }: ToastType) => {
  return (
    <div className='flex justify-center items-center gap-2'>
      <span>{title}</span>
    </div>
  );
};

export const LoadingToastMessage = ({ title }: ToastType) => {
  return (
    <div className='flex justify-center items-center gap-2'>
      <div className='text-lg loader h-2 w-2' />
      <span>{title}</span>
    </div>
  );
};

const ToastContainerCustom = () => {
  return (
    <ToastContainer
      position='top-center'
      transition={bounce}
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      closeButton={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export const toastErrorMsg = (title: string) => toast.error(<ErrorToastMessage title={title} />);

export default ToastContainerCustom;
