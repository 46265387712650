import React from 'react';
import clsx from 'clsx';

type PillType = {
  icon?: React.ReactNode;
  text: string;
  state?: 'normal' | 'nav' | 'complete' | 'active';
  invert?: boolean;
  handleClick?: any;
  className?: string;
};

export function Pill({ icon, text, state = 'normal', invert, handleClick, className }: PillType) {
  return (
    <div
      className={clsx(`${invert ? 'px-[5px] py-5' : 'px-5 py-[5px]'}`, `omni-pill`, `omni-pill-${state}`, className)}
      onClick={handleClick}
    >
      {icon ? icon : null}
      {text}
    </div>
  );
}
