import React from 'react';
import { VerticalBarChartCustom } from '@/components/Chart/VerticalBarChart';
import { BarChartCustom } from '@/components/Chart/BarChart';
import { ComposeBarLineChart } from '@/components/Chart/ComposeBarLineChart';
import { PieChartCustom } from '@/components/Chart/PieChart';
import { useAppSelector } from '@/store/hooks';
import { formatCurrency } from '@/utils/helpers';
import { BarMixChart } from '@/components/Chart/BarMixChart';
import { CustomScatterChart } from '@/components/Chart/ScatterChart';
import { useOptimiseResult } from './OptimiseResultView/hooks/useOptimiseResultLogic';
import { Card, PriceAggregationCard } from '@/components/Common/Card';
import { round } from 'lodash';
import { BarPositiveNegativeChart } from '@/components/Chart/BarPositiveNegativeChart';
import { OptimiseChart } from '@/components/Chart/OptimiseChart';
import { Title } from '@/components/Common/Typography';
import { SliderCustom } from '@/components/Common/SliderCustom';
import { Checkbox } from '@/components/Common/Checkbox';
import { pickMaxNegativeValue } from '@/utils/chart-mapping';
import clsx from 'clsx';

const PDFRenderComponent = () => {
  const { yearly_values } = useAppSelector((state) => state.models.yearlyMMMBudget);
  const allData = useAppSelector((state) => state.models.measurableSpend);
  const competitorSpends = useAppSelector((state) => state.models.competitorSpends);
  const externalSalesContribution = useAppSelector((state) => state.models.externalSalesContribution);
  const mediaSalesContribution = useAppSelector((state) => state.models.mediaSalesContribution);
  const liteDecomp = useAppSelector((state) => state.models.liteDecomp);
  const bubbleData = useAppSelector((state) => state.models.bubbleData);
  const modelledResultsData = useAppSelector((state) => state.models.modelledResults);
  const shareOfVoice = useAppSelector((state) => state.models.shareOfVoice);

  const isPositiveExternalDriveSales = pickMaxNegativeValue(externalSalesContribution, 'percent_contri_spend') === 0;

  const {
    filterDataV3: filterData,
    aggregateDataOptimisedV3: aggregatedOptimisedData,
    changeInSpendSlider,
    totalSpendCheck,
    changeInTotalSpendCheckbox,
    optimisedSpendCheck,
    changeInSpendCheckbox,
    spendCheck,
    spendSliderValue,
    changeInOptimisedSpendCheckbox
  } = useOptimiseResult(yearly_values);

  return (
    <div className='w-[2000px] overflow-x-auto bg-white'>
      <div className='w-full flex max-w-[950px] h-[500px] totalMeasurableBarChart'>
        <VerticalBarChartCustom
          data={allData?.aggregated_spend}
          firstLabel='Total spend'
          secondLabel='Measurable spend'
          isAnimationActive={false}
          isOverflow={false}
          isPDF={true}
        />
      </div>

      <div className='w-full flex max-w-[400px]  h-[320px]  spendPercentagePie'>
        <PieChartCustom
          isAnimationActive={false}
          data={allData?.spend_percentage}
          averageTitle={allData?.spend_percentage?.[0]?.value ? `${allData?.spend_percentage?.[0]?.value}%` : '0%'}
          isPercentage
          isPDF={true}
        />
      </div>
      <div className='w-full flex max-w-[400px] h-[320px]  aggregateSpendPie'>
        <PieChartCustom
          isChannelName
          isAnimationActive={false}
          data={allData?.aggregated_spend}
          fieldKey={{ value: 'spend_percent' }}
          isPercentage
          averageTitle={`${formatCurrency(allData?.measurable_total_spend)} Spend`}
          isPDF={true}
        />
      </div>
      <div className='w-full flex max-w-[800px] h-[500px]'>
        <BarChartCustom
          data={competitorSpends}
          singleBarColor='#48B8A7'
          id='competitorBarChart'
          marginControl={{ left: -40, right: 35, bottom: 30 }}
          maxDomainRef='spend'
          topYAxisLabel={true}
          hasSingleBar
          maxBarSize={25}
          noLegend
          noTooltip
          isDollar
          isAnimationActive={false}
          fieldKeys={{ yAxis: 'name', solidBar: 'spend' }}
          cartesianProps={{ horizontal: false }}
          isPDF={true}
        />
      </div>
      <div className='ml-10'>
        <div className='w-full flex max-w-[800px] h-[500px] shareVoiceChart'>
          <ComposeBarLineChart
            isAnimationActive={false}
            data={shareOfVoice}
            hasSingleBar='#00A0D3'
            firstLabel='Sales'
            secondLabel='Share of voice'
            maxDomainRef='sales'
            fieldKeys={{
              xAxis: 'name',
              bar: 'sales',
              line: 'sov'
            }}
            marginValue={{
              top: 20,
              right: 0,
              left: 22,
              bottom: 15
            }}
            cartesianProps={{ vertical: false }}
            isLinePercentage
            isRightYAxis
            noTooltip
            xAxisTickMargin={16}
            rotateAngle={-25}
            fixedMultiple={50000}
            isMaxRange={false}
            isPDF={true}
          />
        </div>
      </div>
      <div className={clsx('w-full flex max-w-[1100px]  mediaContributionChart', isPositiveExternalDriveSales ? 'h-[210px]' : 'h-[250px]')}>
        <BarPositiveNegativeChart
          data={externalSalesContribution}
          isPDF={true}
          fieldKeys={{
            bar: 'percent_contri_spend',
            xAxis: 'channel'
          }}
        />
      </div>
      <div className={clsx('w-full flex max-w-[1100px]  externalDriveChart', isPositiveExternalDriveSales ? 'h-[220px]' : 'h-[180px]')}>
        <ComposeBarLineChart
          fieldKeys={{
            bar: 'percent_contri_spend',
            xAxis: 'channel',
            line: ''
          }}
          maxDomainRef='percent_contri_spend'
          isNoDataLabel={false}
          data={mediaSalesContribution}
          rotateAngle={-25}
          marginValue={{
            top: 20,
            right: 0,
            left: 22,
            bottom: 15
          }}
          noLine
          noLegend
          rotateValue
          noYAxis
          noCartesian
          isPercentage
          noTooltip
          isAnimationActive={false}
          isPDF={true}
        />
      </div>
      <div className='w-full flex max-w-[600px] h-[600px] majorContributionChart'>
        <BarMixChart
          data={liteDecomp}
          fieldKeys={{ xAxis: 'name', solidBar: 'base', stripeBar: 'brand', lightBar: 'media' }}
          labelSet
          hasSingleColor
          isPercentage
          minPointSize={10}
          isAnimationActive={false}
          isPDF={true}
        />
      </div>
      <div className='w-full flex max-w-[800px] h-[440px]'>
        <CustomScatterChart id='contributionChart' isAnimationActive={false} data={bubbleData} />
      </div>
      <div className='w-full flex max-w-[800px] h-[440px]'>
        <BarChartCustom
          id='roiChart'
          isAnimationActive={false}
          data={modelledResultsData}
          marginControl={{ left: 45, right: 35, bottom: 30 }}
          fieldKeys={{ yAxis: 'name', solidBar: 'roi' }}
          hasSingleBar
          maxBarSize={25}
          noLegend
          noTooltip
          isDollar
          formatter={formatCurrency}
          noCartesian
          isPDF={true}
        />
      </div>

      <div className='w-full flex max-w-[1500px] h-[70px] optimiseResultHeader'>
        <div className='flex justify-around gap-4  w-full'>
          <PriceAggregationCard
            title='Total revenue uplift'
            value={formatCurrency(aggregatedOptimisedData.totRevenue, 0)}
            unformattedValue={aggregatedOptimisedData.totRevenue}
            isPDF={true}
          />
          <PriceAggregationCard
            title='Revenue uplift %'
            value={aggregatedOptimisedData.revenueUplift !== Infinity ? `${round(aggregatedOptimisedData.revenueUplift, 1)}%` : '0%'}
            unformattedValue={aggregatedOptimisedData.revenueUplift}
            isPDF={true}
          />
          <PriceAggregationCard
            title='Expenditure uplift'
            value={formatCurrency(aggregatedOptimisedData.expanditureUplift, 0)}
            unformattedValue={aggregatedOptimisedData.expanditureUplift}
            isPDF={true}
          />
        </div>
      </div>
      <div className='flex flex-col gap-[30px] h-full lg:flex-row optimiseResultPdfChart'>
        <div className='min-w-[272px] ml-4'>
          <Card size='noshadow' className='shadow-none'>
            <Title as='h5' className='text-center mb-4'>
              Show / Hide Data
            </Title>
            <div className='flex flex-row gap-6 lg:gap-2 md:flex-col'>
              <div className='w-3/5 lg:w-full'>
                <SliderCustom handleChange={changeInSpendSlider} value={spendSliderValue} />
                <Checkbox isPDF={true} label='Total Spend Change' checked={totalSpendCheck} handleChange={changeInTotalSpendCheckbox} />
              </div>
              <div className='w-2/5 lg:w-full'>
                <div>
                  <div className='rounded-app bg-[#2AC8DD] striped w-full h-[19px] mb-2' />
                  <Checkbox
                    isPDF={true}
                    label='Optimised Spend'
                    checked={optimisedSpendCheck}
                    handleChange={changeInOptimisedSpendCheckbox}
                  />
                </div>
                <div className='mt-4 lg:mt-2'>
                  <div className='rounded-app bg-[#2AC8DD] w-full h-[19px] mb-2' />
                  <Checkbox isPDF={true} label='Current Spend' checked={spendCheck} handleChange={changeInSpendCheckbox} />
                </div>
              </div>
            </div>
          </Card>
        </div>

        <Card size='noshadow' className='w-full p-[10px] mr-2'>
          <div className='text-center text-xl font-semibold text-[#373F45]'>Media Spend Optimisation ('000)</div>
          <div className='w-full rounded-[15px] flex max-w-[1500] h-[850px] '>
            <OptimiseChart
              data={filterData}
              margin={{ top: 30, left: 10, right: 10 }}
              formatter={formatCurrency}
              fieldKeys={{ xAxis: 'name', solidBar: 'current_spend', stripeBar: 'optimal_spend', lightBar: 'total_spend' }}
              isPDF={true}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PDFRenderComponent;
