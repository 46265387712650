import React from 'react';
import { utcToZonedTime, format } from 'date-fns-tz';
import { TableCustom } from '@/components/Common/Table';
import { AppButtonWrapper, ResultsDashboardLayout } from '@/components/Layout/DesignLayout';
import { GButton } from '@/components/Common/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  fetchSessions,
  sessionDataForCompletedModel,
  sessionDataGathering,
  sessionRemovalCheck,
  updateCurrentClientData
} from '@/store/modelsSlice';
import { HashMap } from '@/types';
import { toast } from 'react-toastify';
import { SuccesToastMessage, toastErrorMsg } from '@/components/Common/Toast';
import { LoadingSection } from '@/components/Common/Loading';
import { useClearCurrentSession } from '@/components/Common/hook/session';
import { defaultName, defaultTimestamp, defaultTimezone, OKTAConst } from '@/utils/constants';
import { getFirstName } from '@/utils/helpers';

const ModelState = {
  uploaded: {
    key: 'KPI file uploaded',
    percent: '25%'
  },
  review: {
    key: 'Data validation completed',
    percent: '50%'
  },
  complete: {
    key: 'Model run completed',
    percent: '100%'
  }
};

export function MyAccount() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sessions = useAppSelector((state) => state.models.sessions);
  const sessionsStageLoading = useAppSelector((state) => state.models.sessionsStageLoading);

  const [sessionsMapped, setSessionsMapped] = React.useState<HashMap[]>([]);
  const [isLockDelete, setIsLockDelete] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [name, setName] = React.useState(defaultName);

  React.useEffect(() => {
    const localName = localStorage.getItem(OKTAConst.accessUserName);
    if (localName) {
      setName(getFirstName(localName));
    } else {
      toastErrorMsg('Unauthenticated, no okta session found.');
      navigate('/');
    }
  }, [navigate]);

  const headerData = React.useMemo(() => {
    return {
      title: 'My Account',
      subtleText: `Hi ${name}, view your previous ROI models here`,
      helpTooltipProps: {
        title: 'Access your Clients’ ROI models',
        description: (
          <p>
            Here, you can review all the models you created for your clients.
            <p className='mt-2'>
              You can either complete a model by continuing where you left off, download the report of your finished models or view your
              client dashboard
            </p>
          </p>
        )
      }
    };
  }, [name]);

  useClearCurrentSession();

  React.useEffect(() => {
    dispatch(fetchSessions({}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const filterSessions = [];
    sessions?.forEach((data) => {
      const dateUTCFormat = data.updated_at?.split('.')?.[0] + 'Z';
      const date = new Date(dateUTCFormat);
      const syDate = utcToZonedTime(date, defaultTimezone);
      const formatedDate = format(syDate, defaultTimestamp, { timeZone: defaultTimezone });

      filterSessions.push({
        session_id: data.session_id,
        clientId: data.client_id,
        name: data.client_name,
        startDate: data.start_date,
        endDate: data.end_date,
        state: data.state,
        updatedAt: formatedDate
      });
    });

    setSessionsMapped(filterSessions);
    console.log('Sessions: ', sessions);
  }, [sessions]);

  const handleNewProject = () => {
    navigate('/');
  };

  const updateClientDataOnHeader = (data: HashMap) => {
    dispatch(
      updateCurrentClientData({
        id: data.endDate,
        name: data.name,
        startDate: data?.startDate,
        endDate: data?.endDate
      })
    );
  };

  const handleSessionRemoval = async (data: HashMap) => {
    setIsLockDelete(true);
    await dispatch(sessionRemovalCheck(data.session_id))
      .unwrap()
      .then(() => {
        toast(<SuccesToastMessage title='Session delete successfully' />, {});
      })
      .catch((err) => {
        console.log('ERROR on removal of session: ', err);
      });
    setIsLockDelete(false);
  };

  const handleSessionRedirect = async (payload: HashMap) => {
    setIsLoading(true);
    updateClientDataOnHeader(payload);
    await dispatch(sessionDataGathering({ id: payload.session_id, mediaPlan: false }));
    navigate(`/review-validate?session_id=${payload.session_id}`);
    setIsLoading(false);
  };

  const handleSessionModelResult = async (payload: HashMap) => {
    try {
      setIsLoading(true);
      updateClientDataOnHeader(payload);
      await dispatch(sessionDataGathering({ id: payload.session_id, mediaPlan: true })).unwrap();

      navigate('/review-channel');
      setIsLoading(false);
    } catch (error) {
      console.log('ERROR on fetching the data for model validation', error);
    }
  };

  const handleSessionAllCompleteModelRedirect = async (payload: HashMap) => {
    setIsLoading(true);
    updateClientDataOnHeader(payload);
    await dispatch(sessionDataForCompletedModel(payload.session_id));
    navigate(`/analysis-sales`);
    setIsLoading(false);
  };

  const columns = [
    {
      title: 'Client name',
      dataIndex: 'name',
      key: 'name',
      // @ts-ignore
      render: (text, row, index) => (
        <div className='flex flex-row text-left opacity-70 text-xs lg:text-sm lg:opacity-100'>
          <span className=''>{text}</span>
        </div>
      )
    },
    {
      title: 'Date Modelled',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      // @ts-ignore
      render: (text, row, index) => <div className='text-lg font-semibold lg:text-sm'>{text}</div>
    },
    {
      title: 'Dataset Time Period',
      dataIndex: 'startDate',
      key: 'startDate',
      // @ts-ignore
      render: (text, row, index) => (
        <div className='absolute right-0 top-0 flex flex-col text-xs py-1 text-right opacity-70 lg:opacity-100 lg:relative lg:text-sm lg:py-0 lg:text-center'>
          <span className=''>{row?.startDate}</span>
          <span className=''>{row?.endDate}</span>
        </div>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'state',
      key: 'state',
      // @ts-ignore
      render: (_, row) => (
        <div className='flex flex-row gap-2'>
          {row.state !== ModelState.complete.key ? (
            <GButton
              variant='secondary'
              size='sm'
              className='py-[12px] px-[10px] w-full opacity-50 pointer-events-none lg:pointer-events-auto lg:opacity-100 lg:py-2'
              onClick={() => (ModelState.uploaded.key === row.state ? handleSessionRedirect(row) : handleSessionModelResult(row))}
            >
              {row.state} - {row.state === ModelState.uploaded.key ? ModelState.uploaded.percent : ModelState.review.percent}
            </GButton>
          ) : (
            <>
              {/* <GButton size='sm' bgGradient='gray' className='py-4 px-[10px] w-full lg:py-2'>
                Download
              </GButton> */}
              <GButton
                size='sm'
                variant='primary'
                className='py-4 px-[10px] w-full lg:py-2'
                onClick={() => handleSessionAllCompleteModelRedirect(row)}
              >
                {ModelState.complete.key} - {ModelState.complete.percent}
              </GButton>
            </>
          )}
        </div>
      )
    },
    {
      title: 'Delete',
      // @ts-ignore
      render: (text, row, index) => (
        <div className='flex justify-center pl-2 lg:pl-0'>
          <GButton
            bgGradient='gray'
            className='w-full px-0 py-[15px] opacity-50 pointer-events-none lg:pointer-events-auto lg:opacity-100 lg:px-[5px] lg:py-[5px] lg:w-auto'
            disabled={isLockDelete}
            onClick={() => handleSessionRemoval(row)}
          >
            <i className='fa-regular fa-trash-can'></i>
          </GButton>
        </div>
      )
    }
  ];

  return (
    <LoadingSection isLoading={isLoading} callApiForData={() => {}} onLoadingComplete={() => {}}>
      <ResultsDashboardLayout headerProps={headerData}>
        <div className='flex self-center bg-white rounded-lg p-[40px] z-50 lg:h-[75%] w-[90%] mt-2 mb-4'>
          <div className='lg:overflow-auto w-[100%]'>
            <TableCustom
              className='omni-account-table-custom'
              data={sessionsMapped}
              columns={columns}
              rowKey='session_id'
              isLoading={sessionsStageLoading}
            />
          </div>
        </div>
        <div className='flex relative mb-10 justify-between z-50 items-center flex-wrap text-white w-[90%]  mx-auto text-xs h-min mt-2'>
          <div className='flex justify-between items-center'>
            <Link className='flex flex-row items-center cursor-pointer' to='/'>
              <GButton variant='default' size='md' className='px-0'>
                <>
                  <i className='fa-regular fa-angle-left h-[16px] w-[10px] !text-white'></i>
                  <div className='text-lg font-semibold text-white'>Back</div>
                </>
              </GButton>
            </Link>
          </div>
          <AppButtonWrapper className='hidden lg:flex'>
            <GButton size='lg' bgGradient='gray' suffix={<i className='fa-regular fa-plus'></i>} onClick={handleNewProject}>
              Create new Project
            </GButton>
          </AppButtonWrapper>
        </div>
      </ResultsDashboardLayout>
    </LoadingSection>
  );
}
