import { GButton } from '@/components/Common/Button';
import { Card, CardHeaderIcon } from '@/components/Common/Card';
import { Title } from '@/components/Common/Typography';
import { DefaultLayout } from '@/components/Layout/DesignLayout';
import { ReactComponent as Danger } from '@/assets/images/triangle-exclamation.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { flickHelpTooltip } from '@/store/modelsSlice';

export const NotFoundView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const headerData = React.useMemo(() => {
    return {
      helpTooltipProps: {
        title: (
          <span>
            Contact the omni romi<sup>TM</sup> team
          </span>
        ),
        description: "Didn't find what you were looking for? Send us a message at help@omniromi.com so we can assist you with your concern."
      }
    };
  }, []);

  const navigateToHomePage = () => {
    navigate('/');
  };

  return (
    // @ts-ignore
    <DefaultLayout headerProps={headerData}>
      <div className='flex justify-center items-center flex-col h-full relative'>
        <Card className='justify-around mx-auto'>
          <CardHeaderIcon icon={Danger} isBlob />
          <div className='flex flex-col justify-center'>
            <Title as='h4' className='card-title-text'>
              Error 404
            </Title>
            <p className='text-base tracking-[0.03em] text-neutral-100'>
              Oops, it looks like the page you're trying to access does not exist.
            </p>
          </div>
          <GButton
            type='button'
            bgGradient='gray'
            className='text-lg justify-end mt-10'
            suffix={<i className='fa-light fa-chevron-right w-4' />}
            onClick={navigateToHomePage}
          >
            Go to home page
          </GButton>
          <GButton
            type='button'
            variant='default'
            className='text-lg justify-end mt-[10px]'
            suffix={<i className='fa-light fa-chevron-right w-4' />}
            onClick={() => dispatch(flickHelpTooltip())}
          >
            Get help
          </GButton>
        </Card>
      </div>
    </DefaultLayout>
  );
};
