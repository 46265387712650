import React from 'react';
import clsx from 'clsx';

type SpacerType = { size?: string };
type DividerType = { className?: string };

export function Spacer({ size }: SpacerType) {
  return <div className={clsx(`h-1 mb-[${size || '54px'}]`)} />;
}

export function Divider({ className }: DividerType) {
  return <hr className={clsx('text-divider-shade', className)} />;
}
