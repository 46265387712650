import React from 'react';
import * as htmlToImage from 'html-to-image';
import { Card, CardHeaderIcon, PriceAggregationCard } from '@/components/Common/Card';
import { Title } from '@/components/Common/Typography';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import InlineTextLogo from '@/assets/images/omni-inline-text-logo.svg';
import { saveAs } from 'file-saver';
import { AppButtonWrapper, ResultsDashboardLayout } from '@/components/Layout/DesignLayout';
import { ReactComponent as GlobLogo } from '@/assets/images/omni-glob.svg';
import { formatCurrency, isLandscapeOrientation, shareToolTipLabel, spendToolTipLabel } from '@/utils/helpers';
import { BarChartCustom } from '@/components/Chart/BarChart';
import { PieChartCustom } from '@/components/Chart/PieChart';
import { ComposeBarLineChart } from '@/components/Chart/ComposeBarLineChart';
import { BarPositiveNegativeChart } from '@/components/Chart/BarPositiveNegativeChart';
import { BarMixChart } from '@/components/Chart/BarMixChart';
import { SliderCustom } from '@/components/Common/SliderCustom';
import { Checkbox } from '@/components/Common/Checkbox';
import { CustomScatterChart } from '@/components/Chart/ScatterChart';
import { Document, pdf } from '@react-pdf/renderer';
import { useNavigate } from 'react-router-dom';
import { VerticalBarChartCustom } from '@/components/Chart/VerticalBarChart';
import { useOptimiseResult } from '../OptimiseResultView/hooks/useOptimiseResultLogic';
import { useAppSelector } from '@/store/hooks';
import { MyDocument } from '@/pdf/main';
import { round } from 'lodash';
import PDFRenderComponent from '../PDFGenerate';
import { toast } from 'react-toastify';
import { LoadingToastMessage } from '@/components/Common/Toast';
import { Modal } from '@/components/Common/Modal';
import { SButton } from '@/components/Common/Button';
import { xlsxDataPrepareMapper } from '@/utils/xlsx-helper';
import { OptimiseChart } from '@/components/Chart/OptimiseChart';
import format from 'date-fns/format';
import { pdfDateFormat } from '@/utils/constants';

export const ResultsDashboard = () => {
  const navigate = useNavigate();

  const competitorSpends = useAppSelector((state) => state.models.competitorSpends);
  const allData = useAppSelector((state) => state.models.measurableSpend);
  const bubbleData = useAppSelector((state) => state.models.bubbleData);
  const externalSalesContribution = useAppSelector((state) => state.models.externalSalesContribution);
  const liteDecomp = useAppSelector((state) => state.models.liteDecomp);
  const mediaSalesContribution = useAppSelector((state) => state.models.mediaSalesContribution);
  const modelledResultsData = useAppSelector((state) => state.models.modelledResults);
  const shareOfVoice = useAppSelector((state) => state.models.shareOfVoice);
  const apiData = useAppSelector((state) => state.models.apiData);
  const { yearly_values } = useAppSelector((state) => state.models.yearlyMMMBudget);
  const currentClientData = useAppSelector((state) => state.models.currentClientData);

  const [responsiveModal, setResponsiveModal] = React.useState(isLandscapeOrientation());
  const loadingToastId = React.useRef(null);
  const [isPdfGenerate, setIsPdfGenerate] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [responsiveAlertCounter, setResponsiveAlertCounter] = React.useState(0);

  const {
    changeInSpendSlider,
    changeInTotalSpendCheckbox,
    changeInOptimisedSpendCheckbox,
    changeInSpendCheckbox,
    spendCheck,
    optimisedSpendCheck,
    totalSpendCheck,
    spendSliderValue,
    filterDataV3: optimiseResultFilteredData,
    aggregateDataOptimisedV3: aggregatedOptimisedData
  } = useOptimiseResult(yearly_values);

  const headerData = React.useMemo(() => {
    return {
      helpTooltipProps: {
        title: 'Your results dashboard',
        description: (
          <p>
            Here is a summary of the modelled results based on your client’s media spend and sales for the given time period.
            <p className='mt-2'>You can download a PDF copy or the raw CVS data to share with your team.</p>
            <p className='mt-2'>Otherwise, you can exit this Dashboard and review it again under the My Account page.</p>
          </p>
        )
      }
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', function () {
      if (responsiveAlertCounter < 2) {
        setResponsiveModal(isLandscapeOrientation());
        setResponsiveAlertCounter((prevState) => prevState + 1);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setResponsiveAlertCounter, responsiveAlertCounter]);

  const handleFileDownload = async () => {
    setIsPdfGenerate(true);
  };

  const handleExitDashboard = () => {
    // TODO: comment would be removed
    navigate('/');
  };

  const handlePdfDownloadLogic = async () => {
    document.body.style.overflow = 'hidden';

    const start = performance.now();

    // Review
    const totalSpendMeasurableBarChart = document.getElementsByClassName('totalMeasurableBarChart')[0] as HTMLElement;
    const spendPercentagePieChart = document.getElementsByClassName('spendPercentagePie')[0] as HTMLElement;
    const aggregateSpendPieChart = document.getElementsByClassName('aggregateSpendPie')[0] as HTMLElement;
    const competitorBarChart = document.getElementById('competitorBarChart') as HTMLElement;
    const shareVoiceChart = document.getElementsByClassName('shareVoiceChart')[0] as HTMLElement;

    // Analysis
    const externalDriveChartQB = document.querySelector('.externalDriveChart') as HTMLElement;
    const mediaContributionChartQB = document.getElementsByClassName('mediaContributionChart')[0] as HTMLElement;
    const majorContributionChartQB = document.getElementsByClassName('majorContributionChart')[0] as HTMLElement;
    const contributionChartQB = document.getElementById('contributionChart') as HTMLElement;
    const roiChartQB = document.getElementById('roiChart') as HTMLElement;

    // Optimise
    const optimiseResultChart = document.getElementsByClassName('optimiseResultPdfChart')[0] as HTMLElement;
    const optimiseResultHeader = document.getElementsByClassName('optimiseResultHeader')[0] as HTMLElement;

    const totalSpendMeasurableBarChartResponse = await htmlToImage.toPng(totalSpendMeasurableBarChart);
    const spendPercentagePieChartResponse = await htmlToImage.toPng(spendPercentagePieChart);
    const aggregateSpendPieChartChartResponse = await htmlToImage.toPng(aggregateSpendPieChart);
    const competitorBarChartResponse = await htmlToImage.toPng(competitorBarChart);
    const shareVoiceChartResponse = await htmlToImage.toPng(shareVoiceChart);

    const majorContributionChartResponse = await htmlToImage.toPng(majorContributionChartQB);
    const mediaContributionChartResponse = await htmlToImage.toPng(mediaContributionChartQB);
    const externalDriveChartResponse = await htmlToImage.toPng(externalDriveChartQB);
    const contributionhartResponse = await htmlToImage.toPng(contributionChartQB);
    const roiChartResponse = await htmlToImage.toPng(roiChartQB);

    const optimiseResultChartResponse = await htmlToImage.toPng(optimiseResultChart);
    const optimiseResultHeaderResponse = await htmlToImage.toPng(optimiseResultHeader);

    const end = performance.now();

    console.log(`Chart Creation Execution time: ${end - start} ms`);

    const splitStartDate = currentClientData?.startDate?.split('/');
    const splitEndDate = currentClientData?.endDate?.split('/');

    const currentClientFormatted = {
      ...currentClientData,
      startDate: format(new Date(splitStartDate[2], splitStartDate[1] - 1, splitStartDate[0]), pdfDateFormat),
      endDate: format(new Date(splitEndDate[2], splitEndDate[1] - 1, splitEndDate[0]), pdfDateFormat)
    };

    const doc = (
      <MyDocument
        currentClientData={currentClientFormatted}
        totalSpendMeasurableBarChartResponse={totalSpendMeasurableBarChartResponse}
        spendPercentagePieChartResponse={spendPercentagePieChartResponse}
        competitorBarChartResponse={competitorBarChartResponse}
        shareVoiceChartResponse={shareVoiceChartResponse}
        aggregateSpendPieChartChartResponse={aggregateSpendPieChartChartResponse}
        contributionhartResponse={contributionhartResponse}
        majorContributionChartResponse={majorContributionChartResponse}
        mediaContributionChartResponse={mediaContributionChartResponse}
        externalDriveChartResponse={externalDriveChartResponse}
        roiChartResponse={roiChartResponse}
        optimiseResultChartResponse={optimiseResultChartResponse}
        optimiseResultHeaderResponse={optimiseResultHeaderResponse}
      />
    );
    const asPdf = pdf(<Document />);
    asPdf.updateContainer(doc);
    asPdf
      .toBlob()
      .then((blob) => {
        saveAs(blob, `omni-romi-result.pdf`);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        document.body.style.overflow = 'initial';
        setIsPdfGenerate(false);
        // @ts-ignore
        toast.dismiss(loadingToastId.current);
        console.log('PDF rendering end');
      });
  };

  const formatterROI = (value: number) => {
    return formatCurrency(value, 0);
  };

  React.useEffect(() => {
    if (isPdfGenerate) {
      // @ts-ignore
      loadingToastId.current = toast(<LoadingToastMessage title={'Generating Optimised PDF'} />, {
        autoClose: false
      });
      console.log('PDF rendering start');
      setTimeout(() => {
        handlePdfDownloadLogic();
      }, 200);
      xlsxDataPrepareMapper(apiData, allData?.aggregated_spend);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPdfGenerate]);

  const modalIcon = () => (
    <div className='relative'>
      <GlobLogo className='absolute -left-1' />
      <i className='relative text-[87px] text-neutral-100 fa-thin fa-rotate'></i>
    </div>
  );

  const handleResponsiveCloseModal = () => {
    setResponsiveModal(false);
  };

  const handleNavigationClick = (increment) => {
    if (increment && tabIndex !== 2) {
      setTabIndex(tabIndex + 1);
    } else if (!increment && tabIndex !== 0) {
      setTabIndex(tabIndex - 1);
    }
  };

  return (
    <>
      <ResultsDashboardLayout headerProps={headerData}>
        <div className='flex flex-1 self-center bg-white rounded-lg p-4 lg:p-[40px] z-40 h-[70%] w-[90%]'>
          <div className='overflow-clip w-[100%]'>
            <Tab.Group onChange={(index) => setTabIndex(index)} selectedIndex={tabIndex}>
              <Tab.List className='flex space-x-1 rounded-[10px] bg-neutral-5 p-1'>
                <TabChannel />
              </Tab.List>
              <Tab.Panels className='mt-2 h-full'>
                {/* Review */}
                <Tab.Panel className={clsx('rounded-xl h-full bg-white overflow-x-hidden overflow-y-overlay omni-pill-content')}>
                  <div className='flex flex-col xl:flex-row gap-10 w-full bg-white'>
                    <Card
                      size='noshadow'
                      className='flex-1 flex-grow-[2] h-[600px] 2xl:h-[560px]  flex flex-col shadow-none overflow-auto w-full xl:w-2/3'
                    >
                      <Title as='h5' className='text-center text-neutral-100 mb-4'>
                        Total vs Measurable Spend $ ('000)
                      </Title>
                      <VerticalBarChartCustom data={allData?.aggregated_spend} firstLabel='Total spend' secondLabel='Measurable spend' />
                    </Card>
                    <div className='flex flex-1 flex-col gap-8 w-full xl:w-1/3'>
                      <Card size='noshadow' className='flex-1 shadow-none'>
                        <Title as='h5' className='text-center text-neutral-100 mb-4'>
                          % Spend Included In Model
                        </Title>
                        <PieChartCustom
                          toolTipLabelGenerator={spendToolTipLabel}
                          data={allData?.spend_percentage}
                          averageTitle={allData?.spend_percentage?.[0]?.value ? `${allData?.spend_percentage?.[0]?.value}%` : '0%'}
                          isPercentage
                        />
                      </Card>
                      <Card size='noshadow' className='flex-1 shadow-none'>
                        <Title as='h5' className='text-center text-neutral-100 mb-4'>
                          Share Of Spend Across Channels
                        </Title>
                        <PieChartCustom
                          isChannelName
                          toolTipLabelGenerator={shareToolTipLabel}
                          data={allData?.aggregated_spend}
                          fieldKey={{ value: 'spend_percent' }}
                          isPercentage
                          averageTitle={`${formatCurrency(allData?.measurable_total_spend)} Spend`}
                        />
                      </Card>
                    </div>
                  </div>
                  <div className='flex flex-col xl:flex-row gap-10 w-full text-neutral-100 mt-10 mb-10'>
                    <Card size='noshadow' className='flex-1 h-[500px] shadow-none'>
                      <Title as='h5' className='text-center mb-4'>
                        Competitors by Advertising Spend $ ('000)
                      </Title>
                      <BarChartCustom
                        data={competitorSpends}
                        singleBarColor='#48B8A7'
                        marginControl={{ left: -40, right: 35, bottom: 30 }}
                        maxDomainRef='spend'
                        topYAxisLabel={true}
                        hasSingleBar
                        maxBarSize={25}
                        noLegend
                        isDollar
                        fieldKeys={{ yAxis: 'name', solidBar: 'spend' }}
                        cartesianProps={{ horizontal: false }}
                      />
                    </Card>
                    <Card size='noshadow' className='flex-1 h-[500px] shadow-none'>
                      <div className='flex flex-col h-full'>
                        <div className='text-center mb-4'>
                          <Title as='h5'>Sales ('000) & Share of Voice Over Time</Title>
                        </div>
                        <div className='flex-1'>
                          <ComposeBarLineChart
                            data={shareOfVoice}
                            hasSingleBar='#00A0D3'
                            firstLabel='Sales'
                            secondLabel='Share of Voice'
                            maxDomainRef='sales'
                            fieldKeys={{
                              xAxis: 'name',
                              bar: 'sales',
                              line: 'sov'
                            }}
                            marginValue={{
                              top: 20,
                              right: 0,
                              left: 5,
                              bottom: 15
                            }}
                            isRightYAxis
                            cartesianProps={{ vertical: false }}
                            isLinePercentage
                            xAxisTickMargin={16}
                            rotateAngle={-25}
                            fixedMultiple={50000}
                            isMaxRange={false}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Tab.Panel>
                {/* Analysis */}
                <Tab.Panel className={clsx('rounded-xl bg-white h-full overflow-x-hidden overflow-y-overlay omni-pill-content')}>
                  <div className='flex flex-col xl:flex-row gap-10 w-full text-neutral-100'>
                    <div className='flex-1 flex flex-col gap-5'>
                      <Card size='noshadow' className='flex-1 shadow-none'>
                        <Title as='h5' className='text-center mb-4'>
                          External Drivers Of Sales
                        </Title>
                        <BarPositiveNegativeChart
                          data={externalSalesContribution}
                          fieldKeys={{
                            bar: 'percent_contri_spend',
                            xAxis: 'channel'
                          }}
                        />
                      </Card>
                      <Card size='noshadow' className='basis-[250px] gap-5 shadow-none'>
                        <Title as='h5' className='text-center mb-4'>
                          Media Contribution To Sales
                        </Title>
                        <ComposeBarLineChart
                          fieldKeys={{
                            bar: 'percent_contri_spend',
                            xAxis: 'channel',
                            line: ''
                          }}
                          maxDomainRef='percent_contri_spend'
                          data={mediaSalesContribution}
                          isNoDataLabel={false}
                          rotateAngle={-35}
                          noLine
                          noLegend
                          rotateValue
                          noYAxis
                          noCartesian
                          isPercentage
                        />
                      </Card>
                    </div>
                    <Card size='noshadow' className='flex min-w-[450px] h-[680px] 2xl:h-[580px]  shadow-none'>
                      <div className='flex flex-col h-full'>
                        <div className='text-center mb-4'>
                          <Title as='h5'>Major Contributors To Sales</Title>
                        </div>
                        <div className='flex-1'>
                          <BarMixChart
                            data={liteDecomp}
                            fieldKeys={{ xAxis: 'name', solidBar: 'base', stripeBar: 'brand', lightBar: 'media' }}
                            labelSet
                            hasSingleColor
                            isPercentage
                            minPointSize={10}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className='flex flex-col lg:flex-row gap-10 w-full text-neutral-100 mt-10'>
                    <Card size='noshadow' className='lg:w-[55%] 2xl:h-[560px] shadow-none'>
                      <Title as='h5' className='text-center mb-4'>
                        Contribution
                      </Title>
                      <CustomScatterChart data={bubbleData} />
                    </Card>
                    <Card size='noshadow' className='flex h-[680px] 2xl:h-[560px] lg:w-[55%] shadow-none'>
                      <div className='flex flex-col h-full'>
                        <div className='text-center mb-4'>
                          <Title as='h5'>Return on Investment</Title>
                        </div>

                        <BarChartCustom
                          data={modelledResultsData}
                          marginControl={{ left: 30, right: 55, bottom: 30 }}
                          fieldKeys={{ yAxis: 'name', solidBar: 'roi' }}
                          hasSingleBar
                          maxBarSize={25}
                          noLegend
                          isDollar
                          formatter={formatterROI}
                          noCartesian
                        />
                      </div>
                    </Card>
                  </div>
                </Tab.Panel>
                {/* Optimise */}
                <Tab.Panel className={clsx('rounded-xl bg-white h-full overflow-x-hidden overflow-y-overlay omni-pill-content')}>
                  <div className='flex flex-col lg:flex-row gap-10 w-full'>
                    <Card size='noshadow' className='flex-1 flex-grow-[2] h-[680px] flex flex-col gap-[18px] shadow-none'>
                      <div className='flex justify-around gap-2 lg:gap-4'>
                        <PriceAggregationCard
                          title='Total Revenue uplift'
                          value={formatCurrency(round(aggregatedOptimisedData.totRevenue, 0))}
                          unformattedValue={aggregatedOptimisedData.totRevenue}
                        />
                        <PriceAggregationCard
                          title='Revenue uplift %'
                          value={
                            aggregatedOptimisedData.revenueUplift !== Infinity
                              ? `${round(aggregatedOptimisedData.revenueUplift, 1)}%`
                              : '0%'
                          }
                          unformattedValue={aggregatedOptimisedData.revenueUplift}
                        />
                        <PriceAggregationCard
                          title='Expenditure uplift'
                          value={formatCurrency(round(aggregatedOptimisedData.expanditureUplift, 0))}
                          unformattedValue={aggregatedOptimisedData.expanditureUplift}
                        />
                      </div>
                      <div className='flex flex-col gap-[30px] h-full lg:flex-row'>
                        <div className='min-w-[272px]'>
                          <Card size='noshadow' className='shadow-none'>
                            <Title as='h5' className='text-center mb-4'>
                              Show / Hide Data
                            </Title>
                            <div className='flex flex-row gap-6 lg:gap-2 md:flex-col'>
                              <div className='w-3/5 lg:w-full'>
                                <SliderCustom handleChange={changeInSpendSlider} value={spendSliderValue} />
                                <Checkbox label='Total Spend Change' checked={totalSpendCheck} handleChange={changeInTotalSpendCheckbox} />
                              </div>
                              <div className='w-2/5 lg:w-full'>
                                <div>
                                  <div className='rounded-app bg-[#2AC8DD] striped w-full h-[19px] mb-2' />
                                  <Checkbox
                                    label='Optimised Spend'
                                    checked={optimisedSpendCheck}
                                    handleChange={changeInOptimisedSpendCheckbox}
                                  />
                                </div>
                                <div className='mt-4 lg:mt-2'>
                                  <div className='rounded-app bg-[#2AC8DD] w-full h-[19px] mb-2' />
                                  <Checkbox label='Current Spend' checked={spendCheck} handleChange={changeInSpendCheckbox} />
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                        <Card size='noshadow' className='h-full w-full shadow-none 2xl:h-[460px]'>
                          <Title as='h5' className='text-center mb-4'>
                            Media Spend Optimisation ('000)
                          </Title>
                          <div className='h-full 2xl:h-[390px]'>
                            <OptimiseChart
                              data={optimiseResultFilteredData}
                              margin={{ top: 20, left: 10, right: 10 }}
                              formatter={formatCurrency}
                              fieldKeys={{ xAxis: 'name', solidBar: 'current_spend', stripeBar: 'optimal_spend', lightBar: 'total_spend' }}
                            />
                          </div>
                        </Card>
                      </div>
                    </Card>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
        <div className='relative mb-10 flex flex-col justify-between items-center flex-wrap text-white w-[90%] mx-auto text-xs h-min mt-4 z-40 lg:mt-6 lg:flex-row'>
          <div className='flex flex-col items-center order-2 mt-8 text-center leading-snug lg:text-left lg:mt-auto lg:order-1 lg:flex-row'>
            <img src={InlineTextLogo} className='translate-y-[1px] h-[25px] mb-2 lg:mb-auto' alt='Footer inline logo' />
            <span className='max-w-xs mx-auto lg:mx-0 lg:max-w-full'>
              &nbsp; is created by OMG Media Group. ©omni romi™ All rights reserved 2022
            </span>
          </div>
          <nav className='flex items-center w-full order-1 lg:w-auto lg:order-2'>
            <AppButtonWrapper className='inline-flex gap-2 flex-row w-full'>
              <div className='flex gap-2'>
                <button
                  onClick={() => handleNavigationClick(false)}
                  disabled={tabIndex === 0}
                  className={clsx(
                    'py-[12px] px-[16px] border-2 border-white rounded-[4px] w-full lg:w-auto',
                    tabIndex === 0 && 'bg-[#9a9a9a] border-[#9a9a9a]'
                  )}
                >
                  <i className='fa-light fa-chevron-left' />
                </button>
                <button
                  onClick={() => handleNavigationClick(true)}
                  disabled={tabIndex === 2}
                  className={clsx(
                    'py-[12px] px-[16px] border-2 border-white rounded-[4px] w-full lg:w-auto',
                    tabIndex === 2 && 'bg-[#9a9a9a] border-[#9a9a9a]'
                  )}
                >
                  <i className='fa-light fa-chevron-right' />
                </button>
              </div>
              {/* TODO: update to AppFooter component */}
              <button className='py-[12px] px-[32px] border-2 border-white rounded-[4px] w-full lg:w-auto' onClick={handleExitDashboard}>
                Exit Dashboard
              </button>
              <button
                className='py-[14px] px-[32px] gap-[8px] rounded-[4px] hidden w-full lg:w-auto lg:flex'
                style={{
                  background: 'radial-gradient(86.82% 192.89% at 73.58% 28.49%, #373F45 0%, #566169 100%)'
                }}
                onClick={handleFileDownload}
              >
                Download XLSX &amp; PDF <i className='fa-regular fa-download'></i>
              </button>
            </AppButtonWrapper>
          </nav>
        </div>
        {/* Responsive Modal */}
        <Modal open={responsiveModal} onClose={handleResponsiveCloseModal} size='lg'>
          <CardHeaderIcon icon={modalIcon} />
          <Title as='h4' className='card-title-text'>
            Please rotate your device
          </Title>
          <div className='text-base tracking-[0.03em] text-neutral-100 pt-3'>
            <p>For best viewing experience, rotate your device to landscape mode, and disable Portrait Orientation Lock if necessary. </p>
          </div>
          <div className='flex flex-col mt-[50px]'>
            <SButton
              fullWidth
              suffix={<i className='fa-light fa-chevron-right' />}
              onClick={handleResponsiveCloseModal}
              className='w-full text-lg'
            >
              Ok got it!
            </SButton>
          </div>
        </Modal>
      </ResultsDashboardLayout>
      {isPdfGenerate ? <PDFRenderComponent /> : null}
    </>
  );
};

const TabChannel = () => {
  const data = [
    {
      text: 'Review',
      icon: <i className='fa-light fa-search'></i>
    },
    {
      text: 'Analyse',
      icon: <i className='fa-light fa-chart-mixed'></i>
    },
    {
      text: 'Optimise',
      icon: <i className='fa-regular fa-dial-high'></i>
    }
  ];

  return (
    <React.Fragment>
      {data.map((ele) => (
        <Tab
          key={ele.text}
          className={({ selected }) => clsx('omni-tabs-header-list', selected ? 'bg-white text-primary-50' : 'text-neutral-100')}
        >
          <span className='hidden md:inline-block'>{ele.icon}</span>
          <span className='ml-[7px]'>{ele.text}</span>
        </Tab>
      ))}
    </React.Fragment>
  );
};
