import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as MainBgLogo } from '@/assets/images/omni-rotating-transition-logo.svg';
import { ReactComponent as ChartScatterLogo } from '@/assets/images/omni-chart-scatter.svg';
import { ReactComponent as ChartLineLogo } from '@/assets/images/omni-chart-line.svg';
import { ReactComponent as ChartFlaskLogo } from '@/assets/images/omni-flasks.svg';
import { ReactComponent as UploadLogo } from '@/assets/images/omni-upload.svg';
import { ReactComponent as LoadingLogo } from '@/assets/images/omni-loading.svg';
import { Transition } from '@headlessui/react';
import { Title } from './Typography';

type LoadingSectionType = {
  isLoading: boolean;
  children: React.ReactNode;
  onLoadingComplete: Function;
  callApiForData?: Function;
  debug?: boolean;
  isLoadingCounter?: boolean;
};

const uploadDataConst = 'Uploading data';
const drawingDataConst = 'Drawing data';

export function LoadingSection({
  isLoading,
  children,
  onLoadingComplete,
  callApiForData,
  debug = false,
  isLoadingCounter = false
}: LoadingSectionType) {
  const [phase, setPhase] = React.useState(1);
  const [interId, setInterId] = React.useState('');
  const [counterInterId, setCounterInterId] = React.useState('');
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const location = useLocation();

  React.useEffect(() => {
    if (isLoading) {
      const intervalId = setInterval(() => {
        setPhase((prev) => {
          if (prev === 1) {
            callApiForData?.();
          }
          return prev + 1;
        });
      }, 2000);

      const counterId = setInterval(() => {
        setLoadingCounter((prev) => prev + 1);
      }, 1500);

      // @ts-ignore
      setInterId(intervalId);
      // @ts-ignore
      setCounterInterId(counterId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  React.useEffect(() => {
    if (!isLoadingCounter && phase === 4) {
      clearInterval(interId);
      onLoadingComplete?.();
    } else if (isLoadingCounter && loadingCounter >= 90) {
      clearInterval(counterInterId);
      onLoadingComplete?.();
    }
  }, [isLoadingCounter, counterInterId, onLoadingComplete, phase, loadingCounter, interId]);

  // Removing states on unmount
  React.useEffect(() => {
    if (!isLoading && interId) {
      setPhase(1);
      setInterId('');
    }
  }, [isLoading, interId]);

  const pathName = React.useMemo(() => {
    if (location?.pathname === '/upload') {
      return uploadDataConst;
    } else if (location?.pathname === '/optimise-confirm') {
      return drawingDataConst;
    } else return null;
  }, [location]);

  const phaseNum = phase % 3;

  return (
    <React.Fragment>
      <Transition
        show={isLoading}
        enter='transition-opacity duration-75'
        enterFrom='opacity-0'
        enterTo='opacity-100 h-full'
        leave='transition-opacity duration-75'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        {/* @ts-ignore */}
        <div className='relative splash-wrapper h-full flex flex-col items-center justify-center overflow-hidden'>
          <MainBgLogo className='absolute w-[1222px] h-[1222px]' />
          <div className='bg-white relative w-[380px] h-[380px] rounded-full flex justify-center items-center'>
            {pathName === uploadDataConst ? (
              <UploadLogo />
            ) : phaseNum === 1 ? (
              <ChartScatterLogo />
            ) : phaseNum === 2 ? (
              <ChartFlaskLogo />
            ) : (
              <ChartLineLogo />
            )}
          </div>
          <div className='relative text-center text-white mt-8 lg:mt-0 lg:bottom-[110px] lg:absolute'>
            {isLoadingCounter && <Title as='h4'>{loadingCounter} %</Title>}
            {pathName ? (
              <Title as='h4'>{pathName}...</Title>
            ) : (
              <Title as='h4'>{phaseNum === 1 ? 'Validating data' : phaseNum === 2 ? 'Modelling data' : 'Drawing data'}...</Title>
            )}
            <p className='text-base'>Please do not leave or close this screen.</p>
          </div>
        </div>
      </Transition>
      {!isLoading ? children : null}
    </React.Fragment>
  );
}

export function LoadingComp() {
  return (
    <div className='w-[145px] h-[165px]'>
      <LoadingLogo />
    </div>
  );
}
