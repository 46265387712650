import React from 'react';
import { ResultsDashboardLayout } from '@/components/Layout/DesignLayout';
import { GButton } from '@/components/Common/Button';
import { useNavigate } from 'react-router-dom';

export function TermsView() {

    const navigate = useNavigate();

    const headerData = React.useMemo(() => {
        return {
          title: 'Standard Terms & Conditions',
          subtleText: '',
          helpTooltipProps: {
            title: 'Access your Clients’ ROI models',
            description: (
              <p>
                Here, you can review all the models you created for your clients.
                <p className='mt-2'>
                  You can either complete a model by continuing where you left off, download the report of your finished models or view your
                  client dashboard
                </p>
              </p>
            )
          }
        };
      }, []);

    return (
        <ResultsDashboardLayout headerProps={headerData}>
            <div className='flex self-center bg-white rounded-lg p-[40px] z-50 h-[75%] w-[90%] mt-2 mb-4 lg:my-8'>
                <div className='overflow-auto w-[100%] xl:px-16'>
                    <ol className="list-decimal">
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Appointment</h4>
                            <p>(“You” or the “Client”) appoints <strong>Omnicom Media Group Australia</strong>, (ABN 74 129 754 883) (“Us” or  “OMG”)  to  provide  the  services  as  set  forth  in  Schedule  1  (the  “Services”)  with  respect  to  services  targeted primarily to persons residing in Australia (the “Territory”) during the Term (as defined below) in accordance with this  Agreement.   For  Services  performed  hereunder,  OMG  will  be  paid  a  fee  as  set  forth  in  Schedule  1  (the “Compensation”).   Client  shall  reimburse  OMG for  all  reasonable  travel  and  out-of-pocket  expenses  incurred  by OMGin connection with performing the Services hereunder.</p>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Terms Of Appointment</h4>
                            <p>This Agreement shall commence from date of receipt of signed agreement from Client or as indicated in Schedule 2 (the “Commencement Date”) and shall continue, subject to Section 12, unless one party provides notice in writing to the other party no later than 90 days prior to the end of the Term of its election to terminate this Agreement. The period from the Commencement Date until the termination of this Agreement is termed the “Term.”</p>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">OMG's Status</h4>
                            <p>In performing its Services hereunder, OMGshall act as an independent contractor and not as an agent on behalf of Client.  OMGreserves the right, in the event Client’s payments to OMGare not timely received, Client’s financial condition otherwise becomes a concern or credit insurers (if applicable) revise, withdraw or decline cover, to cease work on Client’s account.  OMGshall be entitled to sub-contract its obligations under this Agreement provided that OMGuses its best efforts to appoint reputable sub-contractors.</p>
                            <p>The Client agrees to pay OMGbased on the fixed and agreed costs as approved by the Client.</p>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Information</h4>
                            <p>Where the Client has provided information, representations, data, reports and/or other materials to OMGfor use in the Services, Client warrants that it is entitled to do so.  If Client provides data to OMG, such data must have been processed in a manner that complies in all material respects with all applicable data privacy laws, self-regulatory principles, and Client’s privacy policy and public statements. Client shall, prior to Client transferring data to OMG, specifically identify the types of data and obtain written consent from OMGto transfer the data to OMG.</p>
                            <p>If Client provides data collected online to OMG, such data must have been collected inline with all applicable data privacy laws, self-regulatory principles, and disclosed in Client’s privacy policy. Client is advised that vendor terms and conditions commonly require Client to post and maintain on its web site a privacy policy that discloses its data processing practices, and complies with all applicable laws and self-regulatory requirements.</p>
                            <p>Client acknowledges and agrees that OMGmay collect, retain, and use anonymous information derived from client’s users or media activity in relation to the provision of the Services, (i) to generate code, information, and data, for the enhancement and optimisation of Client campaigns, (ii) aggregated statistics, metrics, and general trend data for the enhancement of OMGproducts and services and for marketing, promotional, and analytics purposes. Client will not be identified to any third party in connection therewith and under no circumstances will OMGprovide any Client confidential information to any other client in connection with such use.</p>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Cancellations And Amendments Of Work In Progress</h4>
                            <p>The Client may request OMG to cancel or amend any and all plans, schedules or work in progress.  OMG shall take all  reasonable  steps  to  comply  with  any  such  request  provided  that  OMG  is  able  to  do  so  within  its  contractual obligations to other third parties.  In the event of any such cancellation or amendment, the Client shall pay OMG for any charges or expenses to which OMG is committed, including without limitation any charges imposed on OMG by third parties arising from the cancellation or amendment.</p>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Taxes</h4>
                            <p>All sums payable hereunder are stated exclusive of any applicable GST, sales and/or other taxes or levies imposed by a governmental or self-regulatory authority, which shall be included and itemized separately on OMG invoices and  paid  by  Client,  as  appropriate,  at  the  rate  prevailing  from  time  to  time.   In  addition,  if  Client  is  required  to withhold from any amount payable to OMG an amount representing taxes or other levies imposed pursuant to the laws  of  any  country,  Client  shall  nevertheless  have  the  obligation  to  pay  the  entire  gross  amount  of  the  payment without regard to the amount of such tax or other levy.</p>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Terms Of Payment</h4>
                            <p><strong className="block">OMG Remuneration and fees</strong> OMG  shall  invoice  the  Client  with  the  same  payment  terms.   OMG  shall  invoice  the  Client  in  accordance  with Schedule 1, and the Client shall pay each such invoice within 30 days from date of invoice.</p>
                            <p><strong className="block">Other Changes</strong></p>
                            <ul className="list-disc pl-8 mt-1">
                                <li className="mb-4">OMG  shall  invoice  the  Client  on  the  third  week  of  each  month  in  respect  any  other  third-party  charges  for  the current month and the Client shall pay such invoices within 30 days (or such shorter period as OMG may indicate, based on the principle that all payments for other third-party costs must be received in time for OMG to pay vendor invoices in a timely manner).  Appropriate adjustments will be made to the invoices for other third-party charges.</li>
                                <li className="mb-4">If the Client fails to pay any invoice by the relevant due date, OMG reserves the right to suspend the performance of its Services until it has received the overdue amount; and/or charge the Client interest on the overdue amount at the annual rate of the lesser of (i) the standard average Australian overdraft rate or and (ii) the highest rate allowable by law.  Such interest shall accrue daily from the date on which payment became overdue up to the date on which OMG receives the full outstanding amount.</li>
                                <li>If, following a failure by the Client to pay any invoice by the relevant due date, a late payment surcharge is imposed by a other third party against OMG, the Client shall immediately pay OMG for such surcharge, together with any interest charged by the supplier in respect of the overdue amount.</li>
                            </ul>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Intellectual Property Rights</h4>
                            <p>The  Rights  in  all  documents,  software,  strategy  proposals  and  reports  and  other  material  created  by  directors  or employees  of  OMG  in  the  course  of  providing  the  Services  to  the  Client  (collectively,  “OMG  Material”)  vest  in OMG unless OMG has agreed otherwise in writing.   Subject to the Client’s full compliance with this Agreement, OMG hereby grants to the Client a non-transferable royalty free licence to use OMG Material during the Term in the  Territory  for  the  purposes  contemplated  under  this  Agreement.    Except  as  expressly  provided  for  in  this Agreement,  all  powers  conferred  on  the  Client  by  section  26  of  the  Trade  Marks  Act  1995  (Cth)  are  excluded.  “Rights”  shall  mean  copyright,  designs,  patents,  performer’s  property  rights,  trade  marks,  database  rights  or  any similar  rights  exercisable  in  any  part  of  the  world,  including  any  application  for  registration  of  any  patents, trademarks, designs or similar registrable rights in any part of the world.</p>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Confidential Information</h4>
                            <p>Neither party (the "Recipient") shall, without the other party's ("Discloser") prior consent, divulge, reveal or disclose to  any  persons  or  entities  any  information  involving  Discloser's  trade  secrets  or  other  information  deemed confidential, as expressly noted by Discloser to Recipient.   OMG ’s confidential information shall include but not be limited to all information regarding OMG’s compensation and regarding rates OMG obtains from other suppliers.</p>
                            <p>The confidentiality obligations hereunder shall not apply to information which (i) Recipient is in possession of at the time of disclosure by Discloser, (ii) is or hereafter becomes published or otherwise generally available to the public through no fault of Recipient, (iii) is received on a non-confidential basis from a third party without breach of this Agreement, (iv) is independently developed by the Recipient without reference to the confidential information; or (v) is required to be disclosed by court order, subpoena or other law or legal process.</p>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Indemnities</h4>
                            <ul className="list-disc pl-8 mt-1">
                                <li className="mb-4">OMG  hereby agrees to indemnify and defend the Client against all losses, damages, liabilities and expenses which the  Client  may  incur  or  sustain  directly  or  indirectly  as  a  result  of  any  third-party  claims,  demands  or  civil  or criminal proceedings brought or threatened against the Client and/or as a result of any disciplinary action brought or threatened by any person against the Client, where such claim, demand, proceedings or action arises out of or in connection with (i) OMG’s breach of its obligations under this Agreement or (ii) OMG’s gross negligence or willful misconduct, in each case unless indemnifiable by Client pursuant to Section 11.2.</li>
                                <li>The Client hereby agrees to indemnify and defend OMG  against all losses, damages, liabilities and expenses which OMG  may incur or sustain directly or indirectly as a result of any third-party claims, demands or civil or criminal proceedings brought or threatened against OMG  and/or as a result of any disciplinary action brought or threatened by any person against OMG , where such claim, demand, proceedings or action arises out of or in connection with (i) any information, representations, reports, data and/or other material supplied, prepared or approved by the Client or on behalf of Client (including without limitation the Advertising); (ii) the contents, publication or dissemination by OMG of the Advertising; (iii) any breach by the Client of this Agreement; or (iv) the nature or use of any of Client’s products or services.</li>
                            </ul>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Limitations Of Liability</h4>
                            <ul className="list-disc pl-8 mt-1">
                                <li className="mb-4">To  the  full  extent  permitted  by  law,  OMG  ’s  maximum  aggregate  liability  under  or  in  connection  with  this Agreement,  whether  in  contract,  tort  (including  negligence)  or  otherwise,  shall  in  no  circumstances  exceed  the compensation received by OMG hereunder and retained as its fee in the twelve-month period prior to any claim hereunder.</li>
                                <li className="mb-4">Certain  legislation,  including  the  Australian  Consumer  Law  may  imply  warranties  or  conditions  or  impose obligations  upon  OMG  which  cannot  be  excluded,  restricted  or  modified,  or  cannot  be  excluded,  restricted  or modified except to a limited extent.   This Agreement must be read subject to these statutory provisions.   If these statutory provisions apply, to the extent to which OMG is entitled to do so, OMG limits its liability in respect of any claim under those provisions to:</li>
                                <li className="mb-4">
                                    <ul className="list-disc pl-8 mt-1">
                                        <li className="mb-4">
                                            <p>in the case of goods, at OMG 's option:</p>
                                            <ul className="list-disc pl-8 mt-1 mb-2">
                                                <li className="mb-4">the replacement of the goods or the supply of equivalent goods;</li>
                                                <li className="mb-4">the repair of the goods;</li>
                                                <li className="mb-4">the payment of the cost of replacing the goods or of acquiring equivalent goods; or</li>
                                                <li>the payment of the cost of having the goods repaired; and</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>in the case of services, at OMG 's option:</p>
                                            <ul className="list-disc pl-8 mt-1">
                                                <li className="mb-4">the supplying of the services again; or</li>
                                                <li>the payment of the cost of having the services supplied again.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>To the full extent permitted by law, neither OMG  nor Client shall be liable under this Agreement for any loss of actual or anticipated income or profits, loss of contracts or for any special, indirect or consequential loss or damage of any kind howsoever arising and whether caused by tort (including negligence), breach of contract or otherwise, whether or not such loss or damage is foreseeable, foreseen or known and whether or not OMG  or Client has been advised of or are aware that such damages may be incurred.</li>
                            </ul>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Termination</h4>
                            <ul className="list-disc pl-8 mt-1">
                                <li className="mb-4">In addition to any termination right pursuant to Section 2, either party may terminate this Agreement forthwith by notice in writing to the other if the other party is in material breach of any of the terms of this Agreement and such breach is irremediable or, if the breach is capable of remedy, the other party fails to remedy such breach within 30 days of receipt of written notice giving full particulars of the breach and of the steps required to remedy it.</li>
                                <li className="mb-4">Upon termination of this Agreement for any reason: the Client shall pay to OMG  all sums due in respect of Services performed  and  expenditure  incurred  by  OMG    up  to  the  date  of  termination,  which  sums  will  include  the continuation of all other fees through the period of time, pro-rated if necessary (and OMG  shall continue to perform all  Services  hereunder  with  respect  to  such  services);  and  the  Client  shall  pay  OMG   an  amount  equal  to  all expenditure to which OMG  is committed.</li>
                                <li>Provisions of this Agreement which either are expressed to survive its termination or from their nature or context it is contemplated that they are to survive such termination, shall remain in full force and effect notwithstanding such termination.</li>
                            </ul>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Force Majuere</h4>
                            <p>Neither party shall be deemed in default of this Agreement to the extent that performance of its obligations (other than payment obligations) or attempts to cure any breach are delayed or prevented by reason of any act of god, fire, natural disaster, accident, riots, acts of government, acts of war or terrorism, shortage of materials or supplies, failure of transportation or communications or of suppliers of goods or services, or any other cause beyond the reasonable control of such party.</p>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Assignments</h4>
                            <p>Neither party shall assign, transfer, charge or deal in any other manner with this Agreement or any of its rights under it without the prior written consent of the other party, such consent not to be unreasonably conditioned, withheld or delayed.  Should Client request that OMG make purchases on behalf of or render services to third parties (such as dealers and distributors), Client and the third party shall be jointly and severally liable to OMG even though OMG may render invoices to, or in the name of, the third party.</p>
                        </li>
                        <li className="mb-6">
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">Governing Law; Legal Fees</h4>
                            <p>This Agreement shall be governed by and construed in accordance with the laws of the State of New South Wales and the parties irrevocably and unconditionally submit to the non-exclusive jurisdiction of the Courts of that State, and  the  Courts  which  may  hear  appeals  from  those  Courts.   The  parties  agree  that  in  the  event  that  any  suit  or proceeding is brought in connection with this Agreement, the prevailing party shall be entitled to recover reasonable attorneys’ fees and costs.</p>
                        </li>
                        <li>
                            <h4 className="text-2xl text-primary-50 font-bold mb-2">General</h4>
                            <p>This  Agreement  constitutes  the  exclusive,  complete  and  final  agreement  between  the  parties  with  respect  to  the subject matter hereof and supersedes all prior proposals, negotiations, arrangements and other communications and understandings between the parties, whether oral or written, with respect to such subject matter.  This Agreement may be executed in counterparts, each of which shall be deemed an original and all of which together shall constitute one and the same document.  This Agreement may be executed by facsimile.</p>
                            <p>Each party acknowledges that the other party's employees are a valuable asset of such party.   Accordingly, each party  agrees  that  during  the  period  of  time  dating  from  the  Commencement  Date  through  one  year  after  the termination of this Agreement, such party shall not, directly or indirectly, knowingly recruit or solicit, or employ, engage as a consultant, or otherwise retain, any of the other party's employees who are involved in the performance of this Agreement.  For the avoidance of doubt, this provision shall not restrict (a) general public advertisements of employment or the right of any employee of OMG  or Client in response to general public advertisements, to seek employment with OMG   or Client or any affiliate of either and under such circumstances, for OMG   or Client or such affiliate to hire such employee, or (b) the solicitation or hiring by OMG  or Client or any affiliate of either of an employee of OMG  or Client that has been terminated by OMG  or Client prior to commencement of employment discussions between OMG  or Client or such affiliate and such employee.</p>
                        </li>
                    </ol>
                </div>
            </div>
            <div className='relative mb-10 justify-between items-center flex-wrap text-white w-[90%]  mx-auto text-xs h-min mt-2'>
                <div className='flex justify-between items-center'>
                    <div className='flex flex-row items-center cursor-pointer' onClick={() => navigate(-1)}>
                        <GButton variant='default' size='md' className='px-0'>
                        <>
                            <i className='fa-regular fa-angle-left h-[16px] w-[10px] !text-white'></i>
                            <div className='text-lg font-semibold text-white'>Back</div>
                        </>
                        </GButton>
                    </div>
                </div>
            </div>
        </ResultsDashboardLayout>
    );
}
