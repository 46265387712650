import React from 'react';
import { LoadingComp } from './Loading';

type PrivateRouteType = { isLoading: boolean; children: any };

export const PrivateRoute = ({ isLoading, children }: PrivateRouteType) => {
  if (isLoading) {
    return (
      <div className='grid place-items-center h-full'>
        <LoadingComp />
      </div>
    );
  }

  return children;
};
