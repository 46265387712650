import React from 'react';
import { darkColors } from '@/utils/constants';
import { CartesianGrid, Cell, ReferenceArea, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';
import { BaseChartType, HashMap } from '@/types';

type ScatterChartType = BaseChartType & { data?: any };

export function CustomScatterChart({ data, id, isAnimationActive = true }: ScatterChartType) {  
  const renderReferenceShape = () => {
    return (
      <foreignObject className='omni-scatter-chart-shape' width={'80%'} height={'90%'}>
        <div className='w-full h-full'>
          <div className='omni-scatter-chart-shape-inner-wrapper'>
            <div className='omni-scatter-chart-shape-over'>
              <span className='text-primary-50 w-1/2'>Over performing</span>
            </div>
            <div className='omni-scatter-chart-shape-under'>
              <span className='text-primary-50 text-right mt-20 ml-20'>Under performing</span>
            </div>
          </div>
        </div>
      </foreignObject>
    );
  };

  const AxisLabel = ({ axisType, x, y, width, height, children }: any) => {
    const isVert = axisType === 'yAxis';
    const rot = isVert ? -90 : 0;
    const cx = isVert ? -height / 2 - 120 : x + width / 2;
    const cy = isVert ? y : y + height / 2 + 100;

    return (
      <text x={cx} y={cy} transform={`rotate(${rot})`}>
        {children}
      </text>
    );
  };

  const RightAxisLabel = ({ axisType, x, y, width, height, children }: any) => {
    const isVert = axisType === 'yAxis';
    const rot = isVert ? -90 : 0;
    const cx = isVert ? -height / 2 - 120 : x + width / 2;
    let cy = null;
    if (x > 500) {
      cy = isVert ? x + y : y + height / 2 + 100;
    } else {
      cy = isVert ? x + y + 20 : y + height / 2 + 100;
    }

    return (
      <text x={cx} y={cy} transform={`rotate(${rot})`}>
        Under performing <i className='fa-solid fa-arrow-up'></i> Over performing
      </text>
    );
  };

  const chartData = React.useMemo(() => {
    return data.map((element, index) => {
      const currentColor = darkColors[index % darkColors.length];

      return {
        ...element,
        currentColor
      };
    });
  }, [data]);

  const renderLabel = (props: HashMap) => {
    return (
      <text textAnchor='start' x={props.x + props.width / 2 - 11} y={props.y + props.height / 2 + 5} className='fill-white text-xxs'>
        {+props.values}%
      </text>
    );
  };

  const customTooltipRender = (props: HashMap) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <>
          <div
            className='p-4 rounded-[8px]'
            style={{
              background: 'var(--gradient-radial-gray)'
            }}
          >
            {props.payload[2] && (
              <p className='flex flex-row items-center text-white'>{`${props.payload[0].payload?.channel}  ${props.payload[2].value}%`}</p>
            )}
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <ScatterChart
        id={id}
        margin={{
          top: 30,
          right: 0,
          bottom: 0,
          left: 10
        }}
      >
        <CartesianGrid vertical={false} horizontal={false} />
        <Tooltip content={customTooltipRender} cursor={true} animationDuration={200} animationEasing='ease-out' />
        {/* <Legend /> */}
        <XAxis dataKey='spend_proportion' type='number' label={'Channel Spend %'} domain={[0, 100]} tick={false} axisLine={false} />
        <YAxis
          yAxisId={0}
          domain={[-15, 100]}
          orientation='left'
          tick={false}
          axisLine={false}
          type='number'
          dataKey='sales_proportion'
          height={80}
          width={50}
          label={({ viewBox }: any) => (
            <AxisLabel axisType='yAxis' {...viewBox}>
              {'Channel Sales %'}
            </AxisLabel>
          )}
        />
        <YAxis
          yAxisId={1}
          orientation='right'
          tick={false}
          axisLine={false}
          label={({ viewBox }: any) => <RightAxisLabel axisType='yAxis' {...viewBox}></RightAxisLabel>}
        />
        {/* TODO: Range should be updated according to size of the data, not static */}
        <ZAxis type='number' dataKey='total_contribution' range={[1000, 5000]} />
        <ReferenceArea x1={25} x2={200} shape={renderReferenceShape} />
        <Scatter data={chartData} fill='#8884d8' opacity={0.6} label={renderLabel} isAnimationActive={isAnimationActive}>
          {chartData.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry.currentColor} values={entry.total_contribution} />;
          })}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
}
