import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { ReactComponent as MainBgLogo } from '@/assets/images/omni-rotate-logo.svg';
import { SideBarBreadcrumb } from './SideBarBreadcrumb';
import { Footer } from './Footer';
import { AppHeader, AppHeaderType, Header, ResultsDashboardHeader } from './Header';

type DefaultLayoutType = { children: ReactNode; headerProps: AppHeaderType; title?: string; sidebarClassname?: string; className?: string };
type AppButtonWrapperType = { children: ReactNode; className?: string };

export function AppButtonWrapper({ children, className }: AppButtonWrapperType) {
  return <div className={clsx('self-end', className)}>{children}</div>;
}

export function DefaultLayout({ children, headerProps }: DefaultLayoutType) {
  return (
    <div className='splash-wrapper relative h-screen overflow-hidden flex flex-col'>
      <MainBgLogo width={1840} height={1840} className='absolute -left-[36rem] -top-[40rem] bg-logo' />
      <Header {...headerProps} />
      {children}
      <Footer />
    </div>
  );
}

export function AppLayoutMainContent({ children, headerProps, className }: DefaultLayoutType) {
  return (
    <div className={clsx('omni-app-main-content', className)}>
      <div className='max-w-[var(--main-content-max-width)] w-full h-full mx-auto'>
        <div className='flex flex-col justify-between mx-[24px] lg:mx-[50px] h-full'>
          <AppHeader {...headerProps} />
          {children}
        </div>
      </div>
    </div>
  );
}

export function AppLayout({ children, headerProps, sidebarClassname }: DefaultLayoutType) {
  return (
    <div className='omni-app-main-body'>
      <SideBarBreadcrumb className={sidebarClassname} />
      <AppLayoutMainContent headerProps={headerProps}>{children}</AppLayoutMainContent>
    </div>
  );
}

export function ResultsDashboardLayout({ children, headerProps }: DefaultLayoutType) {
  return (
    <div className='splash-wrapper relative lg:h-screen overflow-hidden flex flex-col'>
      <MainBgLogo width={1200} height={1200} className='absolute z-40 left-[55rem] top-[15rem]' />
      <ResultsDashboardHeader {...headerProps} />
      {children}
    </div>
  );
}

type OverflowContentType = { children: React.ReactNode; className?: string; isRequireSpace?: boolean; noShadow?: boolean };

export function OverflowContent({ children, className, isRequireSpace }: OverflowContentType) {
  return (
    <div className={clsx('w-full overflow-y-auto overflow-y-overlay overflow-scroll-custom', isRequireSpace ? '' : '', className)}>
      {children}
    </div>
  );
}
