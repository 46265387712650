import React, { ChangeEventHandler } from 'react';
import clsx from 'clsx';

type CheckboxType = {
  checked?: boolean;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  label: string;
  disabled?: boolean;
  isPDF?: boolean;
};

export function Checkbox({ label, checked, handleChange, disabled = false, isPDF }: CheckboxType) {
  const textSize = isPDF ? 'text-base' : 'text-xs';
  return (
    <label className='omni-checkbox-label flex cursor-pointer'>
      <div className='bg-white relative w-[20px] h-[20px] border border-[#2AC8DD] rounded-[4px] flex justify-center items-center mr-2 isolate'>
        <input type='checkbox' className='hidden relative z-10' disabled={disabled} onChange={handleChange} checked={checked} />
        <i className='hidden fa-solid fa-check pointer-events-none relative z-10 text-white'></i>
        <span className='w-full h-full absolute'></span>
      </div>
      <span className={clsx('select-none ml-2 text-[#212121] truncate ... w-full', textSize)}>{label}</span>
    </label>
  );
}
