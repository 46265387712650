import { useAppDispatch } from '@/store/hooks';
import { clearAllModelData } from '@/store/modelsSlice';
import React from 'react';

export const useClearCurrentSession = () => {
  const dispatch = useAppDispatch();

  /* This would clear all the previous state for the currentSessionId */
  React.useLayoutEffect(() => {
    dispatch(clearAllModelData());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
