// @ts-nocheck
import React from 'react';
import { StyleSheet, Text, View, Image } from '@react-pdf/renderer';
const pdfHomePage = require('../../assets/images/pdfHomePage.png');

const Welcome = (props) => {
  const styles = StyleSheet.create({
    mainStyle: {
      width: '100%',
      height: '100%'
    },
    omniRomiLogo: {
      position: 'absolute',
      minHeight: '100%',
      width: '100%',
      margin: '10px 10px 0 10px'
    },
    returnInvestText: {
      position: 'relative',
      marginTop: '10px',
      left: '350px',
      top: '400px',
      fontSize: '45px',
      color: '#fff',
      width: '500px'
    },
    clientHeading: {
      position: 'relative',
      fontSize: '14px',
      color: '#fff',
      width: '500px',
      marginTop: '20px',
      left: '440px',
      top: '400px'
    },
    clientNameHeading: {
      position: 'relative',
      fontSize: '30px',
      color: '#fff',
      width: '500px',
      left: '440px',
      top: '400px'
    }
  });

  return (
    <View style={styles.mainStyle}>
      <Image src={pdfHomePage} style={styles.omniRomiLogo} />
      <Text style={styles.returnInvestText}>Return on media</Text>
      <Text style={styles.returnInvestText}>investment analyser</Text>
      <Text style={styles.returnInvestText}>&#95;&#95;&#95;&#95;&#95;&#95;&#95;&#95;&#95;&#95;&#95;&#95;&#95;&#95;&#95;&#95;</Text>
      <Text style={styles.clientHeading}>Client</Text>
      <Text style={styles.clientNameHeading}>{props?.currentClientData?.name || 'N/A'}</Text>
      <Text style={styles.clientHeading}>Dates</Text>
      <Text style={styles.clientNameHeading}>
        {props?.currentClientData?.startDate} - {props?.currentClientData?.endDate}
      </Text>
    </View>
  );
};

export default Welcome;
