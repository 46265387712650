import clsx from 'clsx';
import React, { ReactNode, forwardRef } from 'react';

type ButtonType = React.ComponentPropsWithRef<'button'> & {
  suffix?: ReactNode;
  fullWidth?: boolean;
};

// Base Button
export const BButton = forwardRef<HTMLButtonElement, ButtonType>(({ children, className, ...rest }, ref) => {
  return (
    <button className={clsx('py-4 px-[1.87rem]', className)} ref={ref} {...rest}>
      {children}
    </button>
  );
});

type GButtonType = ButtonType & {
  variant?: 'primary' | 'secondary' | 'default';
  size?: 'lg' | 'md' | 'sm' | 'xs';
  bgGradient?: 'gray';
  isDanger?: boolean;
  isGradient?: boolean;
  noShade?: boolean;
};

// Group Button
export const GButton = forwardRef<HTMLButtonElement, GButtonType>(
  (
    { children, className, suffix, isGradient, size = 'lg', variant = 'default', bgGradient, noShade, isDanger, ...rest }: GButtonType,
    ref
  ) => {
    const sizeCompare = React.useMemo(() => {
      if (size === 'lg') {
        return `text-lg`;
      } else if (size === 'md') {
        return `text-md px-8 py-3.5`;
      } else if (size === 'sm') {
        return `text-md px-8 py-3.5`;
      } else {
        return `text-xs`;
      }
    }, [size]);

    return (
      <BButton
        className={clsx(
          'btn-transition rounded-[0.25rem]',
          sizeCompare,
          `btn-${bgGradient || noShade ? '' : variant}`,
          variant === 'default' && 'test',
          bgGradient && `${bgGradient === 'gray' ? 'btn-gradient-gray text-white' : null}`,
          isDanger && `btn-danger`,
          className
        )}
        ref={ref}
        {...rest}
      >
        <span
          className={clsx('flex justify-center items-center flex-1 gap-x-2  hover:gap-4', {
            'btn-text-gradient': isGradient
          })}
        >
          {children}
          {suffix ?? null}
        </span>
      </BButton>
    );
  }
);

// Icon Button
export function IButton({ children, className, ...rest }: ButtonType) {
  return (
    <BButton className={clsx(className)} {...rest}>
      {children}
    </BButton>
  );
}

export function SButton({ children, onClick, className, suffix, fullWidth = false }: ButtonType) {
  return (
    <button
      className={clsx(
        'px-[32px] py-[14px] text-white mr-[20px] rounded-[4px] btn-transition',
        fullWidth ? 'w-full' : 'w-[200px]',
        className
      )}
      style={{
        background: 'var(--gradient-radial-gray)'
      }}
      onClick={onClick}
    >
      <span className={clsx('flex justify-center items-center flex-1 gap-x-2 hover:gap-4')}>
        {children}
        {suffix ?? null}
      </span>
    </button>
  );
}
