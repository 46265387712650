import React from 'react';
import clsx from 'clsx';

type ModalType = {
  open: boolean;
  title?: string;
  description?: string;
  children?: any;
  autoHeight?: boolean;
  maxWidth?: number;
  showCloseIconOnTop?: boolean;
  onClose?: any;
  size?: 'lg';
};

export const Modal = ({ open, children, size, showCloseIconOnTop = false, onClose }: ModalType) => {
  const sizeParam = React.useMemo(() => {
    if (size === 'lg') {
      return 'w-[417px] p-[42px]';
    }

    return 'w-[367px] p-6';
  }, [size]);

  const handleClick = (event: any) => {
    const name = event.target.attributes && event.target.attributes['data-name'] && event.target.attributes['data-name'].value;
    if (name === 'modal-wrapper') {
      onClose();
    }
  };

  if (!open) return null;

  return (
    <div
      data-name='modal-wrapper'
      className='modal-wrapper fixed w-screen h-screen left-0 top-0 flex items-center justify-center z-50 backdrop-brightness-50'
      onClick={handleClick}
    >
      {showCloseIconOnTop && (
        <div className='absolute top-4 left-4 w-6 h-6 cursor-pointer' onClick={onClose}>
          close
        </div>
      )}
      <div className={clsx('modal-container overflow-y-auto h-auto bg-white rounded-lg shadow-md', sizeParam)}>
        <div className='modal-innerContainer overflow-auto w-full h-full flex flex-col justify-between gap-y-4'>
          <div className='modal-header py-2 relative'>
            <div className='mb-2'>
              {!showCloseIconOnTop && <div className='absolute left-0 top-0 w-6 h-6 cursor-pointer' onClick={onClose}></div>}
            </div>
          </div>
          <div className='modal-body'>{children}</div>
        </div>
      </div>
    </div>
  );
};
