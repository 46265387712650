// @ts-nocheck
import React from 'react';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { OptimiseImg } from './svg/Optimise-img';

const styles = StyleSheet.create({
  mainStyle: {
    width: '100%',
    height: '90%',
    margin: '40px 0 20px 40px'
  },
  cardWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #D6D7D8',
    borderRadius: '10px',
    margin: '5px 10px 10px 10px'
  },
  optimiseChart: {
    marginTop: '20px',
    marginBottom: '10px'
  },
  optimiseChartHeader: {
    marginLeft: 10
  },
  cardHeaderChart: {
    width: '100%',
    height: '55px',
    marginTop: '15px'
  },
  optimiseWrapper: {
    display: 'flex',
    width: '95%',
    flexDirection: 'row',
    margin: '0 10px 0 0px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  optimiseHeading: {
    fontSize: '28px',
    fontWeight: 700,
    marginLeft: '10px'
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 10px 0 10px',
    marginBottom: '10px'
  },
  headerDate: {
    fontSize: '14px',
    fontWeight: 500,
    marginRight: '20px'
  },
  rowWrapper: {
    width: '92%',
    height: '100%'
  }
});

const Optimise = (props) => {
  const clientDetail = `${props?.currentClientData?.name}, ${props?.currentClientData?.startDate} - ${props?.currentClientData?.endDate}`;

  return (
    <View style={styles.mainStyle}>
      <View style={styles.optimiseWrapper}>
        <View style={styles.headerTitle}>
          <View>
            <OptimiseImg />
          </View>
          <Text style={styles.optimiseHeading}>Optimise</Text>
        </View>
        <Text style={styles.headerDate}>{clientDetail}</Text>
      </View>
      <View style={styles.rowWrapper}>
        <View style={styles.cardHeaderChart}>
          <Image src={props?.optimiseResultHeaderResponse} style={styles.optimiseChartHeader} />
        </View>
        <View style={styles.cardWrapper}>
          <Image src={props?.optimiseResultChartResponse} style={styles.optimiseChart} />
        </View>
      </View>
    </View>
  );
};

export default Optimise;
